/**
 * Reads JSON from a slice of a given element's dataset and then destroys it.
 * Borrowed from a similar method in @mc/page-data
 *
 * We keep a cache of entries b/c the runtimes run multiple times in the
 * editor, and in that case if the dom not has not changed the data attribute
 * will be gone. In those cases, fall back to the last known value.
 *
 * @param {*} element
 * @param {*} dataSlice
 */

const cache = new Map();

const destructiveReadSlice = (element, dataSlice) => {
  let data = {};
  const cacheItem = cache.get(element) || {};

  const jsonString = element.dataset[dataSlice];
  if (jsonString) {
    try {
      data = JSON.parse(jsonString);
      delete element.dataset[dataSlice];

      cacheItem[dataSlice] = data;
      cache.set(element, cacheItem);
    } catch (e) {
      // Maybe call a future bugsnag endpoint here, just don't error out the page yet.
    }
    return data;
  }

  return cacheItem[dataSlice];
};

export default destructiveReadSlice;
