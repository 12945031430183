import {
  buildMultipleChoice,
  buildDropdown,
  buildTextbox,
  buildAddressField,
  buildBirthdayInputField,
  buildDatePicker,
} from './fn/forms';

/**
 * Creates and inserts GDPR form block - all parameters are pulled in from the attached audience's GDPR settings
 *
 * @param {object} gdprSettings
 * @param {object} gdprSettings.gdprField - object containing data needed to render GDPR inputs
 * @param {string} gdprSettings.gdprLabel - used as heading for GDPR block
 * @param {string} gdprSettings.gdprLegal - user-customizable legal text for GDPR block
 * @param {string} gdprSettings.gdprMCLegal - MC-defined legal text for GDPR block
 * @param {String} formId - the ID of the form that contains this GDPR block
 * @param {boolean} isPreview - when viewed inside the editor, used to apply tab-index="-1"
 *
 * @returns {node} div - the GDPR node for the form
 */
export const buildGdprBlock = (gdprSettings, formId, isPreview) => {
  const { gdprField, gdprLabel, gdprLegal, gdprMCLegal } = gdprSettings;
  const wrapper = document.createElement('div');
  wrapper.setAttribute('class', 'mceGdprForm mceText');

  const heading = document.createElement('h2');
  heading.textContent = gdprLabel;
  wrapper.appendChild(heading);
  wrapper.appendChild(buildMultipleChoice(gdprField, formId, isPreview));

  const legal = document.createElement('p');
  legal.setAttribute('class', 'small-text');
  legal.innerHTML = gdprLegal;
  wrapper.appendChild(legal);

  const mcLegalContainer = document.createElement('div');
  mcLegalContainer.setAttribute('class', 'mceLegalContainer');
  const mcLegalImage = document.createElement('img');
  mcLegalImage.setAttribute(
    'src',
    'https://cdn-images.mailchimp.com/icons/mailchimp-gdpr.svg',
  );
  mcLegalContainer.appendChild(mcLegalImage);

  const mcLegalText = document.createElement('p');
  mcLegalText.innerHTML = gdprMCLegal;
  if (isPreview) {
    mcLegalText.querySelector('a').setAttribute('tabindex', '-1');
  }
  mcLegalContainer.appendChild(mcLegalText);

  wrapper.appendChild(mcLegalContainer);

  return wrapper;
};

/**
 * Helper method for Hosted Forms AKA Simple Signup Form Builder
 * Hosted Forms need fieldConfig to be modified to support new properties
 * for our business requirements, so we are adding this helper method for
 * modifying fieldConfig for HostedForms Specifically.
 *
 * @param {array} fields - All fields and groups available for the audience
 * @param {object} fieldConfig - Which fields should be shown in this form. Also specifies which fields are required, and what order the fields are in.
 */

export const parseFormFieldsForHostedForms = (fields, fieldConfig) => {
  // Insert the new field in the order set by the fieldConfig
  return fields.reduceRight((parsedFields, field) => {
    parsedFields.splice(fieldConfig[field.name].order, 0, {
      ...field,
      label: fieldConfig[field.name].label || field.label, //fieldConfig will have our customized form field properties
      helper_text: fieldConfig[field.name].helper_text || field.helper_text,
      required: fieldConfig[field.name].isRequired,
      isEnabled: fieldConfig[field.name].isEnabled,
      order: fieldConfig[field.name].order || field.order,
    });
    return parsedFields
      .filter((x) => x.isEnabled)
      .sort((a, b) => a.order - b.order);
  }, []);
};

/**
 * Applies a form config to a list of fields, returning an ordered list of form fields
 *
 * @param {array} fields - All fields and groups available for the audience
 * @param {object} fieldConfig - Which fields should be shown in this form. Also specifies which fields are required, and what order the fields are in.
 * @param {bool} isSimpleSignupForm - Bool to check whether or not we're in a Hosted Form or not
 */

export const parseFormFields = (fields, fieldConfig, isSimpleSignupForm) => {
  if (isSimpleSignupForm) {
    return parseFormFieldsForHostedForms(fields, fieldConfig);
  }
  return fields.reduceRight((parsedFields, field) => {
    if (fieldConfig[field.name]) {
      // Insert the new field in the order set by the fieldConfig
      parsedFields.splice(fieldConfig[field.name].order, 0, {
        ...field,
        required: fieldConfig[field.name].isRequired,
      });
    }
    return parsedFields;
  }, []);
};

/**
 * Takes a set of form fields and separates the GDPR form fields from the rest of the fields
 *
 * @param {object} allFields - All fields and groups available for the audience
 * @param {boolean} isGdprEnabled
 * @param {boolean} isGdprRequired
 * @param {string} gdprLabel
 */
export const splitGdprFieldsFromAllFields = (
  allFields,
  isGdprEnabled,
  isGdprRequired,
  gdprLabel = '',
) => {
  const gdprField = {
    name: 'gdpr',
    label: gdprLabel,
    required: isGdprRequired,
    type: 'checkbox',
    choices: [],
  };
  const formFields = [];

  allFields.forEach((field) => {
    if (field.marketing_preference_id) {
      // Adjust the GDPR fields to match the format used by the other checkbox/radio fields
      gdprField.choices.push({
        value: field.name,
        label: field.label,
        marketing_preference_id: field.marketing_preference_id,
      });
    } else {
      formFields.push(field);
    }
  });

  if (formFields.length > 1) {
    formFields.sort((a, b) => {
      return a.order - b.order;
    });
  }

  return {
    gdprField: isGdprEnabled ? gdprField : {},
    formFields,
  };
};

// TODO: Handle help text?
/**
 * Given a set of non-GDPR form fields, render each based on its type
 *
 * @param {array} parsedFormFields
 * @param {boolean} isLabelVisible
 * @param {number} formId
 * @param {boolean} isPreview - when viewed inside the editor, used to apply tabindex="-1"
 */
export const buildFormFields = (
  parsedFormFields,
  isLabelVisible,
  formId,
  isPreview,
) => {
  const formFieldsContainer = new DocumentFragment();
  parsedFormFields.forEach((field) => {
    switch (field.type) {
      case 'checkbox':
      case 'radio':
        return formFieldsContainer.appendChild(
          buildMultipleChoice(field, formId, isPreview),
        );
      case 'dropdown':
      case 'select':
        return formFieldsContainer.appendChild(
          buildDropdown(field, isLabelVisible, formId, isPreview),
        );
      case 'address':
        // TODO: Replace 164 (USA country code) with the ability to select a default country
        return formFieldsContainer.appendChild(
          buildAddressField(field, isLabelVisible, formId, isPreview, 164),
        );
      case 'date':
        return formFieldsContainer.appendChild(
          buildDatePicker(field, isLabelVisible, formId, isPreview),
        );
      case 'birthday':
        return formFieldsContainer.appendChild(
          buildBirthdayInputField(field, isLabelVisible, formId, isPreview),
        );
      default:
        return formFieldsContainer.appendChild(
          buildTextbox(field, isLabelVisible, formId, isPreview),
        );
    }
  });
  return formFieldsContainer;
};
