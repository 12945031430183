const timers = new Map();

export function setUpCountdownTimers() {
  const countdownTimers = document.querySelectorAll(
    '[data-countdown="datetime"]',
  );

  const hasCountdownTimers = countdownTimers.length > 0;

  if (!hasCountdownTimers) {
    return;
  }

  countdownTimers.forEach((countdownTimer) => {
    timers.set(
      countdownTimer,
      requestAnimationFrame(() => animateTimer(countdownTimer)),
    );
  });

  return () => {
    timers.forEach((timer) => cancelAnimationFrame(timer));
  };
}

function animateTimer(element) {
  const comparisonDate = new Date(+element.dataset.datetime);

  const secondsLeft = Math.max(
    0,
    Math.floor((comparisonDate - Date.now()) / 1000),
  );
  const seconds = Math.floor(secondsLeft % 60).toString();
  const minutes = Math.floor((secondsLeft / 60) % 60).toString();
  const hours = Math.floor((secondsLeft / (60 * 60)) % 24).toString();
  const days = Math.floor(secondsLeft / (60 * 60 * 24)).toString();

  element.querySelector('[data-countdown="days"]').textContent = padStartDigit(
    days,
  );
  element.querySelector('[data-countdown="hours"]').textContent = padStartDigit(
    hours,
  );
  element.querySelector(
    '[data-countdown="minutes"]',
  ).textContent = padStartDigit(minutes);
  element.querySelector(
    '[data-countdown="seconds"]',
  ).textContent = padStartDigit(seconds);

  if (secondsLeft > 0) {
    // TODO: only show the timer in prod using env.isForPublicConsumption?
    timers.set(
      element,
      requestAnimationFrame(() => animateTimer(element)),
    );
  }
}

function padStartDigit(str) {
  return str.length < 2 ? '0' + str : str;
}
