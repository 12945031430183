import { hasMergeTag } from './fn/isMergeTag';

const renderDetails = (data) => {
  const orderSummary = document.querySelector('[data-ref="orderSummary"]');
  const customerInfo = document.createElement('div');
  const productImageColumn = document.createElement('div');
  if (data.order_number) {
    orderSummary.setAttribute('style', 'display: flex');
    orderSummary.setAttribute('class', 'mceRow');
    customerInfo.setAttribute('class', 'mceColumn');
    customerInfo.setAttribute('style', '--mceColumn-span: 6');
    productImageColumn.setAttribute('class', 'mceColumn');
    productImageColumn.setAttribute('style', '--mceColumn-span: 6');

    const productImage = document.createElement('img');
    productImage.setAttribute('class', 'mceImage');
    productImage.setAttribute('style', 'width: 100%');
    productImage.setAttribute('src', data.display_items[0].custom.images[0]);

    productImageColumn.appendChild(productImage);

    const productName = document.createElement('p');
    productName.setAttribute('class', 'mceText');
    productName.textContent = data.display_items[0].custom.name;
    customerInfo.appendChild(productName);

    const customerName = document.createElement('p');
    customerName.setAttribute('class', 'mceText');
    customerName.textContent = data.name;
    customerInfo.appendChild(customerName);

    const { line1, line2, city, state, postal_code } = data.shipping_address;
    const shippingAddressLine1 = document.createElement('p');
    shippingAddressLine1.setAttribute('class', 'mceText');
    shippingAddressLine1.textContent = line1;
    customerInfo.appendChild(shippingAddressLine1);

    if (line2) {
      const shippingAddressLine2 = document.createElement('p');
      shippingAddressLine2.setAttribute('class', 'mceText');
      shippingAddressLine2.textContent = line2;
      customerInfo.appendChild(shippingAddressLine2);
    }

    const shippingAddressLine3 = document.createElement('p');
    shippingAddressLine3.setAttribute('class', 'mceText');
    shippingAddressLine3.textContent = city + ', ' + state + ' ' + postal_code;
    customerInfo.appendChild(shippingAddressLine3);

    const email = document.createElement('p');
    email.setAttribute('class', 'mceText');
    email.textContent = data.email;
    customerInfo.appendChild(email);

    orderSummary.appendChild(productImageColumn);
    orderSummary.appendChild(customerInfo);
  }
};

export function buildOrderSummary() {
  const sessionId = new URLSearchParams(window.location.search).get(
    'session_id',
  );
  const element = document.querySelector('[data-stripe-payment-details-url]');
  if (!element) {
    return;
  }
  const stripePaymentDetailsUrl =
    window.location.origin +
    element.dataset.stripePaymentDetailsUrl +
    '&session_id=' +
    sessionId;

  if (
    element.dataset.stripePaymentDetailsUrl &&
    !hasMergeTag(element.dataset.stripePaymentDetailsUrl)
  ) {
    return fetch(stripePaymentDetailsUrl, {
      method: 'GET',
    })
      .then(function(data) {
        return data.json();
      })
      .then(function(data) {
        renderDetails(data);
      });
  }
}
