import { compileSubscribeSettings } from '@mc/editing/blocks/runtimes/subscribeForm';
import { trackClicks } from '@mc/editing/blocks/runtimes/tracking';
import { startupCookieBanner } from '@mc/editing/blocks/runtimes/cookieBanner';
import { startupSectionAnimationObserver } from '@mc/editing/blocks/runtimes/sectionObserver';
import { setUpBuyButtons } from '@mc/editing/blocks/runtimes/productBlock';
import { setUpServicesSection } from '@mc/editing/blocks/runtimes/servicesSection';
import { setUpAppointmentConfirmation } from '@mc/editing/blocks/runtimes/appointmentConfirmation';
import { buildOrderSummary } from '@mc/editing/blocks/runtimes/orderSummary';
import {
  insertUnsubscribeElement,
  getProfileFormData,
  completePreferenceButtons,
} from '@mc/editing/blocks/runtimes/preferencesCenterForm';
import { setUpAsyncForms } from '@mc/editing/blocks/runtimes/asyncForms';
import { setUpCartForms } from '@mc/editing/blocks/runtimes/commerce/cartForm';
import { setUpCountdownTimers } from '@mc/editing/blocks/runtimes/countdownTimer';
import {
  setUpProductListingPagination,
  setUpSSRProductListingPagination,
} from '@mc/editing/blocks/runtimes/productListingPagination';
import { startupResponsiveNav } from '@mc/editing/blocks/runtimes/responsiveNav';
import { insertOembedContent } from '@mc/editing/blocks/runtimes/oembedData';
import { buildCartIcon } from '@mc/editing/blocks/runtimes/commerce/cartIcon';
import { initializeGallery } from '@mc/editing/blocks/runtimes/imageGallery';
import { accommodateFixedRow } from '@mc/editing/blocks/runtimes/fn/accomodateFixedRow';
import freeShippingNotificationIcon from '@mc/editing/blocks/runtimes/commerce/freeShippingNotificationIcon';
import setUpCarouselBlocks from '@mc/editing/blocks/runtimes/carouselBlock';
import 'intl-tel-input/build/css/intlTelInput.css';

import './index.less';

// Add runtimes here. The contract for a runtime is to synchronously execute and
// optionally return a cleanup function. Runtimes should be idempotent–the editor
// may execute them many times during an editing session.
const runtimes = [
  getProfileFormData,
  compileSubscribeSettings,
  trackClicks,
  startupCookieBanner,
  setUpBuyButtons,
  buildOrderSummary,
  insertUnsubscribeElement,
  setUpAsyncForms,
  setUpCartForms,
  completePreferenceButtons,
  setUpCountdownTimers,
  setUpServicesSection,
  setUpProductListingPagination,
  setUpSSRProductListingPagination, // Set up pagination for the SSR based PLP
  insertOembedContent,
  buildCartIcon,
  setUpAppointmentConfirmation,
  initializeGallery,
  accommodateFixedRow,
  startupResponsiveNav,
  freeShippingNotificationIcon,
  setUpCarouselBlocks,
  // keep animation last to ensure DOM element modifications have been made
  // before animation classes are added
  startupSectionAnimationObserver,
];

// The code from here through the end of the file coordinates running
// the above set of runtimes. You should not need to change it when adding
// a runtime of your own.

// Keep track of any provided cleanup functions
let cleanups = [];

function run() {
  // Run cleanups returned during the previous run
  for (const cleanup of cleanups) {
    cleanup();
  }

  cleanups = [];

  // Run each runtime, if it returns a function store it
  // as a cleanup.
  for (const runtime of runtimes) {
    const result = runtime();

    if (typeof result === 'function') {
      cleanups.push(result);
    }
  }
}

// Listen for messages from the editor's parent frame
// these messages are sent when the editor document updates.

let timeoutId = null;

window.addEventListener(
  'message',
  (event) => {
    // Since this iframe runs as a srcdoc, we can't strictly
    // check the origin.
    if (!event.origin.endsWith('mailchimp.com')) {
      return;
    }

    // Re-run the runtimes on change but debounce, as these
    // messages may come in rapid succession during text updates, etc.
    if (event.data === 'runtimes:inc') {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(run, 500);
    }
  },
  false,
);

run();
