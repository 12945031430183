import { hasMergeTag } from './fn/isMergeTag';
import env from './env';

const createCheckoutSession = function(
  checkoutSessionUrl,
  title,
  description,
  image,
  productSectionUniqueId,
) {
  return fetch(checkoutSessionUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      title,
      description,
      image,
      productSectionUniqueId,
    }),
  }).then(function(result) {
    return result.json();
  });
};

const showErrorMessage = (section) => {
  const errorMessage = section.querySelector('[data-product-error-message]');
  if (errorMessage) {
    errorMessage.setAttribute('style', 'display: grid');
  }
};

const toggleButtonDisabled = (button, isDisabled, buttonText) => {
  if (isDisabled) {
    button.setAttribute('disabled', 'disabled');
    button.setAttribute(
      'style',
      `
      opacity: 0.5;
    `,
    );
  } else {
    button.removeAttribute('disabled');
    button.setAttribute(
      'style',
      `
      opacity: 1;
    `,
    );
  }

  button.innerText = buttonText;
};

export function setUpBuyButtons() {
  // Skip everything if we don't have any products on the current page.
  const products = document.querySelectorAll('[data-product-section-id]');
  const hasProducts = products.length > 0;
  if (!hasProducts || !env.isForPublicConsumption) {
    return;
  }

  // Install Stripe client API on page via <script>
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = 'https://js.stripe.com/v3/';
  script.async = true;
  script.defer = true;
  const head = document.head;
  head.appendChild(script);

  // For every product section, set up click behavior for Buy button (if present)
  products.forEach((section) => {
    const stripeBuyButton = section.querySelector(
      '[data-product-button-buy-enabled]',
    );

    const paypalBuyButton = section.querySelector(
      '[data-product-button-buy-paypal-enabled]',
    );

    const venmoBuyButton = section.querySelector(
      '[data-product-button-buy-venmo-enabled]',
    );

    if (stripeBuyButton) {
      stripeBuyButton.addEventListener('click', () => {
        stripeBuyButton.setAttribute(
          'data-original-content',
          stripeBuyButton.innerText,
        );

        if (window.Stripe) {
          toggleButtonDisabled(stripeBuyButton, true, 'Loading...');

          const checkoutSessionUrl = new URL(
            window.location.origin + section.dataset.checkoutSessionUrl,
          ).href;
          createCheckoutSession(
            checkoutSessionUrl,
            section.querySelector('[data-product-title]').innerText,
            section.querySelector('[data-product-description]').innerText,
            section.querySelector('img').src,
            section.dataset.productSectionId,
          )
            .then(function(data) {
              const stripe = window.Stripe(data.stripePublishableKey, {
                stripeAccount: data.stripeAccountId,
              });
              stripe
                .redirectToCheckout({
                  sessionId: data.sessionId,
                })
                .then((result) => {
                  if (result.error) {
                    toggleButtonDisabled(
                      stripeBuyButton,
                      true,
                      stripeBuyButton.getAttribute('data-original-content'),
                    );
                    showErrorMessage(section);
                  }
                });
            })
            .catch(() => {
              toggleButtonDisabled(
                stripeBuyButton,
                true,
                stripeBuyButton.getAttribute('data-original-content'),
              );
              showErrorMessage(section);
            });
        }
      });
    }

    if (paypalBuyButton) {
      const paypalPaymentUrl = new URL(
        window.location.origin +
          section.dataset.paypalPaymentUrl +
          '&productSectionUniqueId=' +
          section.dataset.productSectionId,
      ).href;
      const link = document.createElement('a');
      link.href = paypalPaymentUrl;
      link.target = '_blank';
      link.innerText = paypalBuyButton.innerText;
      link.rel = 'noopener noreferrer';
      paypalBuyButton.parentElement.append(link);
      paypalBuyButton.remove();
    }

    if (venmoBuyButton) {
      const venmoPaymentUrl = new URL(
        window.location.origin +
          section.dataset.venmoPaymentUrl +
          '&productSectionUniqueId=' +
          section.dataset.productSectionId,
      ).href;
      const link = document.createElement('a');
      link.href = venmoPaymentUrl;
      link.target = '_blank';
      link.innerText = venmoBuyButton.innerText;
      link.rel = 'noopener noreferrer';
      venmoBuyButton.parentElement.append(link);
      venmoBuyButton.remove();
    }
  });

  // Verify user is connected to Stripe and toggle appropriate button
  if (
    products[0].dataset.stripeConnectionUrl &&
    !hasMergeTag(products[0].dataset.stripeConnectionUrl)
  ) {
    fetch(products[0].dataset.stripeConnectionUrl, {
      method: 'GET',
    })
      .then(function(result) {
        return result.json();
      })
      .then((data) => {
        if (!data || !data.isConnected) {
          products.forEach((product) => {
            const button = product.querySelector(
              '[data-product-button-buy-enabled]',
            );
            if (button) {
              button.setAttribute('disabled', 'disabled');
              button.setAttribute('style', 'opacity: 0.5;');
            }
          });
        }
      });
  }
}
