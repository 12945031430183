import { warning, closeIcon } from './commerceIcons';

export default function commerceError(msg) {
  // remove exisiting error if we're already displaying one
  const errorElement = document.getElementById('commerceErrorElement');
  if (errorElement) {
    errorElement.remove();
  }

  //creat error component
  const errorWrap = document.createElement('div');
  errorWrap.id = 'commerceErrorElement';
  errorWrap.classList.add('commerceErrorWrap');
  const error = document.createElement('div');
  error.classList.add('commerceError');
  error.innerHTML = `${warning}<span>${msg}</span>`;
  errorWrap.appendChild(error);

  // create close button
  const closeButton = document.createElement('div');
  closeButton.innerHTML = closeIcon;
  closeButton.classList.add('closeButton');
  closeButton.setAttribute('role', 'button');
  closeButton.setAttribute('tabindex', '0');
  closeButton.setAttribute('aria-live', 'assertive');
  error.appendChild(closeButton);
  closeButton.addEventListener('click', () => errorWrap.remove());

  return errorWrap;
}
