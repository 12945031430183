import { get, getWithCache } from './request';
import destructiveReadSlice from './fn/destructiveReadSlice';
import {
  buildHoneytimeField,
  buildRecaptchaField,
  loadRecaptchaJs,
} from './fn/forms';
import {
  buildFormFields,
  buildGdprBlock,
  parseFormFields,
  splitGdprFieldsFromAllFields,
} from './audienceSettingsFields';

import {
  isDateValid,
  isEmailValid,
  isPhoneValid,
  isUrlValid,
  isUSZipValid,
} from './fn/validation';

/* global grecaptcha */

const ERRORS_LIST = {
  INVALID_BIRTHDAY: '08e0d7dddecd2fbebae22aa3f9d5241d',
  INVALID_DATE: '773fcd7a2070fcf96c72ea6540c77eb8',
  INVALID_EMAIL: 'b5bccda6d3a04e1d8f79846355290435',
  INVALID_URL: '2918dd459b068924e2b6d6df3faa2a47',
  INVALID_PHONE: '7724076d0ccc93dbb00326dbedfeee14',
  INVALID_ZIP: '7549695a4238049ba35dcbaec4071088',
  REQUIRED_TEXT_FIELD: '9303c9bd4f8178680dc382adbfcd62af',
  REQUIRED_MULTICHOICE_FIELD: 'f012c25f03027d643497283b22d7d6d9',
};

/**
 * Creates feedback block with form errors prior to submitting the form.
 *
 * @param {Object} translations - A list of translated strings
 *
 * @returns {Object} formFeedback - feedback form node
 */
const buildFormFeedback = (translations) => {
  const formFeedback = document.createElement('div');
  const title = document.createElement('p');
  formFeedback.setAttribute(
    'class',
    'mceText mceFormFeedback mceFormFeedback--error',
  );
  title.innerText = translations.ERRORSBELOW;
  formFeedback.appendChild(title);

  return formFeedback;
};

/**
 * Builds a success or error feedback block after the form data has been submitted.
 *
 * @param {String} response - Response result, "success" or "error"
 * @param {String} msg - Body of success or error message
 *
 * @returns {Object} feedbackMessage - feedback node
 */
const buildSubmitFeedback = (response, msg) => {
  const feedbackMessage = document.createElement('div');
  feedbackMessage.setAttribute('class', 'mceText mceFormFeedback');
  feedbackMessage.innerHTML = msg;
  feedbackMessage.classList.add(
    response === 'success'
      ? 'mceFormFeedback--success'
      : 'mceFormFeedback--error',
  );

  return feedbackMessage;
};

/**
 * Builds error messages based on form error state
 *
 * @param {Array} errors - The list of errors found during submission
 * @param {Node} formNode - The subscribe form that was submitted
 * @param {Object} translations - A list of translated strings
 */
const insertErrorMessages = (errors, formNode, translations) => {
  // eslint-disable-next-line array-callback-return
  errors.map((error) => {
    const { inputIds, message, messageAnchor } = error;
    inputIds.forEach((inputId) => {
      const input = document.getElementById(inputId);
      input.classList.add('mceInput--error');
    });
    const errorMessage = document.createElement('p');
    errorMessage.setAttribute('class', 'mceErrorMessage');
    errorMessage.innerText = translations[message];
    const messageContainer = formNode.querySelector(`#${messageAnchor}`);
    //Hiding helper text when errors occur because of overlap of errors and helper text
    //This also matches current design system standards for helper text when errors occur in the input component
    const helperText = formNode.querySelector(`#${messageAnchor}helperText`);
    if (helperText) {
      helperText.style.display = 'none';
    }
    messageContainer.insertAdjacentElement('afterend', errorMessage);
  });
};

/**
 * Builds the submit url with appropriate query string values for submission.
 * Because of how Avesta handles http requests, this eventually gets called as a GET
 * instead of a POST and the query params are passed in for the contact subscribed.
 *
 * @param {String} url - base subscribe url from subscribe endpoint
 * @param {FormData} formData
 *
 * @returns {String} subUrl - complete subscribe url
 */
const buildUrl = (url, formData) => {
  return `${url}&${new URLSearchParams(formData).toString()}`;
};

/**
 * Builds the FormData that will be passed into the subscribe POST
 *
 * @param {String} honeytime
 * @param {Boolean} gdprEnabled
 * @param {node} subscribeBlock
 *
 * @returns {FormData}
 */
const buildFormData = (honeytime, gdprEnabled, subscribeBlock) => {
  const formData = new FormData(subscribeBlock);
  formData.set('ht', honeytime);
  if (gdprEnabled) {
    // Unset the initial GDPR value from FormData because it requires special handling.
    formData.delete('gdpr');
    const gdprFields = document.querySelectorAll('input[name="gdpr"]');
    Array.from(gdprFields).forEach((field) => {
      if (field.checked) {
        formData.set(field.value, 'on');
      }
    });
  }
  const dateInputs = Array.from(
    subscribeBlock.querySelectorAll('[data-date-input]'),
  );

  dateInputs.forEach((dateInput) => {
    // Unset the initial date value from FormData because it requires special handling.
    formData.delete(dateInput.name);

    if (dateInput.type === 'date') {
      const dateFields = dateInput.value.split('-');

      if (dateInput.dataset.dateFormat === 'MM/DD/YYYY') {
        formData.set(`${dateInput.name}[year]`, dateFields[0]);
        formData.set(`${dateInput.name}[month]`, dateFields[1]);
        formData.set(`${dateInput.name}[day]`, dateFields[2]);
      } else {
        formData.set(`${dateInput.name}[year]`, dateFields[0]);
        formData.set(`${dateInput.name}[month]`, dateFields[2]);
        formData.set(`${dateInput.name}[day]`, dateFields[1]);
      }
    } else {
      const dateFields = dateInput.value.split('/');
      // Date fields allow a year value, and Birthday fields do not. Only add the year if it exists.
      if (dateFields.length > 2) {
        formData.set(`${dateInput.name}[year]`, dateFields[2]);
      }
      if (dateInput.dataset.dateFormat === 'MM/DD') {
        formData.set(`${dateInput.name}[month]`, dateFields[0]);
        formData.set(`${dateInput.name}[day]`, dateFields[1]);
      } else {
        formData.set(`${dateInput.name}[month]`, dateFields[1]);
        formData.set(`${dateInput.name}[day]`, dateFields[0]);
      }
    }
  });

  const groups = Array.from(
    subscribeBlock.getElementsByClassName('mceFieldset'),
  );

  const checkboxInputs = [];

  groups.forEach((group) => {
    const groupInputs = Array.from(group.elements);
    groupInputs.forEach((element) => {
      if (element.type === 'checkbox') {
        checkboxInputs.push(element);
      }
    });
  });

  if (checkboxInputs.length > 0) {
    checkboxInputs.forEach((input) => {
      if (input.checked) {
        // first delete the malformed checkbox data..
        formData.delete(input.name);
        // then re-add the valid version of it.
        formData.set(`${input.name}[${input.value}]`, '1');
      }
    });
  }

  // TODO - Add special handling for Address fields
  return formData;
};

/**
 * Adds a submit handler and handles submission
 *
 * @param {String} honeytime - encoded honeytime timestamp pulled from subscribe endpoint
 * @param {Boolean} gdprEnabled - is the gdpr form enabled - pulled from audience settings
 * @param {Object} subscribeBlock - subscribe form
 * @param {Boolean} captchaEnabled - is recaptcha enabled - pulled from audience settings
 * @param {Object} translations - a list of translated strings
 * @param {String} subscribeUrl - full subscribe url including query params
 * @param {Boolean} doubleOptin - indicates if list requires double opt-in via email confirmation
 * @param {Integer} widgetId - the id of the recaptcha widget if recaptcha is enabled
 */
const addSubmitHandler = (
  honeytime,
  gdprEnabled,
  subscribeBlock,
  captchaEnabled,
  translations,
  subscribeUrl,
  doubleOptin,
  widgetId,
) => {
  subscribeBlock.addEventListener('submit', (event) => {
    event.preventDefault();
    const errors = [];
    // Remove any error messages from previous submission
    event.target.parentNode
      .querySelectorAll('.mceErrorMessage, .mceFormFeedback')
      .forEach((errorMsg) => {
        errorMsg.remove();
        // and reset the captcha
        if (captchaEnabled) {
          grecaptcha.reset(widgetId);
        }
      });

    // Clear out the error state from the input.
    event.target.parentNode
      .querySelectorAll('.mceInput--error')
      .forEach((errorInput) => {
        errorInput.classList.remove('mceInput--error');
      });

    // Add Helper Text Back if input is valid
    event.target.parentNode
      .querySelectorAll('[id*="helperText"]')
      .forEach((helperTextLabel) => {
        helperTextLabel.style.display = 'block';
      });

    // Email Validation
    const emailInput = event.target.EMAIL;
    if (emailInput) {
      const emailFieldId = emailInput.id;
      if (!emailInput.value) {
        errors.push({
          inputIds: [emailFieldId],
          message: ERRORS_LIST.REQUIRED_TEXT_FIELD,
          messageAnchor: emailFieldId,
        });
      } else {
        if (!isEmailValid(emailInput.value)) {
          errors.push({
            inputIds: [emailFieldId],
            message: ERRORS_LIST.INVALID_EMAIL,
            messageAnchor: emailFieldId,
          });
        }
      }
    }

    // Fields for the CURRENT form being filled out
    const thisFormFields = event.target.querySelectorAll(
      '.mceInput, .mceSelect',
    );

    // Required field validation,
    // excludes email field cuz we already do that check up there^
    thisFormFields.forEach((field) => {
      if (
        field.required &&
        !field.value &&
        field.name !== 'EMAIL' &&
        field.name !== 'ADDRESS[addr2]'
      ) {
        errors.push({
          inputIds: [field.id],
          message: ERRORS_LIST.REQUIRED_TEXT_FIELD,
          messageAnchor: field.id,
        });
      }
    });

    // Multiple choice fields for the CURRENT form being filled out
    const thisMultiChoiceFields = event.target.querySelectorAll('.mceFieldset');

    // Required multiple choice field validation (checkboxes, radio buttons, gdpr fields)
    thisMultiChoiceFields.forEach((field) => {
      if (field.dataset.isRequired) {
        const hasSelection = (inputs) => {
          if (inputs.length > 0) {
            return inputs.find((one) => one.checked);
          }
          return false;
        };

        const radioOrCheckboxInputs = () => {
          const radios = Array.from(
            field.querySelectorAll('input[type="radio"]'),
          );
          const checkboxes = Array.from(
            field.querySelectorAll('input[type="checkbox"]'),
          );
          if (radios.length > 0) {
            return radios;
          } else if (checkboxes.length > 0) {
            return checkboxes;
          }
        };

        // there is a radio or checkbox and it has no options selected, so push the error.
        if (!hasSelection(radioOrCheckboxInputs())) {
          errors.push({
            inputIds: [field.getElementsByTagName('legend')[0].id],
            message: ERRORS_LIST.REQUIRED_MULTICHOICE_FIELD,
            messageAnchor: field.getElementsByTagName('legend')[0].id,
          });
        }
      }
    });

    // Content validation for US zip code fields
    const zipInput = Array.from(
      event.target.querySelectorAll('input[type="zip"]'),
    );
    if (zipInput.length > 0) {
      const invalidUSZipInputs = zipInput.filter((input) => {
        return input.value !== '' && !isUSZipValid(input.value);
      });
      invalidUSZipInputs.forEach((invalidInput) => {
        errors.push({
          inputIds: [invalidInput.id],
          message: ERRORS_LIST.INVALID_ZIP,
          messageAnchor: invalidInput.id,
        });
      });
    }

    // Content validation for Website & Image fields
    const urlInputs = Array.from(
      event.target.querySelectorAll(
        'input[type="url"], input[type="imageurl"]',
      ),
    );
    if (urlInputs.length > 0) {
      const invalidUrlInputs = urlInputs.filter((input) => {
        return input.value !== '' && !isUrlValid(input.value);
      });
      invalidUrlInputs.forEach((invalidInput) => {
        if (invalidInput.value !== '') {
          errors.push({
            inputIds: [invalidInput.id],
            message: ERRORS_LIST.INVALID_URL,
            messageAnchor: invalidInput.id,
          });
        }
      });
    }

    // Validation for Phone number fields
    const phoneNumberInputs = Array.from(
      event.target.querySelectorAll('input[type="phone"]'),
    );
    if (phoneNumberInputs.length > 0) {
      const invalidPhoneNumberInputs = phoneNumberInputs.filter((input) => {
        return input.value !== '' && !isPhoneValid(input.value);
      });
      invalidPhoneNumberInputs.forEach((invalidInput) => {
        errors.push({
          inputIds: [invalidInput.id],
          message: ERRORS_LIST.INVALID_PHONE,
          messageAnchor: invalidInput.id,
        });
      });
    }

    // Validation for Date and Birthday fields
    const dateInputs = Array.from(
      event.target.querySelectorAll('input[data-date-input]'),
    );
    if (dateInputs.length > 0) {
      const invalidDateInputs = dateInputs.filter((input) => {
        let value = input.value;
        if (input.type === 'date') {
          // Convert YYYY-MM-DD format to use slashes. The months/days order doesn't matter here.
          value = value.replace(/^(\d{4})-(\d{2})-(\d{2})$/, '$2/$3/$1');
        }
        return value !== '' && !isDateValid(input.dataset.dateFormat, value);
      });
      invalidDateInputs.forEach((invalidInput) => {
        errors.push({
          inputIds: [invalidInput.id],
          message:
            invalidInput.dataset.dateInput === 'date'
              ? ERRORS_LIST.INVALID_DATE
              : ERRORS_LIST.INVALID_BIRTHDAY,
          messageAnchor: invalidInput.id,
        });
      });
    }

    if (errors.length === 0) {
      if (captchaEnabled) {
        grecaptcha.execute(widgetId);
      } else {
        const formData = buildFormData(honeytime, gdprEnabled, subscribeBlock);
        // Serialize the FormData into a string and pass it as query params
        get(buildUrl(subscribeUrl, formData)).then((response) => {
          if (response.result === 'success') {
            if (window.mc_process_engagement_post_signup) {
              window.mc_process_engagement_post_signup();
            }
            const successMessage = doubleOptin
              ? translations.COMPLETELINK
              : translations.SUBSCRIBETHANKS;
            subscribeBlock.replaceWith(
              buildSubmitFeedback('success', successMessage),
            );
          } else {
            const errorFeedback = buildSubmitFeedback('error', response.msg);
            subscribeBlock.parentNode.insertBefore(
              errorFeedback,
              subscribeBlock,
            );
          }
        });
      }
    } else {
      if (captchaEnabled) {
        // Reset captcha
        grecaptcha.reset(widgetId);
      }

      const formFeedback = buildFormFeedback(translations);
      subscribeBlock.parentNode.insertBefore(formFeedback, subscribeBlock);
      insertErrorMessages(errors, event.target, translations);
    }
  });
};

/**
 * Looks for subscribe blocks, pulls data for those blocks from the settings url, sets up
 * honeytime/GDPR/recaptcha stuff if enabled, and passes relevant info to the submit handler
 */
export const compileSubscribeSettings = () => {
  const subscribeBlocks = document.querySelectorAll('[data-subscribe-form]');
  const blocksToEmpty = [];

  if (subscribeBlocks) {
    subscribeBlocks.forEach((subscribeBlock) => {
      const settingsUrl = subscribeBlock.dataset.signupFormSettingsUrl;

      const gdprContainer = subscribeBlock.querySelector(
        '[data-ref="gdpr-container"]',
      );

      const fieldsContainer = subscribeBlock.querySelector(
        '[data-ref="form-fields-container"]',
      );

      blocksToEmpty.push(gdprContainer, fieldsContainer);

      if (
        settingsUrl &&
        settingsUrl !== '*|WEBSITE:SIGNUP_FORM_SETTINGS_URL|*'
      ) {
        getWithCache(settingsUrl).then((data) => {
          const {
            captchaEnabled,
            recaptchaSitekey,
            honeytime,
            config,
            fields,
            subscribeUrl,
            language,
            isSimpleSignupForm,
          } = data;
          const {
            gdprEnabled,
            gdprDescription,
            gdprLabel,
            gdprLegal,
            gdprMCLegal,
            requireOneField,
            doubleOptin,
          } = config;
          const { gdprField, formFields } = splitGdprFieldsFromAllFields(
            fields,
            gdprEnabled,
            requireOneField,
            gdprDescription,
          );

          if (gdprEnabled) {
            const gdprSettings = {
              gdprField,
              gdprLabel,
              gdprLegal,
              gdprMCLegal,
            };
            const gdprBlock = buildGdprBlock(gdprSettings, subscribeBlock.id);
            gdprContainer.appendChild(gdprBlock);
          }

          let fieldConfig = {};

          if (fieldsContainer) {
            fieldConfig = destructiveReadSlice(subscribeBlock, 'fieldConfig');
            const parsedFormFields = parseFormFields(
              formFields,
              fieldConfig,
              isSimpleSignupForm,
            );
            const formBlock = buildFormFields(
              parsedFormFields,
              fieldConfig.isLabelVisible,
              subscribeBlock.id,
            );
            fieldsContainer.appendChild(formBlock);
          }

          subscribeBlock.setAttribute('aria-live', 'assertive');
          subscribeBlock.setAttribute('class', 'mceForm-element mceText');
          const formId = subscribeBlock.id;
          const recaptchaId = 'recaptcha' + formId;

          if (captchaEnabled) {
            window[`onGrecaptchaLoadCallback${recaptchaId}`] = () => {
              const widgetId = grecaptcha.render(recaptchaId, {
                sitekey: recaptchaSitekey,
                callback: (grecaptchaResponse) => {
                  const formData = buildFormData(
                    honeytime,
                    gdprEnabled,
                    subscribeBlock,
                  );
                  formData.set('g-recaptcha-response', grecaptchaResponse);
                  // Serialize the FormData into a string and pass it as query params
                  get(buildUrl(subscribeUrl, formData)).then((response) => {
                    if (response.result === 'success') {
                      if (window.mc_process_engagement_post_signup) {
                        window.mc_process_engagement_post_signup();
                      }
                      const successMessage = doubleOptin
                        ? language.COMPLETELINK
                        : language.SUBSCRIBETHANKS;

                      subscribeBlock.replaceWith(
                        buildSubmitFeedback('success', successMessage),
                      );
                    } else {
                      const errorFeedback = buildSubmitFeedback(
                        'error',
                        response.msg,
                      );
                      subscribeBlock.parentNode.insertBefore(
                        errorFeedback,
                        subscribeBlock,
                      );
                    }
                  });
                },
              });
              addSubmitHandler(
                honeytime,
                gdprEnabled,
                subscribeBlock,
                captchaEnabled,
                language,
                subscribeUrl,
                doubleOptin,
                widgetId,
              );
            };
            subscribeBlock.appendChild(
              buildRecaptchaField(recaptchaSitekey, recaptchaId),
            );
            loadRecaptchaJs(recaptchaId);
          }

          subscribeBlock.appendChild(buildHoneytimeField(honeytime));
          if (!captchaEnabled) {
            addSubmitHandler(
              honeytime,
              gdprEnabled,
              subscribeBlock,
              captchaEnabled,
              language,
              subscribeUrl,
              doubleOptin,
            );
          }
        });
      }
    });
  }

  // When the runtime is executing in the editor, we need to cleanup by emptying the target
  // containers before the next run to prevent duplicate content.
  return () => {
    blocksToEmpty.forEach((node) => {
      if (node) {
        node.textContent = '';
      }
    });
  };
};
