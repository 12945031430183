const CarouselSlideControlPreviousIcon =
  '<svg width="71" height="21" viewBox="0 0 71 21" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
  '    <line x1="3" y1="10.5" x2="70" y2="10.5" stroke-width="2"/>\n' +
  '    <path d="M11.5 20L2 10.5L11.5 1" stroke-width="2"/>\n' +
  '</svg>';

const CarouselSlideControlNextIcon =
  '<svg width="71" height="21" viewBox="1200 0 71 21" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
  '    <line x1="1268" y1="10.5" x2="1201" y2="10.5" stroke-width="2"/>\n' +
  '    <path d="M1259.5 1L1269 10.5L1259.5 20" stroke-width="2"/>\n' +
  '</svg>';

const CarouselSlidePickerIcon =
  '<svg width="20" height="20" viewBox="-1 -1 22 22" fill="none"' +
  ' xmlns="http://www.w3.org/2000/svg">\n' +
  '    <circle cx="10" cy="10" r="10" />\n' +
  '    <circle cx="10" cy="10" r="9" stroke-width="2"/>\n' +
  '</svg>';

/**
 * Creates an HTML button element that is ignored by the Section Manager's
 * click tracking.
 * @param {string} innerHTML
 * @param {string} value
 * @param {string} ariaLabel
 * @return {HTMLButtonElement}
 */
function createUiControlButton(innerHTML, value, ariaLabel) {
  const button = document.createElement('button');
  button.dataset.uiControl = 'true';
  button.innerHTML = innerHTML;
  button.value = value;

  button.setAttribute('aria-label', ariaLabel);
  button.setAttribute('role', 'button');

  return button;
}

function formatLabel(template, { direction, position, size } = {}) {
  return template.replace(/(\${(\w+)})/gi, (_, __, match) => {
    switch (match.toLowerCase()) {
      case 'direction':
        return direction;
      case 'position':
        return position;
      case 'size':
        return size;
      default:
        return '';
    }
  });
}

/**
 * Parses the given {@link value} as a decimal number or returns
 * {@link defaultValue} if the value is not a number.
 * @param {string} value
 * @param {number} defaultValue
 * @return {number}
 */
function parseDecimalOrDefault(value, defaultValue = 0) {
  const result = parseInt(value, 10);
  return isNaN(result) ? defaultValue : result;
}

/**
 * Instructs the inline editor preview to reset the currently selected block,
 * removing any block outlines.
 */
function resetSelectedBlock() {
  const event = new Event('mc:resetSelectedBlock', { bubbles: true });
  document.dispatchEvent(event);
}

/**
 * CSS class names used for {@link Carousel} positioning and rotating.
 * @type {{
 *  current: string,
 *  position: {next: string, previous: string},
 *  rotating: string,
 *  hasCurrent: function(HTMLElement): boolean,
 *  resetPosition: function(HTMLElement): void,
 *  resetRotating: function(HTMLElement): void,
 * }}
 */
const ClassNames = {
  current: 'mceCarousel-slide-current',
  position: {
    next: 'mceCarousel-slide-next',
    previous: 'mceCarousel-slide-previous',
  },
  rotating: 'mceCarousel-slide-rotating',

  /**
   * Returns true if the 'current' class name is applied to {@link slide}
   * @param {HTMLElement} slide
   * @return {boolean}
   */
  hasCurrent: function(slide) {
    return slide.matches(`.${ClassNames.current}`);
  },

  /**
   * Removes the positioning class names from {@link slide}
   * @param {HTMLElement} slide
   */
  resetPosition: function(slide) {
    slide.classList.remove(...Object.values(this.position));
  },

  /**
   * Removes the rotating class names from {@link slide}
   * @param {HTMLElement} slide
   */
  resetRotating: function(slide) {
    slide.classList.remove(this.rotating);
  },
};

/**
 * Represents a numeric value greater than or equal to 0 and bounded by
 * some maximum value, inclusive.
 */
class BoundedValue {
  maximumValue;
  value;

  constructor(maximumValue, value = 0) {
    this.maximumValue = maximumValue;
    this.value = this.constrain(value);
  }

  constrain(value) {
    switch (true) {
      case value < 0:
        return this.maximumValue;
      case value > this.maximumValue:
        return 0;
      default:
        return value;
    }
  }

  clone(value = this.value) {
    return new BoundedValue(this.maximumValue, value);
  }

  equals(value) {
    return this.value === value;
  }

  next() {
    return new BoundedValue(this.maximumValue, this.value + 1);
  }

  previous() {
    return new BoundedValue(this.maximumValue, this.value - 1);
  }
}

BoundedValue.prototype.toString = function() {
  return `${this.value}`;
};

BoundedValue.prototype.valueOf = function() {
  return this.value;
};

/**
 * The direction to advance the carousel rotation sequence.
 * @typedef {('previous'|'next')} RotationDirection
 */

/**
 * Represents an interactive element, often styled as an arrow, that
 * displays the next or previous slide in the carousel rotation sequence.
 */
class SlideControl {
  /**
   * Creates a {@link SlideControl}, inferring the {@link RotationDirection} by
   * comparing the {@link index} value to the optional {@link fromIndex} value.
   * @param {number} index
   * @param {number} [fromIndex=-1] fromIndex
   * @return {SlideControl}
   */
  static from(index, fromIndex = -1) {
    if (index < fromIndex) {
      return new SlideControl('previous');
    }

    return new SlideControl('next');
  }

  /**
   * The direction this {@link SlideControl} instance advances the rotation
   * sequence.
   * @type {RotationDirection}
   */
  direction;

  /**
   * Creates a new {@link SlideControl} instance.
   * @param {RotationDirection} direction
   */
  constructor(direction) {
    this.direction = direction;
  }

  /**
   * Compares this {@link SlideControl} direction to the given {@link value}.
   * @param {string} value
   * @return {boolean}
   */
  equals(value) {
    return this.direction === value;
  }

  /**
   * Returns a string containing an SVG icon that represents this control's
   * {@link RotationDirection}.
   * @return {string}
   */
  icon() {
    if (this.direction === 'previous') {
      return CarouselSlideControlPreviousIcon;
    } else if (this.direction === 'next') {
      return CarouselSlideControlNextIcon;
    }
  }

  /**
   * Returns a new {@link SlideControl} with the opposite
   * {@link RotationDirection} to this instance.
   * @return {SlideControl}
   */
  reverse() {
    if (this.direction === 'previous') {
      return new SlideControl('next');
    } else if (this.direction === 'next') {
      return new SlideControl('previous');
    }
  }
}

/**
 * Returns this instance's {@link RotationDirection} as a string.
 * @return {string}
 */
SlideControl.prototype.valueOf = function() {
  return this.direction;
};

SlideControl.prototype.toString = function() {
  return this.direction;
};

/**
 * @typedef {function()} DestroyFunction
 */

/**
 * An object that presents a set of items, referred to as slides, by
 * sequentially displaying a subset of one or more slides. Users can
 * activate a next or previous {@link SlideControl} that hides the current
 * slide and "rotates" the next or previous slide into view.
 *
 * This carousel is implemented as a stack of slides in the center of a
 * canvas with an empty space, each, to the previous (left) and next (right)
 * positions of that center stack. Only the current slide is visible.
 *
 * When the user advances the rotation sequence by clicking a slide
 * control or slide picker button, we move the incoming slide to the
 * previous or next position and apply a transition to move it back to the
 * center while we also apply a transition the outgoing slide to move it to the
 * opposite direction.
 */
class Carousel {
  /**
   * Creates a new {@link Carousel} instance.
   * @param container {HTMLElement}
   * @return {Carousel}
   */
  static create(container) {
    return new Carousel(container);
  }

  /**
   * The HTML element that contains this {@link Carousel}.
   * @type {HTMLElement}
   */
  container;

  /**
   * The index value of the current slide.
   * @type {BoundedValue}
   */
  currentIndex;

  /**
   * @type {DestroyFunction[]}
   */
  destroyFunctions = [];

  /**
   * The HTML element which will contain the "dots" for jumping to an
   * individual slide.
   * @type {HTMLElement}
   */
  slidePickerContainer;

  /**
   * The list of {@link Carousel} slides.
   * @type {NodeListOf<HTMLElement>}
   */
  slides;

  /**
   * @type {boolean}
   */
  uiControlsDisabled;

  handleSlideControlButtonClick = function(event) {
    event.stopPropagation();
    event.preventDefault();

    if (this.uiControlsDisabled) {
      return;
    }

    resetSelectedBlock();

    const { currentTarget: slideControlButton } = event;
    const slideControl = new SlideControl(slideControlButton.value);

    if (slideControl.equals('previous')) {
      this.setCurrentIndex(this.currentIndex.previous(), slideControl);
    } else if (slideControl.equals('next')) {
      this.setCurrentIndex(this.currentIndex.next(), slideControl);
    }
  }.bind(this);

  handleSlidePickerButtonClick = function(event) {
    event.stopPropagation();
    event.preventDefault();

    if (this.uiControlsDisabled) {
      return;
    }

    resetSelectedBlock();

    const { currentTarget: slidePickerButton } = event;

    const indexValue = parseDecimalOrDefault(slidePickerButton.value);
    const currentIndex = this.currentIndex.clone(indexValue);

    this.setCurrentIndex(currentIndex);
  }.bind(this);

  handleItemTransitionStart = function(event) {
    event.stopPropagation();
    event.preventDefault();

    const { currentTarget: slide } = event;
    const indexValue = parseDecimalOrDefault(slide.dataset.jsState);

    if (this.currentIndex.equals(indexValue)) {
      this.setUiControlsDisabled();
    }
  }.bind(this);

  handleItemTransitionEnd = function(event) {
    event.stopPropagation();
    event.preventDefault();

    const { currentTarget: slide, propertyName: transitionProperty } = event;

    if (transitionProperty !== 'transform') {
      return;
    }

    ClassNames.resetRotating(slide);

    if (ClassNames.hasCurrent(slide) && transitionProperty === 'transform') {
      const indexValue = parseDecimalOrDefault(slide.dataset.jsState);

      if (indexValue >= 0 && !this.currentIndex.equals(indexValue)) {
        const currentIndex = this.currentIndex.clone(indexValue);
        this.setCurrentIndexImmediate(currentIndex);
      }

      this.setUiControlsDisabled(false);
    }
  }.bind(this);

  /**
   * Creates a new {@link Carousel} instance.
   * @param container {HTMLElement}
   */
  constructor(container) {
    if (!container) {
      return;
    }

    this.container = container;

    this.initializeContainer();

    this.destroyFunctions = [
      ...this.initializeSlides(),
      ...this.initializeSlideControls(),
      ...this.initializeSlidePicker(),
    ];

    this.initializeCurrentIndex();
  }

  initializeContainer() {
    // Apply WAI-ARIA attributes:
    this.container.setAttribute('role', 'group');
    this.container.setAttribute('aria-roledescription', 'carousel');
  }

  /**
   * Sets default attributes and event listeners for the slides container
   * and the individual slides.
   * @return {DestroyFunction[]}
   */
  initializeSlides() {
    const slidesContainer = this.container.querySelector(
      '[data-js-target="carousel-slides"]',
    );

    // Apply WAI-ARIA attributes:
    slidesContainer.setAttribute('aria-atomic', 'false');
    slidesContainer.setAttribute('aria-live', 'polite');

    const destroyFunctions = /** @type {DestroyFunction[]} */ [];

    this.slides =
      /** @type {NodeListOf<HTMLElement>} */ slidesContainer.childNodes;

    const labelTemplate =
      this.container.dataset.carouselLabelSlideOfTotal ||
      'Slide ${position} of ${size}';

    this.slides.forEach((slide, indexValue) => {
      // Apply WAI-ARIA attributes:
      slide.setAttribute('role', 'group');
      slide.setAttribute('aria-roledescription', 'slide');

      if (!slide.getAttribute('aria-label')) {
        slide.setAttribute(
          'aria-label',
          formatLabel(labelTemplate, {
            position: indexValue + 1,
            size: this.slides.length,
          }),
        );
      }

      slide.addEventListener(
        'transitionstart',
        this.handleItemTransitionStart,
        false,
      );
      slide.addEventListener(
        'transitionend',
        this.handleItemTransitionEnd,
        false,
      );

      destroyFunctions.push(() => {
        slide.removeEventListener(
          'transitionstart',
          this.handleItemTransitionStart,
          false,
        );
        slide.removeEventListener(
          'transitionend',
          this.handleItemTransitionEnd,
          false,
        );
      });

      slide.dataset.jsState = `${indexValue}`;
    });

    return destroyFunctions;
  }

  /**
   * Insert slide control buttons and set their default attributes and event
   * listeners.
   * @return {DestroyFunction[]}
   */
  initializeSlideControls() {
    const destroyFunctions = /** @type {DestroyFunction[]} */ [];

    this.container
      .querySelectorAll('[data-ref^="carousel-slide-control-"]:empty')
      .forEach((slideControlContainer) => {
        const [, direction] = /^carousel-slide-control-(previous|next)$/i.exec(
          slideControlContainer.dataset.ref,
        );

        if (!direction) {
          return;
        }

        const slideControl = new SlideControl(direction);

        const labelTemplate =
          this.container.dataset[
            `carouselLabelControl${slideControl.direction}`
          ] || '${direction} slide';

        const slideControlButton = createUiControlButton(
          slideControl.icon(),
          slideControl.direction,
          formatLabel(labelTemplate, { direction: slideControl.direction }),
        );

        slideControlButton.addEventListener(
          'click',
          this.handleSlideControlButtonClick,
          false,
        );

        slideControlContainer.appendChild(slideControlButton);

        destroyFunctions.push(() => {
          slideControlButton.removeEventListener(
            'click',
            this.handleSlideControlButtonClick,
            false,
          );
          slideControlButton.remove();
        });
      });

    return destroyFunctions;
  }

  /**
   * Insert the slide picker control buttons and set their default
   * attributes and event listeners.
   *
   * The Slide Picker control is an interactive element, often styled as small
   * dots, that enable the user to pick a specific slide in the
   * rotation sequence to display.
   * @return {DestroyFunction[]}
   * @private
   */
  initializeSlidePicker() {
    const destroyFunctions = /** @type {DestroyFunction[]} */ [];

    this.slidePickerContainer = this.container.querySelector(
      '[data-ref="carousel-slide-picker"]:empty',
    );

    if (this.slidePickerContainer) {
      // Apply WAI-ARIA attributes:
      this.slidePickerContainer.setAttribute('role', 'group');

      this.slidePickerContainer.setAttribute(
        'aria-label',
        formatLabel(
          this.container.dataset.carouselLabelPicker ||
            'Choose Slide to display',
        ),
      );

      for (let indexValue = 0; indexValue < this.slides.length; indexValue++) {
        const slidePickerButton = createUiControlButton(
          CarouselSlidePickerIcon,
          `${indexValue}`,
          formatLabel(
            this.container.dataset.carouselLabelSlide || 'Slide ${position}',
            { position: indexValue + 1 },
          ),
        );

        slidePickerButton.addEventListener(
          'click',
          this.handleSlidePickerButtonClick,
          false,
        );

        destroyFunctions.push(() => {
          slidePickerButton.removeEventListener(
            'click',
            this.handleSlidePickerButtonClick,
            false,
          );
          slidePickerButton.remove();
        });

        this.slidePickerContainer.append(slidePickerButton);
      }

      return destroyFunctions;
    }
  }

  /**
   * Set the initial state for the carousel rotation.
   *
   * By default, we start with the first slide, but engineers can specify a
   * different starting index value in the attribute
   * data-carousel-selected-index on the carousel's container.
   *
   * As users navigate between slides, the current index is stored
   * in the container's data-js-state attribute, which overrides any other the
   * starting value --useful for when the block is re-rendered after the doc
   * is saved.
   */
  initializeCurrentIndex() {
    let indexValue = parseDecimalOrDefault(
      this.container.dataset.carouselSelectedIndex,
    );

    indexValue = parseDecimalOrDefault(
      this.container.dataset.jsState,
      indexValue,
    );

    let currentIndex = new BoundedValue(this.slides.length - 1, indexValue);

    currentIndex = this.setCurrentIndexImmediate(currentIndex);
    this.updateSlidePicker(currentIndex.value);
  }

  /**
   * Immediately updates the rotation sequence to the given {@link currentIndex}.
   * @param {BoundedValue} currentIndex
   * @return {BoundedValue}
   */
  setCurrentIndexImmediate(currentIndex) {
    this.currentIndex = currentIndex;

    this.setContainerState(this.currentIndex);

    this.slides.forEach((slide, indexValue) => {
      ClassNames.resetPosition(slide);

      if (this.currentIndex.equals(indexValue)) {
        slide.classList.add(ClassNames.current);
      }
    });

    return this.currentIndex;
  }

  /**
   * Begins a transition to update the rotation sequence to the given
   * {@link currentIndex} once the transition is complete.
   * @param {BoundedValue} currentIndex
   * @param  {SlideControl} [slideControl]
   */
  setCurrentIndex(currentIndex, slideControl) {
    if (currentIndex.equals(this.currentIndex.value)) {
      return;
    }

    // Store the current index in the container's state right away so that
    // if a user has made a change that starts saving the page doc but then
    // attempts to navigate to a new slide before the save is finished,
    // we'll correctly show the slide the user intended when the page is
    // saved and the block is re-rendered.
    this.setContainerState(currentIndex);

    if (!slideControl) {
      // Infer the slide control direction if none is given, say, if a user
      // clicks a slide picker button.
      slideControl = SlideControl.from(
        currentIndex.value,
        this.currentIndex.value,
      );
    }

    this.slides.forEach((slide, indexValue) => {
      ClassNames.resetRotating(slide);

      if (this.currentIndex.equals(indexValue)) {
        // Transition the outgoing slide out of view.
        slide.classList.add(ClassNames.rotating);
        slide.classList.replace(
          ClassNames.current,
          ClassNames.position[`${slideControl.reverse()}`],
        );
      } else if (currentIndex.equals(indexValue)) {
        this.updateSlidePicker(indexValue);
        ClassNames.resetPosition(slide);

        requestAnimationFrame(() => {
          // First, move the incoming slide into the previous or next position:
          slide.classList.add(ClassNames.position[`${slideControl}`]);

          requestAnimationFrame(() =>
            // Then, transition it back to the center:
            slide.classList.add(ClassNames.rotating, ClassNames.current),
          );
        });
      }
    });
  }

  /**
   * Sets the container's data-js-state attribute for storing the index
   * value between re-renders.
   * @param {BoundedValue} currentIndex
   */
  setContainerState(currentIndex) {
    this.container.dataset.jsState = `${currentIndex}`;
  }

  /**
   * Prevent additional click events while rotating.
   * @param {boolean} value
   */
  setUiControlsDisabled(value = true) {
    this.uiControlsDisabled = value;
  }

  /**
   * Sets the currently active slide picker control based on the {@link indexValue}.
   * @param {number} indexValue
   */
  updateSlidePicker(indexValue) {
    this.slidePickerContainer.childNodes.forEach((picker, pickerIndexValue) => {
      picker.setAttribute('aria-disabled', pickerIndexValue === indexValue);
    });
  }

  /**
   * Remove event listeners and complete any other cleanup.
   */
  destroy() {
    this.destroyFunctions.forEach((destroyFunction) => destroyFunction());
  }
}

/**
 * Creates an array of {@link Carousel} objects and returns a function that
 * runs the destroy function for each.
 * @return {(function(): void)|void}
 */
export default function() {
  const containers = document.querySelectorAll('[data-js-target="carousel"]');
  if (!containers.length) {
    return;
  }

  const carousels = /** @type {Carousel[]} */ Array.prototype.map.call(
    containers,
    Carousel.create,
  );

  return function() {
    carousels.forEach((carousel) => carousel.destroy());
  };
}
