class CookieBanner {
  constructor(bannerNode) {
    this.banner = bannerNode;
    // isEnabled is only applied via the php pipeline
    this.isOutsideOfEditor = bannerNode.dataset.isEnabled;
    this.legalCopy = null;
    this.acceptButton = null;
    this.closeButtonWrapper = null;
    this.closeButton = null;
  }

  startup() {
    // only run outside of the editor
    if (this.isOutsideOfEditor && this.banner.children.length > 0) {
      this.queryElements();
      this.createCloseButton();
      this.addEventListeners();
      this.displayBanner();
    }
  }

  queryElements() {
    this.legalCopy = this.banner.querySelector(
      '[data-cookie-banner-legal-copy]',
    );
    this.acceptButton = this.banner.querySelector(
      '[data-cookie-banner-accept-btn]',
    );
    this.closeButtonWrapper = this.banner.querySelector(
      '[data-ref="cookie-banner-close-btn"]',
    );
  }

  createCloseButton() {
    // create close button
    const legalCopyStyles = window.getComputedStyle(this.legalCopy);
    this.closeButtonWrapper.setAttribute(
      'style',
      `
        position: absolute;
        top: 0;
        right: 0;
      `,
    );
    this.closeButton = document.createElement('button');
    this.closeButton.setAttribute(
      'style',
      `
        padding: 16px;
        cursor: pointer;
        color: ${legalCopyStyles.getPropertyValue('color')};
        background: none;
        border: none;
      `,
    );
    this.closeButton.setAttribute('aria-label', 'Close banner');
    this.closeButton.innerHTML = '&times;';

    this.closeButtonWrapper.appendChild(this.closeButton);
  }

  addEventListeners() {
    this.acceptButton.addEventListener('click', this.handleAcceptClick);
    this.closeButton.addEventListener('click', this.handleCloseClick);
  }

  displayBanner() {
    if (this.shouldHideCookieBanner()) {
      return;
    }

    if (this.banner.dataset.isEnabled) {
      this.banner.style.display = 'flex';
    }

    if (!this.optinRequired()) {
      this.acceptButton.style.display = 'none';
    }
  }

  handleCloseClick = (e) => {
    this.banner.style.display = 'none';
    document.cookie = 'mc_hide_cookie_banner=true;path=/;max-age=31556952;'; // this number equals 1 year in seconds
    e.preventDefault();
    e.stopPropagation();
  };

  handleAcceptClick = (e) => {
    this.banner.style.display = 'none';
    document.cookie = 'mc_user_optin=true;path=/;max-age=31556952;'; // this number equals 1 year in seconds
    document.cookie = 'mc_hide_cookie_banner=true;path=/;max-age=31556952;';
    if (window.$mcSite) {
      window.$mcSite.enableOptIn();
    }
    e.preventDefault();
    e.stopPropagation();
  };

  optinRequired() {
    const cookieValue = document.cookie
      .split('; ')
      .find((row) => row.startsWith('mc_user_optin'));
    if (cookieValue) {
      return true;
    }
    return false;
  }

  shouldHideCookieBanner() {
    if (this.optinRequired()) {
      const cookieValue = document.cookie
        .split('; ')
        .find((row) => row.startsWith('mc_user_optin'))
        .split('=');
      if (cookieValue[1] === 'true') {
        return true;
      }
      return false;
    }

    const cookieValue = document.cookie
      .split('; ')
      .find((row) => row.startsWith('mc_hide_cookie_banner'));
    if (cookieValue) {
      return true;
    }
    return false;
  }
}

export const startupCookieBanner = () => {
  const banners = document.querySelectorAll('[data-cookie-banner]');
  banners.forEach((banner) => new CookieBanner(banner).startup());
};
