import { buildCartDropdown } from './cartDropdown';
import { icon } from './commerceIcons';

export function buildCartIcon() {
  const cartIcon = document.querySelector('[data-ref="cartIcon"]');

  if (!cartIcon) {
    return;
  }

  // If the cart icon container already has children, assumed we've performed this setup
  // already and return. Prevents against duplicate cart icons when this runtime is ran
  // multiple times.
  if (cartIcon.children.length > 0) {
    return;
  }

  cartIcon.id = 'cartIcon';
  let cartData = document.getElementById('js-cart-data');

  if (cartData) {
    cartData = JSON.parse(cartData.textContent);
    const { cart, cartQuantity } = cartData;
    if (cartQuantity > 0) {
      buildCartDropdown(cart, cartQuantity);
    }
  }

  // build cart icon
  cartIcon.classList.add('mceCartIcon');

  // use a <div> for preview, otherwise, use a real link
  const cartLink = document.createElement(
    cartData && cartData.forPreview ? 'div' : 'a',
  );

  if (cartData && !cartData.forPreview) {
    cartLink.setAttribute('href', '/store/cart');
  }

  // Finally, ensure we set the ID and contents of the anchor, then append it.
  cartLink.id = 'cartLink';
  cartLink.innerHTML = icon;
  cartIcon.appendChild(cartLink);

  // With the cart icon created, handle the cart quantity badge (if there is one).
  if (cartData && cartData.cartQuantity && cartData.cartQuantity > 0) {
    const cartCountElement = document.createElement('div');
    cartCountElement.classList.add('mceCartCount');
    cartCountElement.id = 'cartCount';
    cartLink.appendChild(cartCountElement);
    cartCountElement.ariaLabel = `${cartData.cartQuantity} item(s) in the cart`;
    cartCountElement.innerText = cartData.cartQuantity;
  }

  return () => cartIcon.removeChild(cartLink);
}

export function updateCartCount(cart) {
  const cartCount = document.getElementById('cartLink');
  // create the cart notification
  if (cart.quantity > 0) {
    const cartCountElement = document.createElement('div');
    cartCountElement.classList.add('mceCartCount');
    cartCountElement.id = 'cartCount';
    cartCount.appendChild(cartCountElement);
    cartCountElement.ariaLabel = `${cart.quantity} item(s) in the cart`;
    cartCountElement.innerText = cart.quantity;
  }
}
