import env from '../env';
const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const DAYS = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

function pluralize(one, other, count) {
  return count === 1 ? one : other;
}

const reduce = (state, action, data) => {
  if (action === 'CHANGE_MONTH') {
    const currentDate = state.calendarMonthInView;

    return {
      ...state,
      calendarMonthInView: new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + data.changeMonth,
        1,
      ),
      isLoadingTimeslotData: true,
      selectedDate: null,
      selectedTime: null,
    };
  }

  if (action === 'LOADED_TIMESLOT_DATA') {
    const currentMonth = state.calendarMonthInView;
    const firstAvailability = new Date(
      data.timeslotData && Object.keys(data.timeslotData)[0],
    );

    // If the first availability is not the current month, change the current month
    if (
      state.isInitialRequest &&
      !data.selectedDate &&
      !isNaN(firstAvailability) &&
      (firstAvailability.getFullYear() !== currentMonth.getFullYear() ||
        firstAvailability.getMonth() !== currentMonth.getMonth())
    ) {
      return {
        ...state,
        calendarMonthInView: new Date(
          firstAvailability.getFullYear(),
          firstAvailability.getMonth(),
          1,
        ),
        isInitialRequest: false,
      };
    }

    return {
      ...state,
      calendarMonthInView: currentMonth,
      isLoadingTimeslotData: false,
      isInitialRequest: false,
      timeslotData: data.timeslotData,
      maxAttendees: data.maxAttendees,
      selectedDate: data.selectedDate,
      selectedTime: data.selectedTime,
    };
  }

  if (action === 'SELECT_DATE') {
    const monthToShow = new Date(
      data.selectedDate.getFullYear(),
      data.selectedDate.getMonth(),
      1,
    );
    return {
      ...state,
      calendarMonthInView: monthToShow,
      selectedDate: data.selectedDate,
      selectedTime: null,
      isLoadingTimeslotData: !isSameDay(state.calendarMonthInView, monthToShow),
    };
  }

  if (action === 'SELECT_TIME') {
    return {
      ...state,
      selectedTime: data.selectedTime,
    };
  }

  return { ...state };
};

function isSameDay(firstDate, secondDate) {
  return (
    firstDate.getFullYear() === secondDate.getFullYear() &&
    firstDate.getMonth() === secondDate.getMonth() &&
    firstDate.getDate() === secondDate.getDate()
  );
}

function getDaysInMonth(year, month) {
  return 32 - new Date(year, month, 32).getDate();
}

function getCalendarRows(year, month, startOfWeek, includeLastRow = true) {
  const firstDayOffset = (7 - startOfWeek + new Date(year, month).getDay()) % 7;

  const dayCount = getDaysInMonth(year, month);
  const prevDayCount = getDaysInMonth(year, month - 1);

  const rows = [];

  outer: for (let row = 0; row < 6; row++) {
    const rowData = [];
    for (let col = 0; col < 7; col++) {
      const cellIndex = row * 7 + col;

      if (
        !includeLastRow &&
        col === 0 &&
        cellIndex + 1 - firstDayOffset > dayCount
      ) {
        // handle the case of the needless row
        break outer;
      } else if (row === 0 && cellIndex < firstDayOffset) {
        // prev month
        const date = prevDayCount - firstDayOffset + col + 1;
        rowData.push({
          date,
          monthOffset: -1,
          instance: new Date(year, month - 1, date, 0, 0, 0),
        });
      } else if (cellIndex - firstDayOffset + 1 > dayCount) {
        // following month
        const date = ((cellIndex - firstDayOffset) % dayCount) + 1;
        rowData.push({
          date,
          monthOffset: 1,
          instance: new Date(year, month + 1, date, 0, 0, 0),
        });
      } else {
        // current month
        const date = cellIndex + 1 - firstDayOffset;
        rowData.push({
          date,
          monthOffset: 0,
          instance: new Date(year, month, date, 0, 0, 0),
        });
      }
    }
    rows.push(rowData);
  }
  return rows;
}

function renderMonthHeading(state, setNewMonth) {
  const monthInView = state.calendarMonthInView.getMonth();
  const yearInView = state.calendarMonthInView.getFullYear();
  const currentDate = new Date();

  const root = document.createElement('div');
  root.setAttribute('class', 'mceServices-calendar-month');

  const leftMenu = document.createElement('button');
  leftMenu.innerText = '<';
  // Hide left menu button if it's the current month
  if (
    (yearInView === currentDate.getFullYear() &&
      monthInView === currentDate.getMonth()) ||
    state.isLoadingTimeslotData
  ) {
    leftMenu.disabled = true;
  }
  leftMenu.addEventListener('click', (e) => {
    e.preventDefault();
    setNewMonth(-1);
  });

  const rightMenu = document.createElement('button');
  rightMenu.innerText = '>';
  rightMenu.addEventListener('click', (e) => {
    e.preventDefault();
    setNewMonth(1);
  });
  if (state.isLoadingTimeslotData) {
    rightMenu.disabled = true;
  }

  const monthText = document.createElement('p');
  monthText.innerText = `${MONTHS[monthInView]} ${yearInView}`;

  root.appendChild(leftMenu);
  root.appendChild(monthText);
  root.appendChild(rightMenu);

  return root;
}

function renderWeeks(state, setSelectedDate) {
  const monthInView = state.calendarMonthInView.getMonth();
  const yearInView = state.calendarMonthInView.getFullYear();

  const table = document.createElement('table');
  table.setAttribute('class', 'mceServices-calendar-days');

  // Assemble the header for the table
  const header = document.createElement('thead');
  const headerRow = document.createElement('tr');

  table.appendChild(header);
  header.appendChild(headerRow);

  for (let day = 0; day < 7; day++) {
    const dayName = DAYS[(day + state.startOfWeek) % 7];
    const dayHeader = document.createElement('th');
    dayHeader.innerHTML = dayName.slice(0, 3).toUpperCase();
    headerRow.appendChild(dayHeader);
  }

  // Assemble the body for the table
  const body = document.createElement('tbody');
  const daysRowsData = getCalendarRows(
    yearInView,
    monthInView,
    state.startOfWeek,
  );

  daysRowsData.forEach((week) => {
    const weekRow = document.createElement('tr');
    week.forEach((day) => {
      const weekDay = document.createElement('td');

      weekDay.appendChild(renderDay(day, state, setSelectedDate));
      weekRow.appendChild(weekDay);
    });
    body.appendChild(weekRow);
  });
  table.appendChild(body);

  return table;
}

export function getDayKey(date) {
  return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
    2,
    '0',
  )}-${String(date.getDate()).padStart(2, '0')}`;
}
function renderDay(day, state, setSelectedDate) {
  const button = document.createElement('button');

  const hasTimeslots =
    !!state.timeslotData && !!state.timeslotData[getDayKey(day.instance)];

  button.innerText = day.date;
  button.dataset.value = getDayKey(day.instance);
  // Disable days in the previous month, empty days, and during loading
  if (!hasTimeslots || state.isLoadingTimeslotData) {
    button.setAttribute('disabled', '');
    button.dataset.value = null;
  }

  if (day.monthOffset !== 0) {
    button.setAttribute('class', 'translucent_button');
  }

  button.addEventListener('click', (e) => {
    e.preventDefault();
    setSelectedDate(day.instance);
  });

  return button;
}
function highlightSelectedDay(root, state) {
  if (!state.timeslotData) {
    return;
  }
  const buttonToUnhighlight = root.querySelector(`.selected-date`);
  if (buttonToUnhighlight) {
    buttonToUnhighlight.classList.remove('selected-date');
  }
  if (state.selectedDate) {
    const buttonToHighlight = root.querySelector(
      `[data-value="${getDayKey(state.selectedDate)}"]`,
    );
    if (buttonToHighlight) {
      buttonToHighlight.classList.add('selected-date');
    }
  }
}

function renderCalendar(root, state, render) {
  const findMonthContainer = root.querySelector('.mceServices-calendar-month');
  const findWeeksContainer = root.querySelector('.mceServices-calendar-days');

  if (findMonthContainer) {
    root.removeChild(findMonthContainer);
  }
  root.appendChild(
    renderMonthHeading(state, (changeMonth) => {
      const newState = reduce(state, 'CHANGE_MONTH', { changeMonth });
      render(newState, state);
    }),
  );

  if (findWeeksContainer) {
    root.removeChild(findWeeksContainer);
  }
  root.appendChild(
    renderWeeks(state, (selectedDate) => {
      const newState = reduce(state, 'SELECT_DATE', { selectedDate });
      render(newState, state);
    }),
  );
}

export function getTimezoneString(timezoneId, isShort) {
  let timezone = '';
  try {
    const tzObject = Intl.DateTimeFormat('en-US', {
      timeZone: timezoneId,
      timeZoneName: 'long',
    })
      .formatToParts(new Date())
      .find((part) => part.type === 'timeZoneName');
    switch (tzObject.value) {
      case 'Pacific Standard Time':
      case 'Pacific Daylight Time':
        timezone = isShort ? 'PT' : 'Pacific Time';
        break;
      case 'Mountain Standard Time':
      case 'Mountain Daylight Time':
        timezone = isShort ? 'MT' : 'Mountain Time';
        break;
      case 'Central Standard Time':
      case 'Central Daylight Time':
        timezone = isShort ? 'CT' : 'Central Time';
        break;
      case 'Eastern Standard Time':
      case 'Eastern Daylight Time':
        timezone = isShort ? 'ET' : 'Eastern Time';
        break;
      default:
        timezone = tzObject.value;
    }
  } catch (e) {
    timezone = timezoneId;
  }
  return timezone;
}

function renderTimeHeading(state) {
  const root = document.createElement('div');
  root.setAttribute('class', 'mceServices-time-heading');

  const selectedDate = new Date(state.selectedDate);

  const timeslots =
    state.timeslotData &&
    state.selectedDate &&
    state.timeslotData[getDayKey(state.selectedDate)];
  const timezone = timeslots && timeslots[0] && timeslots[0].timezoneLabel;

  const subHeading = document.createElement('h4');
  subHeading.innerText = "Here's what's available for";

  const heading = document.createElement('h3');
  heading.innerText = `${DAYS[selectedDate.getDay()]} ${
    MONTHS[selectedDate.getMonth()]
  } ${selectedDate.getDate()}`;

  const copy = document.createElement('p');
  copy.innerText = `The following timeslots are in ${timezone}`;

  root.appendChild(subHeading);
  root.appendChild(heading);
  root.appendChild(copy);

  return root;
}

function renderTime(root, state, render) {
  const timeSlots =
    state.timeslotData &&
    state.selectedDate &&
    state.timeslotData[getDayKey(state.selectedDate)];
  const findTime = root.querySelector('.mceServices-time');

  if (findTime) {
    root.removeChild(findTime);
  }

  if (
    state.selectedDate &&
    timeSlots &&
    timeSlots.length &&
    !state.isLoadingTimeslotData
  ) {
    const container = document.createElement('div');
    container.setAttribute('class', 'mceServices-time');
    const subContainer = document.createElement('div');
    subContainer.setAttribute('class', 'mceServices-time-navigation');

    const timeSlotsGrid = document.createElement('div');
    timeSlotsGrid.setAttribute(
      'class',
      'mceServices-time-slots-grid-container',
    );
    const leftMenu = document.createElement('button');
    leftMenu.setAttribute('class', 'timeslot-nav');
    leftMenu.innerText = '<';
    leftMenu.addEventListener('click', () => {
      timeSlotsGrid.scrollTo({
        top: 0,
        left: timeSlotsGrid.scrollLeft - 190,
        behavior: 'smooth',
      });
    });
    const rightMenu = document.createElement('button');
    rightMenu.setAttribute('class', 'timeslot-nav');
    rightMenu.innerText = '>';
    rightMenu.addEventListener('click', () => {
      timeSlotsGrid.scrollTo({
        top: 0,
        left: timeSlotsGrid.scrollLeft + 190,
        behavior: 'smooth',
      });
    });
    timeSlots.forEach((timeSlot) => {
      // for maxAttendees gt 1, show vacancy for less than 3 left
      const button = document.createElement('button');
      button.dataset.value = timeSlot.formatted;
      button.innerText = timeSlot.formatted;

      button.addEventListener('click', (e) => {
        e.preventDefault();
        const newState = reduce(state, 'SELECT_TIME', {
          selectedTime: timeSlot,
        });
        render(newState, state);
      });
      if (state.maxAttendees > 1) {
        const containerTimeslot = document.createElement('div');
        const pEl = document.createElement('p');
        pEl.setAttribute('class', 'spots-left');
        if (timeSlot.vacancyCount < state.maxAttendees) {
          const spotsText = pluralize('spot', 'spots', timeSlot.vacancyCount);
          pEl.innerText = `${timeSlot.vacancyCount} ${spotsText} left!`;
        }
        containerTimeslot.appendChild(button);
        containerTimeslot.appendChild(pEl);
        timeSlotsGrid.appendChild(containerTimeslot);
      } else {
        timeSlotsGrid.appendChild(button);
      }
    });

    container.appendChild(renderTimeHeading(state));
    subContainer.appendChild(leftMenu);
    subContainer.appendChild(timeSlotsGrid);
    subContainer.appendChild(rightMenu);
    container.appendChild(subContainer);
    root.appendChild(container);
  }
}

function highlightSelectedTime(root, state) {
  if (!state.timeslotData) {
    return;
  }
  const buttonToUnhighlight = root.querySelector(`.selected-time`);
  if (buttonToUnhighlight) {
    buttonToUnhighlight.classList.remove('selected-time');
  }
  if (state.selectedTime) {
    const buttonToHighlight = root.querySelector(
      `[data-value="${state.selectedTime.formatted}"]`,
    );
    buttonToHighlight.classList.add('selected-time');
  }
}

function renderConfirmButton(root, state, onSave) {
  const button = document.createElement('div');
  button.setAttribute('class', 'mceButton');
  const findButton = root.querySelector('.mceButton');

  if (findButton) {
    root.removeChild(findButton);
  }

  const nativeButton = document.createElement('button');
  nativeButton.innerText = 'Continue To Confirmation';

  if (!state || !state.selectedDate || !state.selectedTime) {
    nativeButton.setAttribute('disabled', '');
  } else {
    nativeButton.addEventListener('click', (e) => {
      e.preventDefault();
      nativeButton.setAttribute('disabled', 'disabled');
      onSave(state.selectedDate, {
        ...state.selectedTime,
      });
    });
  }

  button.appendChild(nativeButton);
  root.append(button);
}

export function buildDateAndTime(
  selectedDate,
  selectedTime,
  onSave,
  getTimeslotData,
  startOfWeek,
  initialSelectedTimeslot,
) {
  const root = document.createElement('div');

  const rowContainer = document.createElement('div');
  rowContainer.setAttribute('class', 'mceServices-date-and-time');

  const calendarWrapper = document.createElement('div');
  calendarWrapper.setAttribute('class', 'mceServices-calendar-wrapper');
  rowContainer.appendChild(calendarWrapper);

  const calendarNode = document.createElement('div');
  calendarNode.setAttribute('class', 'mceServices-calendar');
  calendarWrapper.appendChild(calendarNode);

  const timeNode = document.createElement('div');
  timeNode.setAttribute('class', 'mceServices-time-wrapper');
  rowContainer.appendChild(timeNode);

  const buttonNode = document.createElement('div');
  buttonNode.setAttribute(
    'class',
    'mceServices-date-and-time-button-container',
  );

  root.append(rowContainer);
  root.append(buttonNode);

  let monthInView = selectedDate || new Date();

  // If booker is returning to this page from another page and has a pre-selected timeslot
  if (!selectedDate && initialSelectedTimeslot) {
    monthInView = new Date(0);
    monthInView.setUTCSeconds(initialSelectedTimeslot.startTime);
  }

  const initialState = reduce(
    {
      calendarMonthInView: new Date(
        monthInView.getFullYear(),
        monthInView.getMonth(),
        1,
      ),
      isLoadingTimeslotData: env.isForPublicConsumption,
      isInitialRequest: selectedDate ? false : true,
      timeslotData: null,
      selectedDate,
      selectedTime,
      startOfWeek,
      initialSelectedTimeslot,
    },
    'SET_UP',
  );

  const render = async (state, previousState) => {
    if (
      !previousState ||
      state.timeslotData !== previousState.timeslotData ||
      !isSameDay(state.calendarMonthInView, previousState.calendarMonthInView)
    ) {
      renderCalendar(calendarNode, state, render);
    }
    highlightSelectedDay(calendarNode, state);

    if (
      !previousState ||
      state.selectedDate !== previousState.selectedDate ||
      state.timeslotData !== previousState.timeslotData ||
      !isSameDay(state.calendarMonthInView, previousState.calendarMonthInView)
    ) {
      renderTime(timeNode, state, render);
    }
    highlightSelectedTime(timeNode, state);

    renderConfirmButton(buttonNode, state, onSave, render);

    if (state.isLoadingTimeslotData) {
      const endOfMonthPlusTwoWeeks = new Date(
        state.calendarMonthInView.getFullYear(),
        state.calendarMonthInView.getMonth() + 1,
        14,
      );
      const data = await getTimeslotData(
        state.calendarMonthInView,
        endOfMonthPlusTwoWeeks,
        state.isInitialRequest,
      );

      let matchingTimeslotData = {
        selectedDate: state.selectedDate,
        selectedTime: state.selectedTime,
      };
      if (
        state.isInitialRequest &&
        state.initialSelectedTimeslot &&
        data &&
        data.timeslots
      ) {
        const slotsForDay =
          data.timeslots[state.initialSelectedTimeslot.startDate] || [];
        const matchingTimeslot = slotsForDay.find(
          (slot) =>
            slot.startTime === state.initialSelectedTimeslot.startTime &&
            slot.vacancyCount > 0,
        );
        if (matchingTimeslot) {
          const dateParts = state.initialSelectedTimeslot.startDate.split('-');
          matchingTimeslotData = {
            selectedDate: new Date(
              Number(dateParts[0]),
              Number(dateParts[1]) - 1,
              Number(dateParts[2]),
              0,
              0,
              0,
            ),
            selectedTime: matchingTimeslot,
          };
        }
      }

      const newState = reduce(state, 'LOADED_TIMESLOT_DATA', {
        timeslotData: data.timeslots,
        timezoneId: data.timezoneId,
        maxAttendees: data.maxAttendees,
        ...matchingTimeslotData,
      });
      render(newState, state);
    }
  };

  render(initialState, null);

  return root;
}
