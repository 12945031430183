import countries from '../countries';

const REQUIRED_INDICATOR = '*';

/**
 * Creates a unique ID that's safe to use as a query selector
 *
 * Example: getDomSafeId(mce12345, 'ADDRESS[city]') => mce12345_ADDRESS-city
 * Multichoice example: getDomSafeId(mce12345, 'group[1]', 2) => mce12345_group-1__2
 *
 * @param {string} formId
 * @param {string} fieldName
 * @param {integer} multiChoiceValue
 */
export const getDomSafeId = (formId, fieldName, multiChoiceValue = null) => {
  const domSafeRegex = /\[([a-zA-Z0-9]+)\]/;
  const safeId = fieldName.replace(domSafeRegex, '-$1');
  let uniqueId = `${formId}_${safeId}`;
  if (multiChoiceValue) {
    const safeValue = multiChoiceValue.toString().replace(domSafeRegex, '-$1');
    uniqueId = `${uniqueId}__${safeValue}`;
  }
  return uniqueId;
};

/**
 * Builds a set of radio buttons or checkboxes
 *
 * @param {object} param0 - formField
 * @param {string} formField.name
 * @param {string} formField.label
 * @param {string} formField.type
 * @param {boolean} formField.required
 * @param {array} formField.choices
 * @param {boolean} isPreview - when viewed inside the editor, used to apply tab-index="-1"
 */
const buildMultipleChoice = (
  { name, label, type, required, choices },
  formId,
  isPreview,
) => {
  const fieldset = document.createElement('fieldset');
  fieldset.setAttribute('class', 'mceFieldset');
  if (required) {
    fieldset.setAttribute('data-is-required', true);
  }

  const legend = document.createElement('legend');
  legend.setAttribute('class', 'mceLabel');
  legend.textContent = label;
  legend.setAttribute('id', getDomSafeId(formId, name));
  if (required) {
    legend.textContent += ` ${REQUIRED_INDICATOR}`;
  }
  fieldset.appendChild(legend);

  choices.forEach((choice) => {
    const choiceId = getDomSafeId(formId, name, choice.value);
    const choiceLabel = document.createElement('label');
    const input = document.createElement('input');
    const labelText = document.createElement('span');
    labelText.textContent = choice.label;
    choiceLabel.setAttribute('class', 'mceLabel');
    choiceLabel.setAttribute('for', choiceId);
    input.setAttribute('type', type);
    input.setAttribute('name', name);
    input.setAttribute('value', choice.value);
    input.setAttribute('class', 'mceInput');
    input.setAttribute('id', choiceId);
    if (isPreview) {
      input.setAttribute('tabindex', '-1');
    }
    choiceLabel.appendChild(input);
    choiceLabel.appendChild(labelText);
    fieldset.appendChild(choiceLabel);
  });

  return fieldset;
};

/**
 * Builds a single checkbox. If multiple checkboxes are needed, use MultipleChoice
 *
 * @param {object} param0 - formField
 * @param {string} formField.name
 * @param {string} formField.label
 * @param {string} formField.type
 * @param {boolean} formField.required
 * @param {boolean} isPreview - when viewed inside the editor, used to apply tab-index="-1"
 */
const buildCheckbox = ({ name, label, type, required }, formId, isPreview) => {
  const inputGroup = document.createElement('div');
  inputGroup.setAttribute('class', 'mceInputGroup');

  const input = document.createElement('input');
  input.setAttribute('class', 'mceInput');
  input.setAttribute('type', type);
  input.setAttribute('name', name);
  input.setAttribute('id', getDomSafeId(formId, name));

  if (required) {
    input.setAttribute('required', true);
  }
  if (isPreview) {
    input.setAttribute('tabindex', '-1');
  }

  const fieldLabel = document.createElement('label');
  fieldLabel.setAttribute('class', 'mceLabel');
  fieldLabel.appendChild(input);
  fieldLabel.innerHTML += `${label} ${required ? REQUIRED_INDICATOR : ''}`;

  inputGroup.appendChild(fieldLabel);

  return inputGroup;
};

/**
 * Builds a select input
 *
 * @param {object} formField
 * @param {string} formField.name
 * @param {string} formField.label
 * @param {boolean} formField.required
 * @param {array} formField.choices
 * @param {boolean} isLabelVisible
 * @param {string} formId
 * @param {boolean} isPreview - when viewed inside the editor, used to apply tab-index="-1"
 * @param {string|number} initialValue
 */
const buildDropdown = (
  { name, label, required, choices },
  isLabelVisible,
  formId,
  isPreview,
  initialValue,
) => {
  const inputGroup = document.createElement('div');
  inputGroup.setAttribute('class', 'mceInputGroup');
  const select = document.createElement('select');
  select.setAttribute('class', 'mceSelect');
  select.setAttribute('name', name);
  select.setAttribute('id', getDomSafeId(formId, name));
  if (required) {
    select.setAttribute('required', true);
  }

  const blankOption = document.createElement('option');
  blankOption.setAttribute('value', ''); // The value of the blank option must be "" for the required attribute to function

  if (isLabelVisible) {
    const fieldLabel = document.createElement('label');
    fieldLabel.textContent = label;
    if (required) {
      fieldLabel.textContent += ` ${REQUIRED_INDICATOR}`;
    }
    fieldLabel.setAttribute('class', 'mceLabel');
    fieldLabel.setAttribute('for', name);
    inputGroup.appendChild(fieldLabel);
  } else {
    select.setAttribute('aria-label', label);
    blankOption.textContent = label;
  }
  if (isPreview) {
    select.setAttribute('tabindex', '-1');
  }

  select.appendChild(blankOption);
  choices.forEach((choice) => {
    const option = document.createElement('option');
    option.textContent = choice.label;
    option.setAttribute('value', choice.value);
    // Pre-select the initial value
    if (choice.value === initialValue) {
      option.setAttribute('selected', 'selected');
    }
    select.appendChild(option);
  });

  inputGroup.appendChild(select);
  return inputGroup;
};

/**
 * Builds a text input
 *
 * @param {object} formField
 * @param {string} formField.name
 * @param {string} formField.label
 * @param {string} formField.type
 * @param {boolean} formField.required
 * @param {boolean} isLabelVisible
 * @param {string} formId
 * @param {boolean} isPreview - when viewed inside the editor, used to apply tab-index="-1"
 */
const buildTextbox = (
  { name, label, type, required, helper_text },
  isLabelVisible,
  formId,
  isPreview,
) => {
  const inputGroup = document.createElement('div');
  inputGroup.setAttribute('class', 'mceInputGroup');

  const input = document.createElement('input');
  input.setAttribute('class', 'mceInput');
  input.setAttribute('type', type);
  input.setAttribute('name', name);
  input.setAttribute('id', getDomSafeId(formId, name));

  if (required) {
    input.setAttribute('required', true);
  }
  if (isPreview) {
    input.setAttribute('tabindex', '-1');
  }

  if (isLabelVisible) {
    const fieldLabel = document.createElement('label');
    fieldLabel.textContent = label;
    if (required && input.name !== 'ADDRESS[addr2]') {
      fieldLabel.textContent += ` ${REQUIRED_INDICATOR}`;
    }
    fieldLabel.setAttribute('class', 'mceLabel');
    fieldLabel.appendChild(input);
    if (helper_text) {
      const helperTextLabel = document.createElement('label');
      helperTextLabel.textContent = helper_text;
      helperTextLabel.setAttribute('class', 'mceHelperText');
      helperTextLabel.setAttribute(
        'id',
        getDomSafeId(formId, name + 'helperText'),
      );
      fieldLabel.appendChild(helperTextLabel);
    }
    inputGroup.appendChild(fieldLabel);
  } else {
    input.setAttribute('aria-label', label);
    inputGroup.appendChild(input);
  }

  return inputGroup;
};

/**
 * Builds a group of inputs for an address merge field
 *
 * @param {object} formField
 * @param {string} formField.name
 * @param {string} formField.label
 * @param {boolean} formField.required
 * @param {boolean} isLabelVisible
 * @param {string} formId
 * @param {boolean} isPreview - when viewed inside the editor, used to apply tab-index="-1"
 * @param {number} defaultCountry - country to select by default
 */
const buildAddressField = (
  { name, label, required },
  isLabelVisible,
  formId,
  isPreview,
  defaultCountry,
) => {
  const addressContainer = new DocumentFragment();
  addressContainer.appendChild(
    buildTextbox(
      { name: `${name}[addr1]`, label, type: 'text', required },
      isLabelVisible,
      formId,
      isPreview,
    ),
  );
  addressContainer.appendChild(
    buildTextbox(
      {
        name: `${name}[addr2]`,
        label: 'Address Line 2',
        type: 'text',
        required,
      },
      isLabelVisible,
      formId,
      isPreview,
    ),
  );
  addressContainer.appendChild(
    buildTextbox(
      { name: `${name}[city]`, label: 'City', type: 'text', required },
      isLabelVisible,
      formId,
      isPreview,
    ),
  );
  addressContainer.appendChild(
    buildTextbox(
      {
        name: `${name}[state]`,
        label: 'State / Province / Region',
        type: 'text',
        required,
      },
      isLabelVisible,
      formId,
      isPreview,
    ),
  );
  addressContainer.appendChild(
    buildTextbox(
      {
        name: `${name}[zip]`,
        label: 'Postal / ZIP code',
        type: 'text',
        required,
      },
      isLabelVisible,
      formId,
      isPreview,
    ),
  );
  addressContainer.appendChild(
    buildDropdown(
      {
        name: `${name}[country]`,
        label: 'Country',
        type: 'text',
        required,
        choices: countries,
      },
      isLabelVisible,
      formId,
      isPreview,
      defaultCountry,
    ),
  );
  return addressContainer;
};

/**
 * Builds a date picker input in browsers that support input type="date"
 * Gracefully falls back to a text input in browsers that don't support it. (Currently just desktop Safari & IE).
 *
 * @param {object} formField
 * @param {string} formField.dateformat
 * @param {string} formField.name
 * @param {string} formField.label
 * @param {boolean} formField.required
 * @param {boolean} isLabelVisible
 * @param {string} formId
 * @param {boolean} isPreview - when viewed inside the editor, used to apply tab-index="-1"
 */
const buildDatePicker = (
  { name, dateformat, label, required },
  isLabelVisible,
  formId,
  isPreview,
) => {
  const inputGroup = document.createElement('div');
  inputGroup.setAttribute('class', 'mceInputGroup');

  const input = document.createElement('input');
  input.setAttribute('class', 'mceInput');
  input.setAttribute('name', name);
  input.setAttribute('id', getDomSafeId(formId, name));
  input.setAttribute('data-date-input', 'date');
  input.setAttribute('placeholder', dateformat);
  input.setAttribute('data-date-format', dateformat);
  input.setAttribute('type', 'date');
  // If the browser can't handle input type="date", set up an input pattern and pass through the dateformat
  if (input.type === 'text') {
    input.setAttribute(
      'pattern',
      `${required ? 'd{2}/d{2}/d{4}' : '(^$|d{2}/d{2}/d{4}$)'}`,
    );
  }

  if (required) {
    input.setAttribute('required', true);
  }
  if (isPreview) {
    input.setAttribute('tabindex', '-1');
  }

  const formatDescription = document.createElement('span');
  formatDescription.setAttribute('class', 'visually-hidden');
  formatDescription.textContent = `Enter date as ${dateformat}.`;

  if (isLabelVisible) {
    const fieldLabel = document.createElement('label');
    fieldLabel.textContent = label;
    if (required) {
      fieldLabel.textContent += ` ${REQUIRED_INDICATOR}`;
    }
    fieldLabel.appendChild(formatDescription);
    fieldLabel.setAttribute('class', 'mceLabel');
    fieldLabel.appendChild(input);
    inputGroup.appendChild(fieldLabel);
  } else {
    input.setAttribute('aria-label', label);
    inputGroup.appendChild(formatDescription);
    inputGroup.appendChild(input);
  }

  return inputGroup;
};

/**
 * Builds a birthday input field
 *
 * @param {object} formField
 * @param {string} formField.dateformat
 * @param {string} formField.name
 * @param {string} formField.label
 * @param {boolean} formField.required
 * @param {boolean} isLabelVisible
 * @param {string} formId
 * @param {boolean} isPreview - when viewed inside the editor, used to apply tab-index="-1"
 */
const buildBirthdayInputField = (
  { name, dateformat, label, required },
  isLabelVisible,
  formId,
  isPreview,
) => {
  const inputGroup = document.createElement('div');
  inputGroup.setAttribute('class', 'mceInputGroup');

  const input = document.createElement('input');
  input.setAttribute('class', 'mceInput');
  input.setAttribute('name', name);
  input.setAttribute('id', getDomSafeId(formId, name));
  input.setAttribute('data-date-input', 'birthday');
  input.setAttribute('data-date-format', dateformat);
  input.setAttribute('placeholder', dateformat);
  input.setAttribute('type', 'text');
  input.setAttribute(
    'pattern',
    `${required ? '^d{2}/d{2}$' : '(^$|d{2}/^d{2}$)'}`,
  );

  if (required) {
    input.setAttribute('required', true);
  }
  if (isPreview) {
    input.setAttribute('tabindex', '-1');
  }

  const formatDescription = document.createElement('span');
  formatDescription.setAttribute('class', 'visually-hidden');
  formatDescription.textContent = `Enter date as ${dateformat}.`;

  if (isLabelVisible) {
    const fieldLabel = document.createElement('label');
    fieldLabel.textContent = label;
    if (required) {
      fieldLabel.textContent += ` ${REQUIRED_INDICATOR}`;
    }
    fieldLabel.appendChild(formatDescription);
    fieldLabel.setAttribute('class', 'mceLabel');
    fieldLabel.appendChild(input);
    inputGroup.appendChild(fieldLabel);
  } else {
    input.setAttribute('aria-label', label);
    inputGroup.appendChild(formatDescription);
    inputGroup.appendChild(input);
  }

  return inputGroup;
};

/**
 * Creates an invisible honeytime field for a given form
 *
 * @param {String} honeytime - honeytime value from subscribe endpoint
 */
const buildHoneytimeField = (honeytime) => {
  if (honeytime) {
    const ht = document.createElement('input');
    ht.type = 'hidden';
    ht.name = 'ht';
    ht.value = honeytime;
    return ht;
  }
};

/**
 * Creates an invisible recaptcha div
 *
 * @param {String} recaptchaSitekey - recaptchaSitekey value from subscribe endpoint
 * @param {String} recaptchaId - id for the invisible recaptcha div
 */
const buildRecaptchaField = (recaptchaSitekey, recaptchaId) => {
  const captchaDiv = document.createElement('div');
  captchaDiv.id = recaptchaId;
  captchaDiv.setAttribute('data-size', 'invisible');
  captchaDiv.setAttribute('data-sitekey', recaptchaSitekey);
  return captchaDiv;
};

/**
 * Creates and inserts recaptcha script in head of document
 *
 * @param {String} recaptchaId - id for the invisible recaptcha div
 */
const loadRecaptchaJs = (recaptchaId) => {
  const captcha = document.createElement('script');
  captcha.type = 'text/javascript';
  captcha.src = `https://www.google.com/recaptcha/api.js?onload=onGrecaptchaLoadCallback${recaptchaId}&render=explicit`;
  captcha.defer = true;
  captcha.async = true;

  const head = document.getElementsByTagName('head')[0];
  head.appendChild(captcha);
};

/**
 * Given a form element and a set of contact values,
 * pre-populates the form fields with the contact values.
 *
 * @param {node} formElement
 * @param {object} fieldValues
 */
const prePopulateFormFields = (formElement, fieldValues) => {
  // First, we need to ensure that the fieldValues passed in are flat
  const flatFieldMap = {};
  Object.entries(fieldValues).forEach(([key, value]) => {
    if (key === 'group') {
      Object.entries(value).forEach(([groupKey, groupValue]) => {
        flatFieldMap[`group[${groupKey}]`] = Object.keys(groupValue);
      });
    } else if (typeof value === 'object') {
      Object.entries(value).forEach(([nestedKey, nestedValue]) => {
        flatFieldMap[`${key}[${nestedKey}]`] = nestedValue;
      });
    } else {
      flatFieldMap[key] = value;
    }
  });

  Object.keys(flatFieldMap).forEach((fieldName) => {
    const isGdprField = /^gdpr\[[\d]+\]$/.test(fieldName);
    const newValue = flatFieldMap[fieldName];
    let fieldId = getDomSafeId(formElement.id, fieldName);

    if (isGdprField) {
      fieldId = getDomSafeId(formElement.id, 'gdpr');
    }

    const field = formElement.querySelector(`#${fieldId}`);
    if (!field) {
      return;
    }

    // A couple of field types below require some special formatting:
    // For multichoice fields, find the specific input that matches the
    // given value and set it's checked attribute to true.
    if (field.tagName === 'LEGEND') {
      if (Array.isArray(newValue)) {
        newValue.forEach((selectedValue) => {
          const multiChoiceField = formElement.querySelector(
            `#${getDomSafeId(formElement.id, fieldName, selectedValue)}`,
          );

          multiChoiceField.checked = true;
        });
      } else if (isGdprField) {
        const multiChoiceField = formElement.querySelector(
          `#${getDomSafeId(formElement.id, 'gdpr', fieldName)}`,
        );
        multiChoiceField.checked = true;
      } else {
        const multiChoiceField = formElement.querySelector(
          `#${getDomSafeId(formElement.id, fieldName, newValue)}`,
        );
        multiChoiceField.checked = true;
      }
    } else if (
      // For date fields in browsers that don't have an HTML5 datepicker,
      // format the date string correctly.
      field.dataset.dateInput === 'date' &&
      field.type !== 'date'
    ) {
      const dateFormat = field.dataset.dateFormat;
      const dateValues = newValue.split('-');

      if (dateFormat === 'MM/DD/YYYY') {
        field.value = `${dateValues[1]}/${dateValues[2]}/${dateValues[0]}`;
      } else {
        // dateFormat === 'DD/MM/YYYY'
        field.value = `${dateValues[2]}/${dateValues[1]}/${dateValues[0]}`;
      }
    } else {
      field.value = newValue;
    }
  });
};

export {
  buildMultipleChoice,
  buildCheckbox,
  buildDropdown,
  buildTextbox,
  buildAddressField,
  buildBirthdayInputField,
  buildDatePicker,
  buildHoneytimeField,
  buildRecaptchaField,
  loadRecaptchaJs,
  prePopulateFormFields,
};
