import env from './env';
import { getWithCache } from './request';

const getAppointmentDetails = async (appointmentDetailsUrl) => {
  const requestUrl = env.isForPublicConsumption
    ? appointmentDetailsUrl
    : '/bookings/appointments/editor-preview-confirmation-details';
  const json = await getWithCache(requestUrl);
  return json;
};

const cancelAppointment = async (cancelAppointmentUrl) => {
  const requestUrl = env.isForPublicConsumption
    ? cancelAppointmentUrl
    : '/bookings/appointments/editor-preview-cancel';
  const response = await fetch(requestUrl, { method: 'POST' });
  const json = await response.json();
  return json;
};

const renderCanceledAppointment = (root, contentContainer, data) => {
  const heading = document.createElement('h1');
  heading.innerText = `Your appointment is canceled.`;
  contentContainer.appendChild(heading);

  const serviceName = document.createElement('strong');
  serviceName.innerText = data.appointment.service.name;

  const detailsText = document.createElement('p');
  detailsText.innerHTML = `Your <strong>${serviceName.innerHTML}</strong> appointment, scheduled for <br /><strong>${data.appointment.appointmentDate} at ${data.appointment.appointmentStartTime}  - ${data.appointment.appointmentEndTime} (${data.appointment.timezoneLabel})</strong> has been canceled.`;
  contentContainer.appendChild(detailsText);

  const confirmationText = document.createElement('p');
  const email = data.appointment.contactInfo.customerEmailAddress;
  confirmationText.innerHTML = `We sent a confirmation email${
    email ? ` to<br/><strong>${email}.</strong>` : '.'
  }`;
  contentContainer.appendChild(confirmationText);

  const buttonWrapper = document.createElement('div');
  buttonWrapper.classList.add('mceButton');
  const anotherAppointmentButton = document.createElement('a');
  anotherAppointmentButton.innerText = 'Make a new appointment';
  anotherAppointmentButton.href = env.isForPublicConsumption
    ? '/book-online'
    : '';
  buttonWrapper.appendChild(anotherAppointmentButton);
  contentContainer.appendChild(buttonWrapper);
};

const renderConfirmedAppointment = (
  root,
  contentContainer,
  data,
  cancellationAborted = false,
) => {
  const heading = document.createElement('h1');
  heading.innerText = cancellationAborted
    ? 'Great! We will keep your appointment'
    : `Great! You're scheduled for a ${data.appointment.service.name}`;
  contentContainer.appendChild(heading);

  const detailsText = document.createElement('p');
  const firstName = data.appointment.contactInfo.customerFirstName;
  detailsText.innerHTML = `Thanks${
    firstName ? `, ${firstName}` : ''
  }! We confirmed your appointment for <br /><strong>${
    data.appointment.appointmentDate
  } at ${data.appointment.appointmentStartTime} - ${
    data.appointment.appointmentEndTime
  } (${data.appointment.timezoneLabel})</strong>`;
  contentContainer.appendChild(detailsText);

  if (!cancellationAborted) {
    const confirmationText = document.createElement('p');
    const email = data.appointment.contactInfo.customerEmailAddress;
    confirmationText.innerHTML = `We sent a confirmation email with your appointment details${
      email ? ` to<br/><strong>${email}.</strong>` : '.'
    }`;
    contentContainer.appendChild(confirmationText);
  }

  const googleButtonWrapper = document.createElement('div');
  googleButtonWrapper.classList.add('mceButton');
  googleButtonWrapper.setAttribute(
    'style',
    'display: inline-block; padding: var(--spacing);',
  );
  const addToGoogleButton = document.createElement('a');
  addToGoogleButton.innerText = 'Add To Google Calendar';
  addToGoogleButton.href = env.isForPublicConsumption
    ? data.appointment.addToCalendar.google
    : '';
  googleButtonWrapper.appendChild(addToGoogleButton);

  const icsButtonWrapper = document.createElement('div');
  icsButtonWrapper.classList.add('mceButton');
  icsButtonWrapper.setAttribute(
    'style',
    'display: inline-block; padding: var(--spacing);',
  );
  const addToApple = document.createElement('a');
  addToApple.innerText = 'Add To Apple Calendar';
  addToApple.href = env.isForPublicConsumption
    ? data.appointment.addToCalendar.ics
    : '';
  addToApple.download = data.appointment.service.name + '.ics';
  icsButtonWrapper.appendChild(addToApple);

  const addToCalendarWrapper = document.createElement('div');
  addToCalendarWrapper.appendChild(googleButtonWrapper);
  addToCalendarWrapper.appendChild(icsButtonWrapper);
  contentContainer.appendChild(addToCalendarWrapper);

  const anotherAppointmentButtonWrapper = document.createElement('div');
  anotherAppointmentButtonWrapper.classList.add('mceButton');
  const anotherAppointmentButton = document.createElement('a');
  anotherAppointmentButton.innerText = 'Book Another Appointment';
  anotherAppointmentButton.href = env.isForPublicConsumption
    ? '/book-online'
    : '';
  anotherAppointmentButtonWrapper.appendChild(anotherAppointmentButton);
  contentContainer.appendChild(anotherAppointmentButtonWrapper);

  const cancelLink = document.createElement('a');
  cancelLink.href = '#';
  cancelLink.innerText = 'Cancel my appointment';
  if (env.isForPublicConsumption) {
    cancelLink.addEventListener('click', (e) => {
      e.preventDefault();
      e.stopPropagation();
      renderAppointmentDetails(root, data, 'cancel');
    });
  }
  contentContainer.appendChild(cancelLink);
};

const onConfirmCancelAppointment = async (
  buttonContainer,
  root,
  cancelAppointmentUrl,
) => {
  buttonContainer.querySelectorAll('.mceErrorMessage').forEach((error) => {
    error.remove();
  });

  const appointmentId = new URLSearchParams(window.location.search).get(
    'appointmentId',
  );
  let cancellationResponse = {};
  try {
    cancellationResponse = await cancelAppointment(
      `${cancelAppointmentUrl}&appointmentId=${appointmentId}`,
    );
  } finally {
    if (cancellationResponse.appointment) {
      renderAppointmentDetails(root, cancellationResponse);
    } else {
      const error = document.createElement('p');
      error.classList.add('mceErrorMessage', 'mceErrorMessage--centered');
      error.innerText =
        cancellationResponse.error ?? 'Something went wrong. Please try again.';
      buttonContainer.appendChild(error);
    }
  }
};

const renderConfirmCancelAppointment = (root, contentContainer, data) => {
  const heading = document.createElement('h1');
  heading.innerText = 'Are you sure you want to cancel this appointment?';
  contentContainer.appendChild(heading);

  const detailsText = document.createElement('p');
  const firstName = data.appointment.contactInfo.FNAME;

  const serviceName = document.createElement('strong');
  serviceName.innerText = data.appointment.service.name;

  detailsText.innerHTML = `Hi${
    firstName ? `, ${firstName}` : ''
  }! Please confirm that you would like to cancel your <strong>${
    serviceName.innerHTML
  }</strong> appointment scheduled for <br /><strong>${
    data.appointment.appointmentDate
  } at ${data.appointment.appointmentStartTime} - ${
    data.appointment.appointmentEndTime
  } (${data.appointment.timezoneLabel})</strong>`;
  contentContainer.appendChild(detailsText);

  const buttonWrapper = document.createElement('div');
  buttonWrapper.classList.add('mceButton', 'mceForm');
  const cancelAppointmentButton = document.createElement('a');
  cancelAppointmentButton.innerText = 'Cancel Appointment';

  if (env.isForPublicConsumption) {
    cancelAppointmentButton.addEventListener('click', (e) => {
      e.preventDefault();
      e.stopPropagation();
      onConfirmCancelAppointment(
        e.target.parentNode,
        root,
        data.cancelAppointmentUrl,
      );
    });
  }
  buttonWrapper.appendChild(cancelAppointmentButton);
  contentContainer.appendChild(buttonWrapper);

  const keepAppointmentLink = document.createElement('a');
  keepAppointmentLink.href = '#';
  keepAppointmentLink.innerText = 'Keep my appointment';

  if (env.isForPublicConsumption) {
    keepAppointmentLink.addEventListener('click', (e) => {
      e.preventDefault();
      e.stopPropagation();
      renderAppointmentDetails(root, data, 'cancellation-aborted');
    });
  }
  contentContainer.appendChild(keepAppointmentLink);
};

const renderNotFoundAppointment = (root, contentContainer) => {
  const heading = document.createElement('h1');
  heading.innerText = `The details for this appointment could not be found.`;
  contentContainer.appendChild(heading);

  const buttonWrapper = document.createElement('div');
  buttonWrapper.classList.add('mceButton');
  const anotherAppointmentButton = document.createElement('a');
  anotherAppointmentButton.innerText = 'Book Another Appointment';
  anotherAppointmentButton.href = env.isForPublicConsumption
    ? '/book-online'
    : '';
  buttonWrapper.appendChild(anotherAppointmentButton);
  contentContainer.appendChild(buttonWrapper);
};

function renderAppointmentDetails(root, data, intent) {
  const wrapper = document.createElement('div');
  wrapper.classList.add('mceColumn', 'mceText');
  wrapper.setAttribute('style', '--mceColumn-textAlign:center');
  if (!data.appointment) {
    renderNotFoundAppointment(root, wrapper);
  } else if (data.appointment.isCanceled) {
    renderCanceledAppointment(root, wrapper, data);
  } else if (intent === 'cancel') {
    renderConfirmCancelAppointment(root, wrapper, data);
  } else {
    renderConfirmedAppointment(
      root,
      wrapper,
      data,
      intent === 'cancellation-aborted',
    );
  }

  root.innerHTML = '';
  root.appendChild(wrapper);
}

export async function setUpAppointmentConfirmation() {
  const appointmentSection = document.querySelector(
    '[data-appointment-confirmation]',
  );
  if (!appointmentSection) {
    return;
  }

  const appointmentContent = appointmentSection.querySelector(
    "[data-ref='content']",
  );
  if (!appointmentContent) {
    return;
  }

  appointmentSection.classList.add('mceRow--maxWidth');

  let appointmentDetails = {};

  const queryParams = new URLSearchParams(window.location.search);
  if (env.isForPublicConsumption && !queryParams.has('appointmentId')) {
    renderAppointmentDetails(appointmentContent, appointmentDetails);
    return;
  }

  try {
    appointmentDetails = await getAppointmentDetails(
      `${
        appointmentSection.dataset.appointmentDetailsUrl
      }&appointmentId=${queryParams.get('appointmentId')}`,
    );
    appointmentDetails.cancelAppointmentUrl =
      appointmentSection.dataset.cancelAppointmentUrl;
  } finally {
    renderAppointmentDetails(
      appointmentContent,
      appointmentDetails,
      queryParams.get('intent'),
    );
  }
}
