import env from '../env';
import { renderStepOne, renderStepTwo } from './fn';
import { getDayKey } from './dateAndTime';
import { getWithCache } from '../request';

const getAvailableServices = async (servicesListUrl) => {
  const json = await getWithCache(servicesListUrl);
  return json;
};

let allTimeslotData = {};
let timeslotServiceId = 0;
const getTimeslotData = async (
  timeslotUrl,
  startTime,
  endTime,
  serviceId,
  duration,
  maxAttendees,
  isInitialRequest,
) => {
  const startTimeString = `${startTime.getFullYear()}-${String(
    startTime.getMonth() + 1,
  )}-${startTime.getDate()}`;
  const endTimeString = `${endTime.getFullYear()}-${String(
    endTime.getMonth() + 1,
  )}-${endTime.getDate()}`;
  const urlWithParams = `${timeslotUrl}&startTime=${startTimeString}&endTime=${endTimeString}&serviceId=${serviceId}&isInitialRequest=${isInitialRequest}`;
  const json = await getWithCache(urlWithParams);
  if (serviceId !== timeslotServiceId) {
    allTimeslotData = {};
    timeslotServiceId = serviceId;
  }
  if (json.timeslots) {
    Object.keys(json.timeslots).forEach(
      (day) => (allTimeslotData[day] = json.timeslots[day]),
    );
  }
  return {
    ...json,
    duration,
    maxAttendees,
    timeslots: { ...allTimeslotData },
  };
};

const reduce = (state, action, data) => {
  if (action === 'SELECT_SERVICE') {
    return {
      ...state,
      currentStep: data.serviceId ? 2 : 1,
      selectedServiceId: data.serviceId,
      selectedDate: null,
      selectedTime: null,
      stepOneTime: Date.now(),
    };
  }

  if (action === 'SET_CONTACT_DETAILS') {
    return {
      ...state,
      currentStep: 4,
      contactDetails: data.contactDetails,
      stepThreeTime: Date.now(),
    };
  }

  if (action === 'SET_SELECTED_DATE') {
    return {
      ...state,
      currentStep: 3,
      selectedDate: data.selectedDate,
      selectedTime: data.selectedTime,
      stepTwoTime: Date.now(),
    };
  }

  if (action === 'SET_ACTIVE_STEP') {
    return {
      ...state,
      currentStep: data.step,
      selectedServiceId: data.step > 1 ? state.selectedServiceId : undefined,
      error: null,
    };
  }
  if (action === 'SET_ERROR') {
    return {
      ...state,
      error: data.error,
    };
  }

  return { ...state };
};

const scrollToStep = (root, step) => {
  const stepWrapper = root.querySelector(
    `[data-ref='services-step-${
      new Array('one', 'two', 'three', 'four')[step - 1]
    }-container']`,
  );
  stepWrapper.scrollIntoView({ behavior: 'smooth' });
};
const renderSteps = (root, state) => {
  if (!root.nextSibling) {
    // if the section is being rendered in the layout section, don't render steps
    return;
  }

  const stepOneContainer = root.querySelector(
    "[data-ref='services-step-one-container']",
  );
  stepOneContainer.classList.add('mceServices', 'mceText');
  stepOneContainer.innerHTML = '';
  stepOneContainer.append(
    renderStepOne(
      !env.isForPublicConsumption || state.currentStep === 1,
      state,
      (serviceId) => {
        let newState = reduce(state, 'SET_ACTIVE_STEP', { step: 1 });
        if (serviceId) {
          newState = reduce(state, 'SELECT_SERVICE', { serviceId });
        }
        renderSteps(root, newState);
        scrollToStep(root, newState.currentStep);
      },
    ),
  );

  const stepTwoContainer = root.querySelector(
    "[data-ref='services-step-two-container']",
  );
  stepTwoContainer.classList.add('mceServices', 'mceText');
  stepTwoContainer.innerHTML = '';
  stepTwoContainer.append(
    renderStepTwo(
      !env.isForPublicConsumption || state.currentStep === 2,
      state,
      (selectedDate, selectedTime) => {
        const params = `serviceID=${
          state.selectedServiceId
        }&startDate=${getDayKey(selectedDate)}&startTime=${
          selectedTime.startTime
        }&endTime=${selectedTime.endTime}`;
        // preserve state of steps 1 and 2 in url hash, so that user can use back button from checkout
        history.replaceState({}, '', `?${params}`);
        window.location.href = `/book-online/checkout?${params}`;
      },
      async (startDate, endDate, isInitialRequest) => {
        const selectedService = state.services.find(
          (x) => state.selectedServiceId === x.serviceId,
        );
        if (!selectedService) {
          return null;
        }
        const data = await getTimeslotData(
          root.dataset.timeslotUrl,
          startDate,
          endDate,
          selectedService.serviceId,
          selectedService.duration,
          parseInt(selectedService.maxAttendees, 10),
          isInitialRequest,
        );
        return data;
      },
    ),
  );

  if (!env.isForPublicConsumption) {
    root
      .querySelectorAll('button')
      .forEach((node) => node.setAttribute('disabled', 'disabled'));
  }
};

export async function setUpServicesSection() {
  const servicesSections = document.querySelectorAll('[data-services-section]');
  if (servicesSections.length === 0) {
    return;
  }

  const isRunningPublically =
    env.isForPublicConsumption || env.isForPlatformPreview;

  // Get list of available services
  const services = await getAvailableServices(
    isRunningPublically
      ? servicesSections[0].dataset.servicesListUrl
      : `/bookings/services/list`,
  );

  const initialState = {
    currentStep: 1,
    services: services.services,
    businessName: services.businessName,
    startOfWeek: [
      'sunday',
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
    ].indexOf(services.startOfWeek),
  };

  const queryParams = new URLSearchParams(window.location.search);
  let initialSelectedService = null;

  // If query params contain a service ID, search for it in services
  if (queryParams.get('serviceID')) {
    initialSelectedService =
      initialState.services &&
      initialState.services.find(
        (service) => service.serviceId === queryParams.get('serviceID'),
      );
  }

  // If path contains /services/%stub%, search for stub name in services
  const path = queryParams.get('p') || window.location.pathname;
  if (!initialSelectedService && new RegExp(/\/book-online\/.+$/g).test(path)) {
    const pathParts = path.split('/');
    const stub = pathParts[pathParts.length - 1];
    initialSelectedService =
      initialState.services &&
      initialState.services.find((service) => service.urlToken === stub);
  }

  if (initialSelectedService) {
    initialState.currentStep = 2;
    initialState.selectedServiceId = initialSelectedService.serviceId;
    initialState.initialSelectedTimeslot =
      queryParams.get('startTime') &&
      queryParams.get('endTime') &&
      queryParams.get('startDate')
        ? {
            startTime: Number(queryParams.get('startTime')),
            endTime: Number(queryParams.get('endTime')),
            startDate: queryParams.get('startDate'),
          }
        : null;
  }

  servicesSections.forEach((serviceSection) =>
    renderSteps(serviceSection, initialState),
  );
}
