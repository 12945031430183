import env from '../env';

function nextSiblingWithSelector(element, selector) {
  if (!element) {
    return null;
  }

  // Get the next sibling element
  let sibling = element.nextElementSibling;

  // If the sibling matches our selector, use it
  // If not, jump to the next sibling and continue the loop
  while (sibling) {
    if (sibling.matches(selector)) {
      return sibling;
    }
    sibling = sibling.nextElementSibling;
  }

  return null;
}

const colorSchemeCssVars = [
  '--mceRow-background',
  '--global-backgroundColor',
  '--global-paragraphTextColor',
  '--global-headingTextColor',
  '--global-buttonColor',
  '--global-buttonBackgroundColor',
  '--global-buttonHoveredBackgroundColor',
  '--global-inputColor',
  '--global-linkTextColor',
  '--global-buttonBorderColor',
  '--global-secondaryButtonBackgroundColor',
  '--global-secondaryButtonBorderColor',
  '--global-secondaryButtonColor',
  '--global-buttonTransitionProperty',
  '--global-columnBorderColor',
];

class HeaderMutationHandler {
  constructor() {
    this.headerElement = null;
    this.firstSection = null;
    this.cleanupFunctions = [];
  }

  /**
   *
   * @param {HTMLElement} headerElement
   * @returns {Boolean}
   */
  headerHasColorSchemeVars(headerElement) {
    return colorSchemeCssVars.every(function(cssVar) {
      if (headerElement) {
        return !!headerElement.style.getPropertyValue(cssVar);
      }
      return false;
    });
  }

  queryElements() {
    this.headerElement = document.querySelector('#root').firstElementChild;
    this.firstSection = nextSiblingWithSelector(this.headerElement, '.mceRow');
  }

  containsNeededElements() {
    return this.headerElement && this.firstSection;
  }

  buildState() {
    this.isFixed = 'jsIsFixedNav' in this.headerElement.dataset;
    this.isTransparent = 'jsIsTransparentNav' in this.headerElement.dataset;

    this.isRunningInEditorContext =
      !env.isForPublicConsumption && !env.isForPlatformPreview;

    this.hasColorSchemeData = !!this.headerElement.dataset.originalColorScheme;
    this.hasColorSchemeStyleVars = this.headerHasColorSchemeVars(
      this.headerElement,
    );

    const previousState = JSON.parse(
      this.headerElement.dataset.previousState || '{}',
    );

    const nextSectionId = this.firstSection.dataset.blockId;
    const layout = this.headerElement.dataset.layout;

    // Cases which result in a full reset of DOM mutations
    this.havePropertiesChanged =
      previousState?.isFixed !== this.isFixed ||
      previousState?.isTransparent !== this.isTransparent ||
      previousState?.nextSectionId !== nextSectionId ||
      previousState?.layout !== layout;

    if (this.havePropertiesChanged && this.isRunningInEditorContext) {
      this.headerElement.dataset.previousState = JSON.stringify({
        isFixed: this.isFixed,
        isTransparent: this.isTransparent,
        nextSectionId,
        layout,
      });
    }

    // we only need to check this if transparent nav is enabled
    if (!this.havePropertiesChanged && this.isTransparent) {
      this.onlyRequiresColorSchemeUpdate = this.isColorSchemeOutdated();
    }
  }

  isColorSchemeOutdated() {
    const headerColorScheme = colorSchemeCssVars.reduce(
      (acc, property) => ({
        ...acc,
        [property]: this.headerElement.style.getPropertyValue(property),
      }),
      {},
    );
    const firstSectionColorScheme = colorSchemeCssVars.reduce(
      (acc, property) => ({
        ...acc,
        [property]: this.firstSection.style.getPropertyValue(property),
      }),
      {},
    );
    return (
      JSON.stringify(headerColorScheme) !==
      JSON.stringify(firstSectionColorScheme)
    );
  }

  resetColorSchemeValues() {
    // We do a check here to see if there are color scheme css variables on the
    //  header element to know whether or not the global palette has been changed.
    // If we do not find any color scheme css variables on the header, we can deduce
    //  that the website palette has been changed and we do not want to re-apply the header's
    //  original color scheme data since it will not be compatible with the new
    //  website palette.
    if (this.hasColorSchemeData && this.hasColorSchemeStyleVars) {
      const originalColorScheme = JSON.parse(
        this.headerElement.dataset.originalColorScheme,
      );

      Object.keys(originalColorScheme).forEach((cssVar) => {
        this.headerElement.style.setProperty(
          cssVar,
          originalColorScheme[cssVar],
        );
      });
    }
    delete this.headerElement.dataset.originalColorScheme;
  }
  applyPaddingMutation() {
    const headerHeight = this.headerElement.offsetHeight;

    //Set the value of the first section's padding dymanically with CSS Var
    document.documentElement.style.setProperty(
      '--paddingAdjustFirstSection',
      `${headerHeight}px`,
    );
  }

  removePaddingMutation() {
    //Reset the first section's padding
    document.documentElement.style.setProperty(
      '--paddingAdjustFirstSection',
      '0px',
    );
  }

  applyPositionMutation() {
    // Applying fixed within the editor context causes layout previews to
    // have a height of 0px in the Section Manager.
    if (this.isFixed && !this.isRunningInEditorContext) {
      document.documentElement.style.setProperty(
        '--headingAdjustPosition',
        'fixed',
      );
      document.documentElement.style.setProperty('--headingAdjustTop', '0px');
    } else if (
      this.isTransparent ||
      // In the editor, we don't want the Header to be fixed. However, we still
      // apply the padding mutation so it's important to remove the element
      // from the document flow.
      (this.isFixed && this.isRunningInEditorContext)
    ) {
      document.documentElement.style.setProperty(
        '--headingAdjustPosition',
        'absolute',
      );
      document.documentElement.style.setProperty('--headingAdjustTop', '0px');
    }
    // We have elements on the page that use z-index, so lets set this to an
    // arbitrary high value
    this.headerElement.style.setProperty('z-index', '100');
  }

  applyTransparentBackgroundMutation() {
    if (this.isTransparent) {
      this.headerElement.classList.add('runtimeTransparentBackground');
    }
  }

  applyFixedBackgroundMutation() {
    const currentBackground = this.headerElement.style.getPropertyValue(
      '--mceRow-background',
    );

    const hasBackground =
      currentBackground?.trim() !== '' && currentBackground?.trim() !== 'none';

    if (!hasBackground) {
      // copy the global background to the header element
      const globalBackground = getComputedStyle(
        document.documentElement,
      ).getPropertyValue('--global-backgroundColor');

      this.headerElement.style.setProperty(
        '--mceRow-background',
        globalBackground,
      );

      // mark that the --mceRow-background value should be unset upon
      // re-render
      if (!this.isTransparent) {
        this.headerElement.dataset.fixedNavMutation = true;
      }
    }
  }

  removeBackgroundMutations() {
    this.headerElement.classList.remove('runtimeTransparentBackground');

    if (this.headerElement.dataset.fixedNavMutation) {
      this.headerElement.style.removeProperty('--mceRow-background');
      delete this.headerElement.dataset.fixedNavMutation;
    }
  }

  removePositionMutation() {
    // Undo transformations if transparentNav is toggled off
    document.documentElement.style.setProperty(
      '--headingAdjustPosition',
      'initial',
    );
    this.headerElement.style.removeProperty('z-index');
  }

  resetMutations() {
    if (!this.isTransparent) {
      this.resetColorSchemeValues();
    }
    this.removePaddingMutation();
    this.removePositionMutation();
    this.removeBackgroundMutations();
    this.headerElement.style.removeProperty('transition');
  }

  startupPaddingResizeObserver() {
    // Since we may need to adjust the padding if a user resizes the window,
    //  we will add a ResizeObserver and re-apply the logic
    const resizeObserver = new ResizeObserver(() => {
      this.applyPaddingMutation();
    });

    resizeObserver.observe(this.headerElement);

    this.cleanupFunctions.push(() => resizeObserver.disconnect());
  }

  startupScrollListener() {
    this.headerElement.style.setProperty(
      'transition',
      'background 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
    );

    const scrollFunction = () => {
      if (window.scrollY >= 40) {
        if (this.isTransparent) {
          this.headerElement.classList.remove('runtimeTransparentBackground');
        }

        this.headerElement.style.boxShadow =
          '0px 4px 12px rgba(36, 28, 21, 0.12)';
      } else {
        if (this.isTransparent) {
          this.headerElement.classList.add('runtimeTransparentBackground');
        }
        this.headerElement.style.removeProperty('box-shadow');
      }
    };

    window.addEventListener('scroll', scrollFunction);

    this.cleanupFunctions.push(() => {
      window.removeEventListener('scroll', scrollFunction);
    });
  }

  syncColorScheme() {
    const colorSchemeValues = {};

    colorSchemeCssVars.forEach((cssVar) => {
      const value = getComputedStyle(this.firstSection).getPropertyValue(
        cssVar,
      );

      if (!this.hasColorSchemeData) {
        colorSchemeValues[cssVar] = getComputedStyle(
          this.headerElement,
        ).getPropertyValue(cssVar);
      }

      this.headerElement.style.setProperty(cssVar, value);
    });

    // Make a data attribute that holds a json string of all the original color scheme
    //  values so that we can re-apply them if fixed nav is turned off (when nav is transparent)
    // We are only wanting to set this once, so first time this function is run, we need to set this
    //  value, otherwise, we can skip this step.
    if (!this.hasColorSchemeData) {
      this.headerElement.dataset.originalColorScheme = JSON.stringify(
        colorSchemeValues,
      );
    }
  }

  applyMutations() {
    requestAnimationFrame(() => {
      // Clean the DOM mutation state before reapplying
      this.resetMutations();

      // Apply correct mutations for the current settings
      if (this.isTransparent && this.isFixed) {
        this.syncColorScheme();
        this.applyPaddingMutation();
        this.applyPositionMutation();
        this.applyTransparentBackgroundMutation();
        this.applyFixedBackgroundMutation();
        this.startupPaddingResizeObserver();
        this.startupScrollListener();
      } else if (this.isTransparent) {
        this.syncColorScheme();
        this.applyPaddingMutation();
        this.applyPositionMutation();
        this.applyTransparentBackgroundMutation();
        this.startupPaddingResizeObserver();
      } else if (this.isFixed) {
        this.applyPaddingMutation();
        this.applyFixedBackgroundMutation();
        this.applyPositionMutation();
        this.startupScrollListener();
      } else {
        // nothing to do
      }
    });
  }

  cleanup() {
    this.cleanupFunctions.forEach((cleanupFunction) => cleanupFunction());
  }

  startup() {
    this.queryElements();

    // bail if expected elements are not in the document
    if (!this.containsNeededElements()) {
      return;
    }

    this.buildState();

    const adjustedPadding = document.documentElement.style.getPropertyValue(
      '--paddingAdjustFirstSection',
    );
    if (adjustedPadding !== '0px') {
      this.applyPaddingMutation();
    }

    // If we only require a color scheme update, we can avoid resetting all
    // of the other mutations that have been made.
    if (this.onlyRequiresColorSchemeUpdate) {
      this.syncColorScheme();
      return;
    }

    // When changing global color palettes, the '--mceRow-background' css variable
    //  gets removed from the header styles.  We are needing to ensure that we are
    //  applying a background to the header so that it does not appear transparent
    //  if our first content section has an image background
    if (!this.havePropertiesChanged && this.isFixed) {
      this.applyFixedBackgroundMutation();
      return;
    }

    // If the values of isFixed or isTransparent have not changed we don't
    // need to reset or execute any mutations.
    if (!this.havePropertiesChanged) {
      return;
    }

    // To handle race conditions where the preview or document may have not loaded yet
    //  we will add an event listener to apply the mutation at that time.  This prevents
    //  improperly setting a padding of 0 if this were to execute before the header has
    //  obtained its computed height value.
    if (document.readyState !== 'complete') {
      window.addEventListener('load', this.applyMutations.bind(this));
      this.cleanupFunctions.push(function() {
        window.removeEventListener('load', this.applyMutations.bind(this));
      });
    } else {
      this.applyMutations();
    }

    return this.cleanup.bind(this);
  }
}

export const accommodateFixedRow = () => {
  const runtime = new HeaderMutationHandler();
  return runtime.startup();
};
