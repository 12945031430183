/**
 * Wrapper around navigator.sendBeacon with a fallback for old
 * IE browsers that do not support it
 */
function sendBeacon(url, data) {
  if ('sendBeacon' in navigator) {
    return navigator.sendBeacon(url, data);
  }

  const xhr = new XMLHttpRequest();
  xhr.open('POST', url, false);
  xhr.setRequestHeader('Accept', '*/*');

  if (typeof data === 'string') {
    xhr.setRequestHeader('Content-Type', 'text/plain;charset=UTF-8');
    xhr.responseType = 'text';
  } else if (data instanceof Blob && data.type) {
    xhr.setRequestHeader('Content-Type', data.type);
  }

  try {
    xhr.send(data);
  } catch (error) {
    return false;
  }

  return true;
}

export function trackClicks() {
  const endpoint = document.body.dataset.clickTrackingEndpoint;

  // If the tracking endpoint does not exist on the body's dataset,
  // then tracking has been disabled for this site.
  if (endpoint) {
    const handler = (e) => {
      // Get closest anchor, as sometimes this event target will be
      // an element inside an anchor, like an img.
      const anchor = e.target.closest('a');

      if (anchor === null) {
        return;
      }

      const url = endpoint + '&url=' + encodeURIComponent(anchor.href);
      sendBeacon(url);
    };

    document.addEventListener('click', handler);

    return () => {
      document.removeEventListener('click', handler);
    };
  }
}
