import {
  buildFormFields,
  parseFormFields,
} from '@mc/editing/blocks/runtimes/audienceSettingsFields';
import { prePopulateFormFields } from '@mc/editing/blocks/runtimes/fn/forms';
import destructiveReadSlice from './fn/destructiveReadSlice';
import { getWithCache } from './request';

import env from './env';

const buildUnsubscribeAction = ({ emailId, campaignId }) => {
  const unsubscribeUrlBase = document.querySelectorAll(
    '[data-ref="unsubscribe-from-all-block"]',
  )[0];

  if (unsubscribeUrlBase) {
    const blockData = destructiveReadSlice(unsubscribeUrlBase, 'properties');
    const unsubscribeUrl = blockData.attr['data-unsubscribe-url'];
    if (env.isForPublicConsumption) {
      // The unsubscribe url ALWAYS comes with a `u` and an `id` query param from the backend.
      // you can see it here: `app/lib/MC/ListForm.php`, in the function `getUnsubscribeFormUrl"
      const fullUrl = `${unsubscribeUrl}&e=${emailId}&c=${campaignId}`;

      return (event) => {
        event.preventDefault();
        window.location.href = fullUrl;
      };
    }
  }
  return () => {};
};

const getUpdatePreferencesUrl = () => {
  const updatePreferencesUrlReference = document.querySelectorAll(
    '[data-update-preferences-url]',
  )[0];
  if (updatePreferencesUrlReference) {
    const updatePreferencesUrl =
      updatePreferencesUrlReference.dataset.updatePreferencesUrl;
    return updatePreferencesUrl;
  }
  return false;
};

export const insertUnsubscribeElement = () => {
  const unsubscribeBlock = document.querySelector(
    '[data-ref="unsubscribe-from-all-block"]',
  );
  // Check to make sure the block actually exists, to keep general websites safe
  if (unsubscribeBlock) {
    const unsubscribeElement = document.createElement('p');
    const unsubscribeTextStart = document.createTextNode(
      "If you'd like to unsubscribe entirely, click ",
    );

    const unsubscribeTextMiddle = document.createTextNode(
      'Unsubscribe From All Emails',
    );
    const unsubscribeTextBoldText = document.createElement('strong');
    unsubscribeTextBoldText.appendChild(unsubscribeTextMiddle);

    const unsubscribeTextEnd = document.createTextNode(
      ' at the bottom of this page.',
    );

    unsubscribeElement.appendChild(unsubscribeTextStart);
    unsubscribeElement.appendChild(unsubscribeTextBoldText);
    unsubscribeElement.appendChild(unsubscribeTextEnd);

    unsubscribeElement.setAttribute('class', 'mceText');
    unsubscribeElement.setAttribute('style', 'grid-column: 1/-1;');

    unsubscribeBlock.appendChild(unsubscribeElement);

    // When the runtime resets, clear out content to prevent duplicates
    return () => (unsubscribeBlock.textContent = '');
  }
};

export const buildFieldConfig = (formFields) => {
  const configContainer = {};
  // TODO: We'll need to update this in order to allow
  // marketers to control hidden field display. Some sort
  // of data on the preferences center or query params.
  formFields.forEach((field) => {
    // Public property is undefined for groups
    if (field.public === true || field.public === undefined) {
      configContainer[field.name] = {
        isRequired: field.name === 'EMAIL' ? true : field.req,
      };
    }
  });

  return configContainer;
};

/*
  This function maintains parity with the group input naming convention used with
  the old profile update form in Dojo (web/js/mojo/signup-forms/SignupForm.js#L377).
  Updating a contact's groups without it doesn't allow PHP to parse properly.
*/
export const updateCheckboxGroupInputNames = () => {
  const groupInputs = document.querySelectorAll("input[name*='group']");
  groupInputs.forEach((group) => {
    if (group.type === 'checkbox') {
      group.setAttribute('name', `${group.name}[${group.value}]`);
    }
  });
};

export const getProfileFormData = () => {
  const subscribeBlock = document.querySelector('[data-subscribe-form]');

  if (subscribeBlock) {
    const settingsUrl = subscribeBlock.dataset.preferencesCenterFormSettingsUrl;

    if (
      settingsUrl &&
      settingsUrl !== '*|PREFERENCES_CENTER:SIGNUP_FORM_SETTINGS_URL|*'
    ) {
      getWithCache(settingsUrl).then((data) => {
        const fieldsContainer = subscribeBlock.querySelector(
          '[data-ref="form-fields-container"]',
        );

        if (fieldsContainer) {
          const fieldConfig = destructiveReadSlice(
            subscribeBlock,
            'fieldConfig',
          );

          const signUpFormConfig = buildFieldConfig(data.fields);
          const syncedFieldConfig = { ...fieldConfig };

          // Ensures preferences center form fields configuration maintains
          // parity with visible sign up form fields configuration
          Object.keys(signUpFormConfig).forEach((key) => {
            syncedFieldConfig[key] = signUpFormConfig[key];
          });

          const parsedFormFields = parseFormFields(
            data.fields,
            syncedFieldConfig,
          );
          const formBlock = buildFormFields(
            parsedFormFields,
            true,
            'PREFERENCES_CENTER_FORM',
          );

          formBlock.id = 'PREFERENCES_CENTER_FORM';

          const urlParams = new URLSearchParams(window.location.search);
          const u = urlParams.get('u');
          const id = urlParams.get('id');
          const e = urlParams.get('e');
          const token = urlParams.get('token');

          getWithCache(
            `/manage/preferences/contact-data?u=${u}&id=${id}&e=${e}&token=${token}`,
          ).then((response) => {
            // CREATE FORMAT FUNCTION ********
            const contactData = response.contactData;

            // ADD TOKEN
            const hiddenTokenField = document.createElement('input');
            hiddenTokenField.setAttribute('type', 'hidden');
            hiddenTokenField.setAttribute('name', 'token');
            hiddenTokenField.setAttribute('value', contactData.token);
            formBlock.appendChild(hiddenTokenField);

            prePopulateFormFields(formBlock, contactData);

            subscribeBlock.replaceChild(formBlock, fieldsContainer);

            updateCheckboxGroupInputNames();
          });
        }
      });
    }
  }
};

export const completePreferenceButtons = () => {
  const updatePreferencesUrl = getUpdatePreferencesUrl();
  const form = document.querySelectorAll('[data-subscribe-form]')[0];

  let unsubscribeButton;
  let unsubscribeAction;

  // keep track of any appended form fields to cleanup when the
  // runtime re-runs in the editor.
  const inputsToCleanup = [];

  if (updatePreferencesUrl && form) {
    const queryString = window && window.location.search;
    const queryParams = new URLSearchParams(queryString);
    const id = queryParams.get('id');
    const u = queryParams.get('u');
    const e = queryParams.get('e');
    const c = queryParams.get('c');
    const allParams = { id, u, e, c };

    unsubscribeButton = document.querySelector('[data-unsubscribe-button]');
    unsubscribeAction = buildUnsubscribeAction({
      emailId: e,
      campaignId: c,
    });

    if (unsubscribeButton) {
      unsubscribeButton.addEventListener('click', unsubscribeAction);
    }
    // Attach all of the needed params as hidden inputs.
    Object.keys(allParams).forEach((paramKey) => {
      const formInput = document.createElement('input');
      formInput.setAttribute('type', 'hidden');
      formInput.setAttribute('name', paramKey);
      formInput.setAttribute('value', allParams[paramKey]);
      form.appendChild(formInput);
      inputsToCleanup.push(formInput);
    });

    form.setAttribute('action', updatePreferencesUrl);
    form.setAttribute('method', 'POST');
  }

  return () => {
    if (unsubscribeButton) {
      unsubscribeButton.removeEventListener('click', unsubscribeAction);
    }

    while (inputsToCleanup.length) {
      const input = inputsToCleanup.pop();
      input.parentNode.removeChild(input);
    }
  };
};
