import env from './env';

const hamburgerIconSvg =
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" focusable="false" aria-hidden="true" class="wink-icon"><path d="M22 7H2V5h20v2zm0 6H2v-2h20v2zM2 19h20v-2H2v2z"></path></svg>';
const crossIconSvg =
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" focusable="false" aria-hidden="true" class="wink-icon"><path d="M12 13.414l6.293 6.293 1.414-1.414L13.414 12l6.293-6.293-1.414-1.414L12 10.586 5.707 4.293 4.293 5.707 10.586 12l-6.293 6.293 1.414 1.414L12 13.414z"></path></svg>';

function createNavigationLink({ innerText, href, target }) {
  const listItem = document.createElement('li');

  const linkItem = document.createElement('a');
  linkItem.innerText = innerText;
  linkItem.href = href;
  if (target) {
    linkItem.target = target;
  }

  listItem.appendChild(linkItem);
  return listItem;
}

class ResponsiveNav {
  isInteractive = false;
  section;

  // Runtime created elements
  navigationDrawer;
  navigationDrawerContainer;
  hamburgerIconContainer;
  closeButton;

  constructor(section) {
    this.section = section;
    this.isInteractive = env.isForPlatformPreview || env.isForPublicConsumption;
  }

  openNavigationDrawer = (e) => {
    e.preventDefault();
    e.stopPropagation();

    requestAnimationFrame(() => {
      this.navigationDrawerContainer.classList.add('visible');
      // prettier-ignore
      this.navigationDrawer.style.paddingTop = `${
        this.section.clientHeight / 2
      }px`;
      document.body.style.overflow = 'hidden';
    });
  };

  closeNavigationDrawerStart = (e) => {
    const path = e.composedPath();

    if (
      path.includes(this.navigationDrawer) &&
      !path.includes(this.closeButton)
    ) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    document.body.style.overflow = 'auto';

    if (this.navigationDrawerContainer.classList.contains('visible')) {
      this.navigationDrawerContainer.classList.remove('visible');
    }
    // set "hiding" classes which allow elements to be visible while animating
    // out of view
    this.navigationDrawerContainer.classList.add('hiding');
  };

  closeNavigationDrawerEnd = () => {
    const isClosing = this.navigationDrawerContainer.classList.contains(
      'hiding',
    );
    if (isClosing) {
      // remove hiding classes now that the transition has completed
      this.navigationDrawerContainer.classList.remove('hiding');
    }
  };

  createNavigationDrawer() {
    this.navigationDrawerContainer = document.createElement('div');
    this.navigationDrawerContainer.classList.add(
      'responsiveNavigationDrawerContainer',
    );

    this.navigationDrawer = document.createElement('div');

    this.closeButton = document.createElement('div');
    this.closeButton.classList.add('closeButton');
    this.closeButton.innerHTML = crossIconSvg;
    this.navigationDrawer.appendChild(this.closeButton);
    this.navigationDrawerContainer.appendChild(this.navigationDrawer);

    // append to section for access to header section's padding CSS variables:
    this.section.appendChild(this.navigationDrawerContainer);
  }

  addEventListeners() {
    // Event listener to open the navigation drawer
    this.hamburgerIconContainer.addEventListener(
      'click',
      this.openNavigationDrawer,
    );

    // Event listeners to close the navigation drawer
    this.closeButton.addEventListener('click', this.closeNavigationDrawerStart);
    this.navigationDrawerContainer.addEventListener(
      'click',
      this.closeNavigationDrawerStart,
    );
    this.navigationDrawer.addEventListener(
      'transitionend',
      this.closeNavigationDrawerEnd,
    );
  }

  createHamburgerIcon() {
    this.hamburgerIconContainer = this.section.querySelector(
      '[data-ref="hamburgerIconRef"]',
    );

    if (this.hamburgerIconContainer) {
      this.hamburgerIconContainer.innerHTML = hamburgerIconSvg;
    }
  }

  appendCTA() {
    const ctaButton = this.section.querySelector('.mceButton');
    const parentDiv = document.getElementById('cartDropdown');
    if (ctaButton && !parentDiv?.contains(ctaButton)) {
      const clone = ctaButton.cloneNode(true);
      this.navigationDrawer.appendChild(clone);
    }
  }

  appendNavigationLinks() {
    const links = this.section.querySelectorAll('nav a');
    const navigationLinkList = document.createElement('ul');

    links.forEach(({ href, innerText, target }) => {
      const navLink = createNavigationLink({ href, innerText, target });
      navigationLinkList.appendChild(navLink);
    });

    this.navigationDrawer.appendChild(navigationLinkList);
  }

  startup() {
    //checks if nav drawer is not empty before creating hamburger menu
    if (
      this.section.querySelector('.mceButton') ||
      this.section.querySelectorAll('nav a').length
    ) {
      this.createHamburgerIcon();

      if (this.isInteractive) {
        this.createNavigationDrawer();
        this.addEventListeners();
        this.appendCTA();
        this.appendNavigationLinks();
      }
    }
  }
}

export const startupResponsiveNav = () => {
  const sections = document.querySelectorAll(
    '[data-js-target="headerWithNav"]',
  );
  sections.forEach((section) => new ResponsiveNav(section).startup());
};
