import { centsToCurrency } from '@mc/fn/broke';

const mappings = {
  '*|WEBSITE:COMMERCE_PRODUCT_TITLE|*': 'title',
  '*|WEBSITE:COMMERCE_PRODUCT_PRICE|*': 'price.amount',
};

const PAGE_SIZE = 12;

function getByKeyPath(subject, path, defaultValue = null) {
  if (!path || !subject || !Object.entries(subject)) {
    return defaultValue;
  }
  const keyParts = path.split('.');
  keyParts.forEach((part) => {
    if (!subject[part]) {
      return defaultValue;
    }
    subject = subject[part];
  });
  return subject;
}

/**
 * This function gets the correct data from the product passed in that will hydrate the HTML.
 * @param {object} product - Product contains product data passed to page.
 * @param {string} productField - These are the mapped product fields in the mappings const.
 * @param {string} localeCode - The locale code for the store. Like "en-US", "da-DK", etc.
 * @returns {String|*} - Returns the product value, including title and price amount.
 */
function formatProductValues(product, productField, localeCode) {
  let priceResult = 'Price';
  switch (productField) {
    case 'price.amount':
      // If the price should be formatted, ensure we do so.
      if (getByKeyPath(product, 'price.amount')) {
        // If the store does not have a locale code, default to en-US.
        if (!localeCode) {
          localeCode = 'en-US';
        }

        priceResult = centsToCurrency({
          amount: getByKeyPath(product, 'price.amount'),
          currencyCode: getByKeyPath(product, 'price.currency_code'),
          localeCode,
        });
      }
      return priceResult;
    default:
      return getByKeyPath(product, productField);
  }
}

/**
 * This function hydrates the HTML picture and img tags with the product images.
 *
 * @param {object} product - Product contains product data passed to page.
 * @param {array} itemTemplate - The HTML template from the selector 'data-product-listing-item'.
 */
function setImages(product, itemTemplate) {
  const imageUrl = product.image.image_url;
  // TODO: Why is data attribute: [data-product-image] not working
  const productImageSource = itemTemplate.querySelector('.mceImage picture');
  // here we are hydrating the <picture> <source> tags with the image
  if (productImageSource) {
    itemTemplate.querySelectorAll('.mceImage picture source').forEach((src) => {
      src.setAttribute('srcset', imageUrl);
    });
  }

  const productImg = itemTemplate.querySelector('.mceImage picture img');
  // here we are hydrating the regular <img> tag with the image
  if (productImg) {
    productImg.setAttribute('src', imageUrl);
  }
}

/**
 *This function hydrates the HTML merge tags with the product data
 * @param {object} product - Product contains product data from shopchimp.
 * @param {array} itemTemplate - The HTML template from the selector 'data-product-listing-item'.
 * @param {string} locale - The locale code for the store. Used for things like formatting currencies.
 * @returns {string} newProduct - This is the HTML that has been hydrated with the product data passed in.
 */
function setProductData(product, itemTemplate, locale) {
  let newProduct = '' + itemTemplate.innerHTML;
  Object.entries(mappings).forEach(([mergeTag, productField]) => {
    const value = formatProductValues(product, productField, locale);
    newProduct = newProduct.replace(mergeTag, value);
  });
  return newProduct;
}

/**
 * This function sets the product data onto the website template and links the PDP to each product.
 * @param {array} products Product data from shopchimp.
 * @param {array} itemTemplate - The HTML template from the selector 'data-product-listing-item'.
 * @param {array} productListingElement The HTML element from the selector 'data-product-listing'.
 * @param {boolean|null} forPreview Boolean if we should render preview data.
 * @param {string|null} pdpPreviewUrl The URL for previewing the PDP page. Used so the client can link to the appropriate URL.
 * @param {string|null} storeLocale The locale for the store.
 */
function setData(
  products,
  itemTemplate,
  productListingElement,
  forPreview,
  pdpPreviewUrl,
  storeLocale,
) {
  products.forEach((p) => {
    const newProductElement = itemTemplate.cloneNode(true);

    //set img, if passed in
    if (p.image) {
      setImages(p, newProductElement);
    }

    let newProduct = setProductData(p, newProductElement, storeLocale);

    // Hydrate the product with the link to the PDP. When in preview mode, ensure it links to the website preview page.
    let pdpUrl = `/store/products/${p.product_id}`;
    const createPdpLink = (url, children) =>
      `<a href="${url}" style="text-decoration: none; overflow: hidden; width: 100%; display: block;">${children}</a>`;

    // Ensure we're given a PDP page ID before attempting to create a PDP link in preview mode.
    // For preview mode without a PDP preview URL, don't attempt to link the product.
    if (forPreview && pdpPreviewUrl) {
      // If we're not showing a fake placeholder product, include its ID in the PDP URL for direct preview.
      if (p.product_id !== 'FAKE_PRODUCT') {
        pdpUrl = `${pdpPreviewUrl}&product_id=${p.product_id}`;
      } else {
        pdpUrl = pdpPreviewUrl;
      }

      newProduct = createPdpLink(pdpUrl, newProduct);
    } else if (!forPreview) {
      // Otherwise, it's the real render, so we should just link to the real PDP url.
      newProduct = createPdpLink(pdpUrl, newProduct);
    }

    // set the product data on the element
    newProductElement.innerHTML = newProduct;
    productListingElement.appendChild(newProductElement);
  });
}

export function setUpProductListingPagination() {
  // get the product data from the script tag
  let productListingData = document.getElementById('js-plp-data');
  // get the product listing element and item, then remove the template from the element
  const productListingElement = document.querySelector(
    '[data-product-listing]',
  );
  const itemTemplate = document.querySelector('[data-product-listing-item]');
  if (!productListingData || !productListingElement || !itemTemplate) {
    return;
  }
  productListingElement.removeChild(itemTemplate);

  let innerHTML = productListingData.innerHTML;
  const productData = JSON.parse(innerHTML);

  const { products, store_locale: storeLocale } = productData;
  if (!productData || !products) {
    return;
  }

  // get Load More button
  const loadMoreButton = document.querySelector('[data-product-load-more]');
  const paginationText = document.querySelector(
    '[data-product-pagination-text]',
  );
  const paginationSection = document.querySelector('[data-product-pagination]');

  let forPreview = false;
  if (productData.forPreview) {
    forPreview = true;
  }

  let pdpPreviewUrl = null;
  if (productData.pdpPreviewUrl) {
    pdpPreviewUrl = productData.pdpPreviewUrl;
  }

  //set the product info on the listing item template
  setData(
    products,
    itemTemplate,
    productListingElement,
    forPreview,
    pdpPreviewUrl,
    storeLocale,
  );

  const totalProducts = productData.total_size;
  let shownProducts = products.length;
  // Either show or hide the Load More button + pagination text
  showLoadMore(totalProducts, shownProducts);

  /**
   * This function either displays or hides the Load More button + pagination text of "Viewing X of Y products".
   * @param {int} total The total number of product in the store
   * @param {int} shown The number of products displayed on the page.
   */
  function showLoadMore(total, shown) {
    if (total > shown) {
      const copy = `Viewing ${shown} of ${total} products`;
      paginationText.innerHTML = `<p>${copy}</p>`;

      loadMoreButton.addEventListener('click', handleOnClick);
    } else {
      // if there aren't more than the number of shown in the total no of products, hide this block
      if (paginationSection) {
        paginationSection.setAttribute('style', 'display: none');
      }
    }
  }

  /**
   * This function handles the pagination, including getting the next batch of product data, and hydrating the page.
   */
  function handleOnClick() {
    const url = window.location.href;
    const urlObject = new URL(url);
    // set offset to be added to as we make additional requests for more products
    let pageSize = Number(urlObject.searchParams.get('page_size')) || PAGE_SIZE;
    let offset;
    if (!pageSize) {
      offset = 12;
    } else {
      offset = pageSize;
    }

    urlObject.searchParams.set('offset', offset);
    urlObject.searchParams.set('page_size', PAGE_SIZE);

    fetch(urlObject, { method: 'POST' })
      .then((res) => res.text())
      .then(function(res) {
        const parser = new DOMParser();
        const parsedHtml = parser.parseFromString(res, 'text/html');
        // get the product data from the most recent request
        productListingData = parsedHtml.getElementById('js-plp-data');

        if (!productListingData) {
          return;
        }

        innerHTML = productListingData.innerHTML;
        productListingData = JSON.parse(innerHTML);

        //add the new product data to the template of the page
        setData(
          productListingData.products,
          itemTemplate,
          productListingElement,
          productListingData.forPreview,
          productListingData.pdpPreviewUrl,
          storeLocale,
        );

        // this is to ensure that once we show all products, we don't show the Load More button
        shownProducts += productListingData.products.length;
        pageSize += 12;

        // update the url to append the page size (AKA limit) so that refreshes do not change the number of products on the page.
        const urlStr = window.location.href;
        const urlObj = new URL(urlStr);
        urlObj.searchParams.set('page_size', pageSize);
        history.replaceState({}, '', urlObj.toString());

        showLoadMore(totalProducts, shownProducts);
      });
  }
}

/**
 * SSR Pagination setup
 *
 * How does this work?
 * 1. The initial page load has products fully rendered and ready to go thanks to SSR
 * 2. If there is more content, the page (generated from SSR) will also have a load more button
 * 3. Taking into account url params (and their default values) the "Load More" button will load the next set of products
 * 4. Each load is a pjax-like request. We load the entire PLP page with the fetch request with params for the next page
 * 5. We extract the rendered product items HTML from the response, and append it to the end of the product grid
 *
 * Note:
 * This means, we don't use JSON data (js-plp-data) and JS to render each product like the old pagination method setUpProductListingPagination.
 * Instead, we rely on SSR to do all of the work, which means that each page request whether SSR only or JS enhanced will be subject
 * to the CommercePageStrategy.php cache TTL of 5 minutes unless otherwise invalidated.
 *
 * @returns undefined No return
 */
export function setUpSSRProductListingPagination() {
  // get the product listing element and item, then remove the template from the element
  const productListingElement = document.querySelector(
    '[data-store-plp-products]',
  );

  // If we don't at least have the product grid then stop here.
  // Likely causes would be a 400/500 error
  if (!productListingElement) {
    return;
  }

  // Initial total products
  const initialTotal = parseInt(
    productListingElement.dataset.storePlpTotal,
    10,
  );

  // Initial shown products, it can be 0 - totalProduct due to the nature
  // of the "infinite" scroll
  const initialShown = parseInt(
    productListingElement.dataset.storePlpShown,
    10,
  );

  // Shown products. Increments after every pagination request
  let shownProducts = initialShown;

  // Stop if we have no products
  if (initialTotal === 0) {
    return;
  }

  // Get pagination controls
  // The the entire pagination control (includes the text and button)
  const paginationSection = document.querySelector(
    '[data-store-plp-pagination]',
  );

  // Load more button (its an a tag with an href for SSR, but like a button in JS)
  const loadMoreButton = document.querySelector('[data-store-plp-load-more]');
  const loadingStatus = document.querySelector(
    '[data-ref="store-plp-loading-status"]',
  );

  // The text that stated "Viewing 12 of 40 products"
  const paginationText = document.querySelector('[data-store-plp-page-label]');

  // Initialize "Load More" button if available
  if (loadMoreButton) {
    loadMoreButton.addEventListener('click', handleOnClick);

    // Either show or hide the Load More button + pagination text
    showLoadMore(initialTotal, initialShown);
  }

  /**
   * This function either displays or hides the Load More button + pagination text of "Viewing X of Y products".
   * @param {int} total The total number of product in the store
   * @param {int} shown The number of products displayed on the page.
   */
  function showLoadMore(total, shown) {
    enableLoadMoreButton();

    if (total > shown) {
      const copy = `Viewing ${shown} of ${total} products`;
      paginationText.innerHTML = `<p>${copy}</p>`;
    } else {
      // if there aren't more than the number of shown in the total no of products, hide this block
      if (paginationSection) {
        paginationSection.setAttribute('style', 'display: none');
      }
    }
  }

  function disableLoadMoreButton() {
    loadMoreButton.setAttribute('aria-disabled', 'true');
    loadMoreButton.innerText = 'Loading...';
    loadingStatus.innerText = 'Loading...';
  }

  function enableLoadMoreButton() {
    loadMoreButton.removeAttribute('aria-disabled');
    loadMoreButton.innerText = 'Load More';
    loadingStatus.innerText = '';
  }

  function isLoadMoreDisabled() {
    return loadMoreButton.getAttribute('aria-disabled') === 'true';
  }

  /**
   * This function handles the pagination, including getting the next batch of product data, and hydrating the page.
   */
  function handleOnClick(e) {
    // This button is actually a link that has pagination set up for SEO
    // Lets stop it from paginating using SSR only for SEO so the JS "load more" works
    e.preventDefault();

    // Prevent rapid clicks.
    if (isLoadMoreDisabled()) {
      return;
    }

    // Load more button transition into its "loading state"
    disableLoadMoreButton();

    const url = window.location.href;
    const urlObject = new URL(url);
    // set offset to be added to as we make additional requests for more products
    let pageSize = Number(urlObject.searchParams.get('page_size')) || PAGE_SIZE;
    let offset;
    if (!pageSize) {
      offset = 12;
    } else {
      offset = pageSize;
    }

    urlObject.searchParams.set('offset', offset);
    urlObject.searchParams.set('page_size', PAGE_SIZE);

    // Fetch the next page (response will be HTML and we will parse it to append to the current page)
    fetch(urlObject, { method: 'POST' })
      .then((res) => res.text())
      .then(function(res) {
        // Parse HTML response
        const parser = new DOMParser();
        const parsedHtml = parser.parseFromString(res, 'text/html');

        // Get the product grid element contains the products
        const newProducts = parsedHtml.querySelector(
          '[data-store-plp-products]',
        );

        // If the products grid doesn't exist for some reason (maybe a 400/500 error)
        // then stop here
        if (!newProducts) {
          enableLoadMoreButton();
          return;
        }

        // Grab the id from the first product of the newly loaded grid of products
        const firstNewProductId = newProducts.children[0].getElementsByTagName(
          'a',
        )[0].id;

        // Update the current product grid with the HTML contents of the
        // requested product grid
        productListingElement.insertAdjacentHTML(
          'beforeend',
          newProducts.innerHTML,
        );

        // After loaded products are added to the document,
        // we focus the first of those new products so that keyboard navigation
        // picks up at the start of the new elements.
        document.getElementById(firstNewProductId).focus();

        // Update pagination
        // Pull data from the data attribute of the PLP grid container
        const { storePlpTotal, storePlpShown } = newProducts.dataset;

        // Convert to INTs as data-attributes are strings
        const total = parseInt(storePlpTotal, 10);
        const shown = parseInt(storePlpShown, 10);

        // this is to ensure that once we show all products, we don't show the Load More button
        shownProducts += shown;
        pageSize += PAGE_SIZE;

        // update the url to append the page size (AKA limit) so that refreshes do not change the number of products on the page.
        const urlStr = window.location.href;
        const urlObj = new URL(urlStr);
        urlObj.searchParams.set('page_size', pageSize);
        history.replaceState({}, '', urlObj.toString());

        // Update the current grids data attributes
        productListingElement.dataset.storePlpTotal = total;
        productListingElement.dataset.storePlpShown = shownProducts;

        // Update the pagination controls
        showLoadMore(total, shownProducts);
      });
  }
}
