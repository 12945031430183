import { generateRandomString } from '@mc/fn/rando';

const canUseXHR = (url) => {
  const parsed = document.createElement('a');
  parsed.href = url;
  return (
    window.top.location.protocol === parsed.protocol &&
    window.top.location.host.toLowerCase() === parsed.host.toLowerCase() &&
    window.top.location.port === parsed.port
  );
};

const getViaXHR = async (url) => {
  const headers = { 'X-Requested-With': 'XMLHttpRequest' };
  const response = await fetch(url, { headers });
  const data = await response.json();
  return data;
};

const getViaJSONP = (url) => {
  return new Promise((resolve) => {
    // Toss an 'a' in front of the string so it doesn't start with a number and bug out
    const callbackName = 'a' + generateRandomString();
    window[callbackName] = (data) => {
      cleanup();
      resolve(data);
    };

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url + '&c=' + callbackName;
    script.async = true;
    script.defer = true;

    const head = document.head;
    head.appendChild(script);

    function cleanup() {
      if (window[callbackName]) {
        delete window[callbackName];
      }

      if (script) {
        head.removeChild(script);
      }
    }
  });
};

export const get = (url) => {
  return canUseXHR(url) ? getViaXHR(url) : getViaJSONP(url);
};

const getCache = {};

// getWithCache can be used to prevent duplicate lookups when
// runtimes are called in the editor. Most of the time you want to use
// this instead of directly calling get()
export const getWithCache = async (url) => {
  if (!getCache[url]) {
    getCache[url] = await get(url);
  }

  return getCache[url];
};
