import destructiveReadSlice from '@mc/editing/blocks/runtimes/fn/destructiveReadSlice';
import env from './env';

/**
 * Iterates through any oembed blocks on the page and inserts their content
 */
export const insertOembedContent = () => {
  if (env.isForPublicConsumption || env.isForPlatformPreview) {
    const oembedBlocks = document.querySelectorAll('[data-oembed-container]');

    if (oembedBlocks) {
      oembedBlocks.forEach((oembedBlock) => {
        const { contentHTML } = destructiveReadSlice(oembedBlock, 'oembedData');

        if (contentHTML) {
          oembedBlock.insertAdjacentHTML('afterbegin', contentHTML);
        }
      });
    }
  }
};
