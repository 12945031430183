// money formatting helpers

// Used by getCurrencyFormatting to cache requests for currencies based on
// `[CurrencyCode/LocaleCode]` to increase performance of constant lookups
const currencyInfoCache = {};

/**
 * Converts an numeric amount representing a currency value to cents in the provided currency
 * @example USD Number(19.99) = Int(1999)
 * @param {Object} props Params
 * @param {String|Number} props.amount Numeric monetary value
 * @param {String} [props.currencyCode] ISO 4217 three-letter (country + currency) code (USD, CAD, EUR, MXN)
 * @param {String|Number} [props.localeCode] BCP 47 locale tag with language and region identifiers (`en-US`, `de-DE`, `es-MX`)
 * @returns {Int} A value in cents
 */
export function amountToCents({ amount, currencyCode = 'USD', localeCode }) {
  const locale = localeCode || Intl.NumberFormat().resolvedOptions().locale;

  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyCode,
  });

  // Make sure amount is a number before formatting
  const numberValue = currencyToNumber({
    amount: `${amount}`,
    currencyCode,
    localeCode: locale,
  });

  const stringValue = formatter.format(numberValue);

  // Strip any non numeric values
  return Number(stringValue.replace(/[^0-9]+/g, ''));
}

/**
 * Converts an string or number that represents a monetary value to a string with symbols and formatting
 * @example USD String(199.9) = String("$199.90")
 * @param {Object} props props
 * @param {String|Number} props.amount String or decimal value representing a monetary value
 * @param {String} [props.currencyCode] ISO 4217 three-letter (country + currency) code (USD, CAD, EUR, MXN)
 * @param {String|Number} [props.localeCode] BCP 47 locale tag with language and region identifiers (`en-US`, `de-DE`, `es-MX`)
 * @returns {String} A string representing a currency with symbol and grouping
 */
export function amountToCurrency({
  amount,
  currencyCode = 'USD',
  localeCode,
  ...options
}) {
  const locale = localeCode || Intl.NumberFormat().resolvedOptions().locale;
  const { scale } = getCurrencyFormatting({ currencyCode, localeCode: locale });

  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyCode,
    maximumFractionDigits: scale,
    minimumFractionDigits: scale,
    ...options,
  });

  // Make sure amount is a number before formatting
  const numberValue = currencyToNumber({
    amount: `${amount}`,
    currencyCode,
    localeCode: locale,
  });

  return formatter.format(numberValue);
}

/**
 * Split a given values into currency parts
 *
 * This method is best used to sanitize a price input field
 * @param {Object} props Params
 * @param {String|Number} props.amount String decimal value
 * @param {String} [props.currencyCode] ISO 4217 three-letter (country + currency) code (USD, CAD, EUR, MXN)
 * @param {String|Number} [props.localeCode] BCP 47 locale tag with language and region identifiers (`en-US`, `de-DE`, `es-MX`)
 * @returns {Array} An array of objects [{ type, value }]
 */
export function amountToCurrencyParts({
  amount,
  currencyCode = 'USD',
  localeCode,
  ...options
}) {
  const locale = localeCode || Intl.NumberFormat().resolvedOptions().locale;
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyCode,
    ...options,
  });

  // Make sure amount is a number before formatting
  const numberValue = currencyToNumber({
    amount: `${amount}`,
    currencyCode,
    localeCode: locale,
  });

  // Format into parts, this will be an array of objects [{ type, value }]
  return formatter.formatToParts(numberValue);
}

/**
 * Converts an integer number that represents a monetary value to a string
 * @example USD Int(1999) = String("19.99")
 * @param {Object} props Params
 * @param {Number} props.amount Integer monetary value
 * @param {String} [props.currencyCode] ISO 4217 three-letter (country + currency) code (USD, CAD, EUR, MXN)
 * @param {String|Number} [props.localeCode] BCP 47 locale tag with language and region identifiers (`en-US`, `de-DE`, `es-MX`)
 * @returns {String} A string representing a currency
 */
export function centsToString({
  amount,
  currencyCode = 'USD',
  localeCode,
  ...options
}) {
  const locale = localeCode || Intl.NumberFormat().resolvedOptions().locale;
  const { scale } = getCurrencyFormatting({ currencyCode, localeCode: locale });

  const formatterOptions = {
    currency: currencyCode,
    useGrouping: false,
    ...options,
  };

  let adjustedAmount = amount;
  if (scale > 0) {
    // Divide by a standard fraction amount
    adjustedAmount = amount / Math.pow(10, scale);

    // Set min/max fraction digits for the formatter
    formatterOptions.maximumFractionDigits = scale;
    formatterOptions.minimumFractionDigits = scale;
  }

  const formatter = new Intl.NumberFormat(locale, formatterOptions);

  return formatter.format(adjustedAmount);
}

/**
 * Converts an integer number that represents a monetary value to a number
 * @example USD Int(1999) = Number(19.99)
 * @param {Object} props Params
 * @param {Number} props.amount Integer monetary value
 * @param {String} [props.currencyCode] ISO 4217 three-letter (country + currency) code (USD, CAD, EUR, MXN)
 * @param {String|Number} [props.localeCode] BCP 47 locale tag with language and region identifiers (`en-US`, `de-DE`, `es-MX`)
 * @returns {Number} A number representing a currency
 */
export function centsToNumber(props) {
  return Number(centsToString(props));
}

/**
 * Converts an integer number that represents a monetary value to a string with symbols and formatting
 * @example USD Int(1999) = String("$19.99")
 * @param {Object} props props
 * @param {Number} props.amount Integer monetary value
 * @param {String} [props.currencyCode] ISO 4217 three-letter (country + currency) code (USD, CAD, EUR, MXN)
 * @param {String|Number} [props.localeCode] BCP 47 locale tag with language and region identifiers (`en-US`, `de-DE`, `es-MX`)
 * @returns {String} A string representing a currency with symbol and grouping
 */
export function centsToCurrency(props) {
  return centsToString({
    style: 'currency',
    useGrouping: true,
    ...props,
  });
}

/**
 * Get formatting details of a particular currency using Intl.NumberFormat
 *
 * This method is best used to get the decimal, group, scale, symbol and its position for adjusting the
 * display if UI elements. It caches the currency formatting info return by Intl.NumberFormat based on
 * the currency code and locale to reduce the cost if the lookup
 * @param {Object} props Params
 * @param {String} [props.currencyCode] ISO 4217 three-letter (country + currency) code (USD, CAD, EUR, MXN)
 * @param {String|Number} [props.localeCode] BCP 47 locale tag with language and region identifiers (`en-US`, `de-DE`, `es-MX`)
 * @param {Boolean} [props.enableCache] Enable caching of fetch results to speed up subsequent lookups
 * @returns {Object} An object containing `{ decimal, group, scale, symbol, symbolPosition }`
 */

export function getCurrencyFormatting({
  currencyCode = 'USD',
  localeCode,
  enableCache = true,
}) {
  const locale = localeCode || Intl.NumberFormat().resolvedOptions().locale;
  const cachedCurrencyFormatting =
    currencyInfoCache[`${currencyCode}/${locale}`];

  if (cachedCurrencyFormatting && enableCache) {
    return cachedCurrencyFormatting;
  }

  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyCode,
  });

  const formatParts = formatter.formatToParts(100000.2222); // Use a big enough number to force grouping

  // Generate a simplified object of the currency parts logically grouped
  let numberPos;
  let symbolPos;
  const parts = {
    currencyCode,
    localeCode: locale,
    symbol: '',
    group: '',
    scale: 0,
    symbolPosition: '',
  };

  formatParts.forEach(({ type, value }, index) => {
    // Determine the position of the number
    if (type === 'integer' || type === 'fraction' || type === 'decimal') {
      if (numberPos === undefined) {
        numberPos = index;
      }
    }

    // Pluck out various attributes of the currency
    if (type === 'fraction') {
      // 0, 2, 3 or whatever
      parts.scale = value.length;
    } else if (type === 'decimal') {
      // . or ,
      parts.decimal = value;
    } else if (type === 'group') {
      // , or .
      parts.group = value;
    } else if (type === 'currency') {
      // $, ¥, etc
      parts.symbol = value;
      if (symbolPos === undefined) {
        symbolPos = index;
      }
    }
  });

  parts.symbolPosition = symbolPos < numberPos ? 'leading' : 'trailing';

  if (enableCache) {
    currencyInfoCache[`${parts.currencyCode}/${parts.localeCode}`] = parts;
  }

  return parts;
}

/**
 * Given a string amount representing a price ensure any extraneous characters or mis-formatting
 * that are not part of displaying a correct price in the field are corrected.
 *
 * This method is best used to sanitize a price input field
 * @example Given the input for USD: "199.9999,99", return "199.99"
 * @example Given the input for EUR: "19.999,9999.99", return "19999,99"
 * @param {Object} props Params
 * @param {String} props.amount String decimal value
 * @param {String} [props.currencyCode] ISO 4217 three-letter (country + currency) code (USD, CAD, EUR, MXN)
 * @returns {String} Sanitized decimal values as a string
 */
export function sanitizeCurrencyInput({
  amount,
  currencyCode = 'USD',
  localeCode,
}) {
  const { group, decimal, scale } = getCurrencyFormatting({
    currencyCode,
    localeCode,
  });

  if (scale > 0) {
    const [sanitizedAmount] = amount
      .replace(new RegExp(`\\${group}`, 'g'), '')
      .match(
        new RegExp(
          `^(\\d{0,}(\\${decimal}(\\d{0,${scale}}))?(e\\+\\d{0,})?)`,
          'g',
        ),
      );

    return sanitizedAmount;
  }

  const [sanitizedAmount] = amount.match(/(\d{0,})/g);

  return sanitizedAmount;
}

/**
 * Given a string amount representing a price ensure any extraneous characters or mis-formatting
 * that are not a number [0-9] or a period [.] for the decimal place.
 *
 * This method is best used to sanitize a price input field
 * @example Given the input for USD: "199.9999,99", return 199.99
 * @example Given the input for EUR: "19.999,9999.99", return 19999.99
 * @param {Object} props Params
 * @param {String} props.amount String decimal value
 * @param {String} [props.currencyCode] ISO 4217 three-letter (country + currency) code (USD, CAD, EUR, MXN)
 * @returns {Number} Sanitized amount as a number
 */
export function currencyToNumber({ amount, currencyCode = 'USD', localeCode }) {
  const sanitizedAmount = sanitizeCurrencyInput({
    amount,
    currencyCode,
    localeCode,
  });

  // Ensure a period for fractional currencies;
  return Number(sanitizedAmount.replace(/^(\.|,)/g, '.'));
}

/**
 * Given a string amount representing a price, check if the value is NaN and
 * convert it to a string of "0" if it is.
 *
 * This method is best used to sanitize a price input field
 * @example Given the input: ".", returns "0"
 * @example Given the input of a nunber: "19.99", returns "19.99"
 * @param {String} price String decimal value
 * @returns {String} Either the original string if it was a number, or "0" if not.
 */
export const displayZeroIfNan = (price) => {
  return isNaN(parseFloat(price)) ? '0' : price;
};
