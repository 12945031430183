import { buildDateAndTime } from './dateAndTime';
import { centsToString, amountToCurrency } from '@mc/fn/broke';

const editIcon = `<svg width="36" height="36" viewBox="0 0 36 36" fill="none" fill-rule="evenodd" clip-rule="evenodd" xmlns="http://www.w3.org/2000/svg">
    <path d="M30.6215 8.99998L24.0001 2.37866L4.72177 21.657L1.60742 29.4429L3.55722 31.3927L11.3431 28.2784L30.6215 8.99998ZM5.69272 27.3074L7.27851 23.3429L24.0001 6.6213L26.3788 8.99998L9.65719 25.7216L5.69272 27.3074Z" fill="#403B3B"/>
    <path d="M12.0001 32.9999L15.0001 29.9999H33.0001V32.9999H12.0001Z" fill="#403B3B"/>
</svg>`;

function buildStepHeader(stepNumber, headerText) {
  const stepHeader = document.createElement('div');
  stepHeader.setAttribute('class', 'mceServices-header');

  const bullet = document.createElement('span');
  bullet.setAttribute('class', 'mceServices-headerBullet');
  bullet.innerText = stepNumber;
  const header = document.createElement('h2');
  header.innerText = headerText;
  stepHeader.appendChild(bullet);
  stepHeader.appendChild(header);

  return stepHeader;
}

function buildCollapsedStepSummary(html, onSave) {
  const summaryWrapper = document.createElement('div');
  summaryWrapper.classList.toggle('mceServices-collapsed', !!html);

  summaryWrapper.innerHTML = html;

  if (html) {
    const editButton = document.createElement('button');
    // TODO: Replace this with an edit icon
    editButton.setAttribute('class', 'editButton');
    editButton.innerHTML = editIcon;
    editButton.addEventListener('click', (e) => {
      e.preventDefault();
      onSave();
    });
    summaryWrapper.appendChild(editButton);
  }

  return summaryWrapper;
}

const serviceTypes = {
  person: 'In-Person Session',
  virtual: 'Virtual Session',
  phone_incoming: 'Phone Call',
  phone_outgoing: 'Phone Call',
};
export function getFormattedPrice(price) {
  if (!price) {
    return 'Free';
  }
  const priceString = centsToString({ amount: Number(price) });
  const currencyString = amountToCurrency({ amount: priceString });
  return currencyString;
}
function buildService(serviceData, onSelect) {
  const root = document.createElement('div');
  root.setAttribute('class', 'mceServices-service');

  const info = document.createElement('div');
  info.setAttribute('class', 'mceServices-service-information');
  const name = document.createElement('h4');
  name.innerText = serviceData.name;
  const details = document.createElement('p');
  details.innerText = Array.isArray(serviceData.type)
    ? `${serviceData.duration} mins • ${serviceData.type
        .map((serviceType) => serviceTypes[serviceType])
        .join(', ')}`
    : '';
  details.setAttribute('class', 'mceServices-service-information-sessionType');
  const description = document.createElement('p');
  description.innerText = serviceData.description || '';
  info.appendChild(name);
  info.appendChild(description);
  info.appendChild(details);
  const price = document.createElement('div');
  price.setAttribute('class', 'mceServices-service-price');
  const priceValue = document.createElement('strong');
  priceValue.innerHTML = serviceData.priceFormatted;
  price.appendChild(priceValue);
  if (serviceData.priceFormatted !== 'Free') {
    const priceDisclaimer = document.createElement('span');
    priceDisclaimer.setAttribute(
      'class',
      'mceServices-service-price-disclaimer',
    );
    priceDisclaimer.innerHTML = serviceData.isCommerceEnabled
      ? 'paid upon booking'
      : 'paid at time of appointment';
    price.appendChild(priceDisclaimer);
  }

  const button = document.createElement('div');
  button.setAttribute('class', 'mceButton');
  const nativeButton = document.createElement('button');
  nativeButton.innerText = 'Select';
  nativeButton.addEventListener('click', (e) => {
    e.preventDefault();
    onSelect(serviceData.serviceId);
  });
  button.appendChild(nativeButton);

  root.appendChild(info);
  root.appendChild(price);
  root.appendChild(button);

  return root;
}
export function renderStepOne(isSelected, initialState, onSave) {
  // Create root wrapper
  const wrapper = document.createElement('div');
  // Append header and services list
  wrapper.appendChild(buildStepHeader(1, 'Select a service'));

  if (isSelected) {
    const servicesWrapper = document.createElement('div');
    servicesWrapper.setAttribute('class', 'mceServices-services');
    if (!initialState.services || initialState.services.length === 0) {
      const emptyMessage = document.createElement('div');
      emptyMessage.classList.add('mceServices-empty');
      emptyMessage.innerText = 'There are currently no services available.';
      servicesWrapper.appendChild(emptyMessage);
    } else {
      initialState.services.forEach((serviceData) => {
        // TODO: update this render function when data shape has been updated above
        servicesWrapper.appendChild(buildService(serviceData, onSave));

        const divider = document.createElement('hr');
        divider.setAttribute('class', 'mceDivider');
        servicesWrapper.appendChild(divider);
      });
    }

    wrapper.appendChild(servicesWrapper);
  } else {
    const selectedService = initialState.services.find(
      (service) => service.serviceId === initialState.selectedServiceId,
    );
    if (!selectedService) {
      wrapper.appendChild(
        buildCollapsedStepSummary(
          'Selected service no longer available.',
          onSave,
        ),
      );
      return wrapper;
    }
    const collapsedText = document.createElement('span');

    const serviceName = document.createElement('strong');
    serviceName.innerText = selectedService.name;

    const priceHelperTextContent = selectedService.isCommerceEnabled
      ? `You’ll pay for this service when you book.`
      : `You’ll pay for this service on the date and time of your appointment.`;

    collapsedText.innerHTML = 'You chose ';
    collapsedText.appendChild(serviceName);
    collapsedText.innerHTML += `, a <strong>${
      selectedService.duration
    } minute ${serviceTypes[selectedService.type[0]]}</strong>
          for
          <strong>${selectedService.priceFormatted}</strong>
        </span>`;

    if (selectedService.priceFormatted !== 'Free') {
      const priceHelperText = `<span class="mceServices-service-priceHelperText">${priceHelperTextContent}</span>`;
      collapsedText.innerHTML += priceHelperText;
    }

    wrapper.appendChild(
      buildCollapsedStepSummary(collapsedText.outerHTML, onSave),
    );
  }
  const divider = document.createElement('hr');
  divider.setAttribute('class', 'mceDivider');
  wrapper.appendChild(divider);
  return wrapper;
}

export function renderStepTwo(
  isSelected,
  initialState,
  onSave,
  getTimeslotData,
) {
  // Create root wrapper
  const wrapper = document.createElement('div');

  // Append header
  wrapper.appendChild(buildStepHeader(2, 'Select a date and time'));

  if (isSelected) {
    const dateAndTime = document.createElement('div');
    dateAndTime.setAttribute('class', 'mceServices-date-and-time-wrapper');
    dateAndTime.appendChild(
      buildDateAndTime(
        initialState.selectedDate,
        initialState.selectedTime,
        onSave,
        getTimeslotData,
        initialState.startOfWeek,
        initialState.initialSelectedTimeslot,
      ),
    );

    wrapper.appendChild(dateAndTime);
  } else {
    // TODO: when we know more about the selected date/time types, do better date string formatting
    const collapsedText =
      initialState.currentStep > 2 && initialState.selectedDate
        ? `
      <span>
      You chose <strong>${initialState.selectedDate.toDateString()}</strong>
      at
      <strong>${initialState.selectedTime.formatted} - ${
            initialState.selectedTime.formattedEndTime
          }
      (${initialState.selectedTime.timezoneLabel})</strong>
      </span>`
        : '';
    wrapper.appendChild(buildCollapsedStepSummary(collapsedText, onSave));
  }

  const divider = document.createElement('hr');
  divider.setAttribute('class', 'mceDivider');
  wrapper.appendChild(divider);

  return wrapper;
}
