import { centsToCurrency } from '@mc/fn/broke';
import { closeIcon } from './commerceIcons';

// Functions to build the cart dropdown component
// aka the mini-cart

const buildLineItem = (lineItem) => {
  const { in_stock, is_product_archived } = lineItem;
  let quantityDetails = `<div class="productDetails">
        <div>Qty: </div>
        <span>${lineItem.quantity}</span>
      </div>`;
  let itemPrice = `<span className="price">${lineItem.subtotal}</span>`;

  if (typeof in_stock === 'boolean' && !in_stock) {
    quantityDetails = `<div class="productDetails">
        <span>Out of Stock</span>
      </div>`;
    itemPrice = '';
  }

  if (typeof is_product_archived === 'boolean' && is_product_archived) {
    quantityDetails = `<div class="productDetails">
        <span>Unavailable</span>
      </div>`;
    itemPrice = '';
  }

  const img =
    lineItem.image && lineItem.image.image_url
      ? lineItem.image.image_url
      : 'https://cdn-images.mailchimp.com/stores/shopper/placeholder-image_3x.png';
  const altText =
    lineItem.image && lineItem.image.alt_text ? lineItem.image.alt_text : '';
  return `
    <div class="imageWrap">
      <div class="inner">
        <img
          src="${img}"
          alt="${altText}"
        />
      </div>
    </div>
    <div class="productDescription">
      <div class="productTitle">
      <span>${lineItem.title}</span>
      <span class="itemPrice">${itemPrice}</span>
      </div>
      ${quantityDetails}
    </div>
  `;
};

export function buildCartDropdown(cart) {
  const cartIcon = document.getElementById('cartIcon');

  if (!cartIcon || !cart) {
    return;
  }

  const dropdown = document.getElementById('cartDropdown');
  if (dropdown) {
    dropdown.remove(); // ensure we only render one cart dropdown at a time
  }

  // build cart dropdown
  const cartDropdown = document.createElement('div');
  cartDropdown.id = 'cartDropdown';
  cartDropdown.classList.add('mceCartDropdown');
  cartDropdown.setAttribute('aria-live', 'polite');

  //ensure the dropdown stays above the fold regardless of how many items the shopper has in their cart
  const position = cartIcon.getBoundingClientRect();
  const windowHeight = window.innerHeight;
  const maxHeight = (windowHeight - position.top - cartIcon.offsetHeight) * 0.9;
  const isDesktop = window.matchMedia('(min-width: 480px)');

  // if mobile, we'll set with a media query in cartIcon.less instead
  if (isDesktop.matches) {
    cartDropdown.setAttribute('style', `max-height: ${maxHeight}px`);
  }

  cartDropdown.innerHTML = `
  <div class="closeWrap">
    <div id="closeButton" role="button" tabindex="0" class="close" aria-pressed="false">
      ${closeIcon}
    </div>
  </div>`;

  cart.items.map((item) => {
    item.subtotal = centsToCurrency({
      amount: item.subtotal,
      currencyCode: item.currencyCode || cart.currency_code,
    });
    const lineItem = document.createElement('div');
    lineItem.classList.add('lineItem');
    lineItem.innerHTML = buildLineItem(item);
    return cartDropdown.appendChild(lineItem);
  });

  // subtotal
  const subtotalWrap = document.createElement('div');
  subtotalWrap.classList.add('subtotalWrap');
  const itemText = cart.quantity === 1 ? 'item' : 'items';
  const cartSubtotal = centsToCurrency({
    amount: cart.subtotal,
    currencyCode: cart.currency_code,
  });
  subtotalWrap.innerHTML = `
    <div>You have ${cart.quantity} ${itemText} in your cart<div>
    <div class="subtotal">
      <span>Order Subtotal</span>
      <span>${cartSubtotal}</span>
    </div>
  `;

  cartDropdown.appendChild(subtotalWrap);

  // go to cart button
  const buttonWrap = document.createElement('div');
  const button = document.createElement('a');
  button.setAttribute('href', '/store/cart');
  button.textContent = 'View Cart';
  buttonWrap.classList.add('mceButton');
  buttonWrap.style.margin = 0;
  buttonWrap.appendChild(button);
  subtotalWrap.appendChild(buttonWrap);

  cartIcon.appendChild(cartDropdown);

  // close button
  const closeButton = document.getElementById('closeButton');
  if (closeButton) {
    closeButton.addEventListener('click', () => {
      cartDropdown.style.display = 'none';
    });
  }

  // show on hover, but only on desktop
  if (isDesktop) {
    cartIcon.addEventListener('mouseenter', () => {
      cartDropdown.style.display = 'block';
    });

    cartIcon.addEventListener('mouseleave', () => {
      cartDropdown.style.display = 'none';
    });
  }
}

// triggered when a user adds to cart
export function updateCartDropdown(cart) {
  if (!cart) {
    return;
  }

  buildCartDropdown(cart);
  const cartDropdown = document.getElementById('cartDropdown');

  //show the cart
  cartDropdown.style.display = 'block';

  // events to prevent cart from disappearing while user is hovering over element
  let cartHover = false;

  cartDropdown.addEventListener('mouseleave', () => {
    cartHover = false;
    hideDropdownTimeout();
  });

  cartDropdown.addEventListener('mouseenter', () => {
    cartHover = true;
  });

  function hideDropdownTimeout() {
    setTimeout(() => {
      if (!cartHover) {
        cartHover = false;
        cartDropdown.style.display = 'none';
      }
    }, 3000);
  }
  hideDropdownTimeout();
}
