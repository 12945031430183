import env from '../env';
import { AsyncForm } from '../asyncForms';
import commerceError from './commerceError';
import { updateCartDropdown } from './cartDropdown';
import { updateCartCount } from './cartIcon';

/**
 * Used for the add to cart form on commerce pages. Essentially the same as an AsyncForm, but overrides the request
 * handler callback to trigger a mini-cart instead of a redirect.
 */
class CartForm extends AsyncForm {
  static targetAttribute = 'data-commerce-cart-form';
  static labelTargetAttribute = 'data-commerce-cart-form-label';

  onRequestSuccess(response) {
    if (response?.success === false) {
      const pageContainer = document.querySelectorAll('[data-page-content]');
      const errors = response.errors.map((error) => {
        return error.type;
      });

      const msg = errors.includes('PRODUCT_HIDDEN')
        ? 'Sorry, this product is no longer available.'
        : 'Sorry! There was an error adding this product to your cart.';
      const error = commerceError(msg);
      window.scrollTo(0, 0);
      return pageContainer[0].before(error);
    }

    if (response) {
      const cartCount = document.getElementById('cartLink');
      const { cart } = response;

      //get total cart quantity
      if (cartCount) {
        updateCartCount(cart);
        updateCartDropdown(cart);
      }
    }
  }
}

/**
 * Set up the cart forms on the page (if there are any).
 */
export function setUpCartForms() {
  if (!env.isForPublicConsumption) {
    return;
  }

  const forms = document.querySelectorAll(`[${CartForm.targetAttribute}]`);
  forms.forEach((form) => new CartForm(form));
}
