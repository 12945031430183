/**
 * Checks if given URL input is valid or not
 *
 * @param {String} url - the submission value for the URL input
 *
 * @returns {Boolean}
 */
const isUrlValid = (url) => {
  const urlRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w-._~:/?#[\]@!$&'()*+,;=.]+$/gm;
  return urlRegex.test(url);
};

/**
 * Checks if given email input is valid or not
 *
 * @param {String} inputVal - the submission value for the email input
 *
 * @returns {Boolean}
 */
const isEmailValid = (inputVal) => {
  const EMAIL_REGEX = /^[^\s]+@.+\..+$/;

  return EMAIL_REGEX.test(inputVal);
};

/**
 * Checks if given phone number input is valid or not
 *
 * @param {String} inputVal - the submission value for the phone number input
 *
 * @returns {Boolean}
 */
const isPhoneValid = (inputVal) => {
  // Copied expression from https://stackoverflow.com/a/16702965
  const PHONE_NUMBER_REGEX = /^(?:\+?(\d{1,3}))?[-. (]*(\d{2,3})[-. )]*(\d{3})[-. ]*(\d{3,4})(?: *x(\d+))?$/;

  return PHONE_NUMBER_REGEX.test(inputVal);
};

/**
 * Checks if given zip input is valid in the US or not
 *
 * @param {String} inputVal - the submission value for the zip input
 *
 * @returns {Boolean}
 */
const isUSZipValid = (inputVal) => {
  const US_ZIP_REGEX = /^\d{5}-\d{4}$|^\d{5}$/;

  return US_ZIP_REGEX.test(inputVal);
};

/**
 * Determines if a given date or birthday is valid, based on the expected format
 *
 * @param {string} dateFormat
 * @param {string} value
 */
const isDateValid = (dateFormat, value) => {
  let DATE_REGEX = /^$/;
  let month = 0;
  let day = 0;
  try {
    // Set up the date regex based on the provided format,
    // and extract the month and day values to verify they fall within the expected numerical range
    switch (dateFormat) {
      case 'MM/DD/YYYY': {
        DATE_REGEX = /^(?<month>\d{2})\/(?<day>\d{2})\/(?<year>\d{4})$/;
        const { groups } = value.match(DATE_REGEX);
        month = parseInt(groups.month, 10);
        day = parseInt(groups.day, 10);
        break;
      }
      case 'DD/MM/YYYY': {
        DATE_REGEX = /^(?<day>\d{2})\/(?<month>\d{2})\/(?<year>\d{4})$/;
        const { groups } = value.match(DATE_REGEX);
        month = parseInt(groups.month, 10);
        day = parseInt(groups.day, 10);
        break;
      }
      case 'DD/MM': {
        DATE_REGEX = /^(?<day>\d{2})\/(?<month>\d{2})$/;
        const { groups } = value.match(DATE_REGEX);
        month = parseInt(groups.month, 10);
        day = parseInt(groups.day, 10);
        break;
      }
      case 'MM/DD': {
        DATE_REGEX = /^(?<month>\d{2})\/(?<day>\d{2})$/;
        const { groups } = value.match(DATE_REGEX);
        month = parseInt(groups.month, 10);
        day = parseInt(groups.day, 10);
        break;
      }
      default:
        break;
    }
    if (month === 0 || month > 12) {
      return false;
    }
    if (day === 0 || day > 31) {
      return false;
    }
    return DATE_REGEX.test(value);
  } catch (error) {
    return false;
  }
};

export { isDateValid, isEmailValid, isUrlValid, isPhoneValid, isUSZipValid };
