export function generateRandomString(length = 5) {
  let result = '';
  for (let index = 0; index < length; index++) {
    result += Math.random()
      .toString(36)
      .substr(2, 1);
  }

  return result;
}

export default {
  generateRandomString,
};
