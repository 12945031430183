/**
 * Runtime for imageGallery composite blocks.
 */

// Light shim over requestIdleCallback / cancelIdleCallback,
// since Safari does not support it.
function performIdleCallback(cb, options) {
  if (window.requestIdleCallback) {
    return window.requestIdleCallback(cb, options);
  }

  return setTimeout(cb, options.timeout || 1000);
}

function cancelIdleCallback(handle) {
  if (window.requestIdleCallback) {
    return window.cancelIdleCallback(handle);
  }

  return clearTimeout(handle);
}

export function initializeGallery() {
  const cleanups = [];

  document.querySelectorAll('.mceImageGallery').forEach((gallery) => {
    // Keep track of the last active image in the list of main images.
    let lastIndex = 0;

    // All images are rendered, with the first one shown by default.
    const mainImages = gallery.querySelectorAll('.mceImageGallery-main');
    const thumbContainers = gallery.querySelectorAll('.mceImageGallery-thumb');

    let loadedCount = 1;
    let idleCallbackId;

    // preload the main images when the browser has free cycles.
    function preloadNextImage() {
      idleCallbackId = performIdleCallback(function() {
        if (loadedCount < mainImages.length) {
          const parent = mainImages[loadedCount];
          const img = parent.querySelector('img');

          // currentSrc gets the image the <picture> has
          // currently selected.
          const src = img.currentSrc;

          const tmp = new Image();
          tmp.onload = function() {
            loadedCount++;
            preloadNextImage();
          };

          tmp.src = src;
        }
      });
    }

    preloadNextImage();

    const mainWrapper = gallery.querySelector('.mceImageGallery-main-wrapper');
    const mainStrip = gallery.querySelector('.mceImageGallery-main-strip');

    let containerSize = window.innerWidth;

    // CSS Scroll Snapping doesn't have "end" events unfortunately, so we
    // debounce our way to faking one.
    let timeoutId;

    function handleScroll(e) {
      clearTimeout(timeoutId);

      timeoutId = setTimeout(() => {
        // Since scroll snap align is center, we need to adjust this coordinate
        // by half the viewport.
        const left = e.target.scrollLeft - window.innerWidth / 2;
        // Adjust by 12 because of gap between images.
        const newIndex = Math.round((left - 12) / containerSize);
        setSelectedIndex(newIndex);
      }, 200);
    }

    function setSelectedIndex(newIndex) {
      mainImages[lastIndex].classList.add('mceImageGallery-main--hide');
      thumbContainers[lastIndex].classList.remove(
        'mceImageGallery-thumb--selected',
      );
      mainImages[newIndex].classList.remove('mceImageGallery-main--hide');
      thumbContainers[newIndex].classList.add(
        'mceImageGallery-thumb--selected',
      );
      lastIndex = newIndex;
    }

    // When a click happens inside the bounds of a gallery instance,
    // we check to see if it is inside of one of the thumbnails, and if
    // so, change the main image to match that of the thumbnail.
    function handler(e) {
      const el = e.target;
      let wrapper = el.closest('.mceImageGallery-thumb');
      if (e.key) {
        switch (e.key) {
          case 'Tab': {
            if (e.shiftKey) {
              wrapper = wrapper.previousElementSibling;
              break;
            }

            wrapper = wrapper.nextElementSibling;
            break;
          }
          default:
            return;
        }
      } else {
        // Prevent dragging of the image if this isn't a keyboard event
        e.preventDefault();
      }

      if (wrapper) {
        setSelectedIndex(
          Array.from(wrapper.parentNode.children).indexOf(wrapper),
        );
      }
    }

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const { width } = entry.contentRect;
        mainWrapper.style.setProperty('--containerSize', width + 'px');
        containerSize = width;
      }
    });

    resizeObserver.observe(mainWrapper.parentElement);

    // Set the initial selected thumb
    setSelectedIndex(0);

    gallery.addEventListener('click', handler);
    gallery.addEventListener('mousemove', handler);
    mainStrip.addEventListener('scroll', handleScroll);
    gallery.addEventListener('keydown', handler);

    cleanups.push(() => {
      gallery.removeEventListener('click', handler);
      gallery.removeEventListener('mousemove', handler);
      mainStrip.removeEventListener('scroll', handleScroll);
      gallery.removeEventListener('keydown', handler);
    });

    cleanups.push(() => {
      cancelIdleCallback(idleCallbackId);
      clearTimeout(timeoutId);
      resizeObserver.unobserve(mainWrapper.parentElement);
    });
  });

  return () => {
    cleanups.forEach((c) => c());
  };
}
