import env from './env';

/**
 * A form class instantiated for each async form. Can be extended in order to perform custom functionality as necessary.
 */
export class AsyncForm {
  static targetAttribute = 'data-async-form';
  static labelTargetAttribute = 'data-async-form-label';

  /**
   * Create a new async form. Passed a <form> instance, typically from querySelectorAll.
   * @param {HTMLFormElement} form
   */
  constructor(form) {
    // Allow for an arbitrary action & method so we can reuse these forms outside of the PDP.
    const action = form.getAttribute('action');
    const method = form.getAttribute('method') || 'POST';

    // Grab references to the submit button + default & loading labels for disabling/enabling.
    const submittingLabel = form.getAttribute(
      this.constructor.labelTargetAttribute,
    );
    const submitButton = form.querySelector('[type="submit"]');
    const submitButtonLabel = submitButton.innerText;

    // Add the event listener for the form.
    form.addEventListener('submit', this._handleSubmit.bind(this));

    // Finally, cache the necessary objects as properties on this instance of AsyncForm.
    Object.assign(this, {
      form,
      action,
      method,
      submittingLabel,
      submitButton,
      submitButtonLabel,
    });
  }

  /**
   * Kick off the form request. Uses fetch() to send an appropriate request.
   * @returns {Promise<Response>}
   * @private
   */
  _makeRequest() {
    const { method, action, form } = this;
    const body = new FormData(form);
    const headers = { 'X-Requested-With': 'XMLHttpRequest' };

    return fetch(action, { method, body, headers });
  }

  /**
   * Handle the form submission. Kicks off the request cycle.
   * @param ev
   * @private
   */
  async _handleSubmit(ev) {
    ev.preventDefault();

    const { submitButton, submittingLabel, submitButtonLabel } = this;

    // While submitting, update the submit button label.
    submitButton.innerText = submittingLabel;
    submitButton.setAttribute('disabled', 'disabled');

    // Kick off the request, catching any errors that occur.
    let json, err;
    try {
      const res = await this._makeRequest();
      json = await res.json();
    } catch (e) {
      console.error('Error sending request:', e);
      err = e;
    }

    // Finished with the request, let's re-enable the form and reset the submit label.
    submitButton.innerText = submitButtonLabel;
    submitButton.removeAttribute('disabled');

    // If any errors occurred, call the error handler.
    if (err) {
      return this.onRequestError(err);
    }

    // Call the callback handler. Used in extensions of AsyncForm.
    this.onRequestSuccess(json);
  }

  /**
   * Called if an error occurs during the XHR request. Passed the error that occurred.
   * @param {Error} err
   * @abstract
   */
  onRequestError(err) {
    return window.alert(`There was an error with your request.\n\n${err}`);
  }

  /**
   * Called on successful completion of the XHR request. Passed the JSON response from the request.
   * By default, this looks for a `redirect` property on `response` and sets it to `window.location`.
   * @param {Response} response
   * @abstract
   */
  onRequestSuccess(response) {
    const { redirect } = response;

    if (redirect) {
      window.location = `${redirect}`;
    }
  }
}

/**
 * Set up forms with the `data-async-form` attribute.
 */
export function setUpAsyncForms() {
  if (!env.isForPublicConsumption) {
    return;
  }

  const forms = document.querySelectorAll(`[${AsyncForm.targetAttribute}]`);
  forms.forEach((form) => new AsyncForm(form));
}
