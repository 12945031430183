/**
 * The purpose of this module is to deserialize the application config
 * for a given page. By design, this requires the page to have a script
 * tag of type "application/json" with the id of "js-config".
 *
 * All data contained should be considered static and purely for application
 * hydration.
 */
const destructiveRead = (id) => {
  // This code may execute in server side rendering environments, if so we want
  // to bail out early.
  if (typeof document === 'undefined') {
    return {};
  }
  const configElement = document.getElementById(id);
  if (!configElement) {
    return {};
  }
  const data = JSON.parse(configElement.textContent);
  configElement.innerText = '';
  return Object.freeze(data);
};

export default destructiveRead;
