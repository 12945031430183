import { truck } from './commerceIcons';

export default function freeShippingNotificationIcon() {
  const freeShippingNotificationContainer = document.querySelector(
    '[data-ref="freeShippingNotificationIcon"]',
  );

  if (freeShippingNotificationContainer) {
    freeShippingNotificationContainer.innerHTML = truck;
    freeShippingNotificationContainer.classList.add(
      'freeShippingNotificationIcon',
    );
  }
}
