import env from './env';

export const startupSectionAnimationObserver = () => {
  // disable animation
  if (env.pageType === 'appointment_confirmation') {
    return;
  }

  // Only run animations on previews or the real site
  const shouldAnimate =
    !document.body.classList.contains('isViewer') ||
    document.body.classList.contains('isAnimationPreview');

  if (!shouldAnimate) {
    return;
  }

  const rootEl = document.querySelector('#root');
  const animationType = getComputedStyle(rootEl)
    .getPropertyValue('--animationType')
    .trim();
  const isAnimationEnabled = animationType && animationType !== 'none';

  if (!isAnimationEnabled) {
    return;
  }

  const sections = document.getElementById('root').children;
  let modifiedElements = [];
  const observers = [];

  for (let i = 0; i < sections.length; i++) {
    const section = sections[i];
    const animatableElements = section.querySelectorAll(
      '.mceText, .mceButton, .mceImage, .mceInputGroup, .mceEmbeddedMediaDefault',
    );

    animatableElements.forEach((element) => {
      element.dataset.animationStatus = 'not-started';
    });
    modifiedElements = modifiedElements.concat([...animatableElements]);

    const observerCallback = (entries) => {
      const { intersectionRatio, boundingClientRect } = entries[0];
      if (intersectionRatio > 0) {
        if (section.classList.contains('inViewport')) {
          return;
        }

        // begin animation if the top edge is 30% into the viewport OR
        // at least 40% of the section is already in view.
        if (
          boundingClientRect.top < window.innerHeight * 0.7 ||
          intersectionRatio > 0.4
        ) {
          for (let ii = 0; ii < animatableElements.length; ii++) {
            const element = animatableElements[ii];
            element.dataset.animationStatus = 'entering';
            element.onanimationend = () => {
              element.dataset.animationStatus = 'complete';
            };
          }

          section.classList.add('inViewport');
        }
      }
    };

    const intersectionObserver = new IntersectionObserver(observerCallback, {
      threshold: [0.1, 0.2, 0.3, 0.4, 0.5],
    });

    intersectionObserver.observe(section);
    observers.push(intersectionObserver);
  }

  // Cleanup function
  return () => {
    // Disconnect intersection observers
    observers.forEach((iO) => {
      iO.disconnect();
    });
    // Clear inViewport status
    for (let i = 0; i < sections.length; i++) {
      const section = sections[i];
      if (section.classList.contains('inViewport')) {
        section.classList.remove('inViewport');
      }
    }
    // Reset element status/data
    for (let i = 0; i < modifiedElements.length; i++) {
      const element = modifiedElements[i];
      if (!!element && !!element.dataset) {
        delete element.dataset.animationStatus;
      }
      if (!!element && !!element.style) {
        element.style.animationDelay = '0s';
      }
    }
  };
};
